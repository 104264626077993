import ReviewAnimation from 'Component/ReviewAnimation/ReviewAnimation';
import ApplicationLayout from 'Page/Application/layouts/ApplicationLayout';
import { ApplicationStepsCompleted } from 'Util/constants';

export const UnderwritingProcessing = () => {
  return (
    <ApplicationLayout stepsCompleted={ApplicationStepsCompleted.REVIEW}>
      <ReviewAnimation pageTitle="Processing your plan" />
    </ApplicationLayout>
  );
};
