import AgreementTable, {
  TableContent,
} from 'Page/Application/Sign/components/AgreementTable';
import { ReactElement } from 'react';

export const PrimeSignatureInfoTable = ({
  primeAcceptanceDate,
  primeAuthorizedSigner,
  primeAuthorizedSignerTitle,
  primeSignatureElement,
}: {
  primeAcceptanceDate: string;
  primeAuthorizedSigner: string;
  primeAuthorizedSignerTitle: string;
  primeSignatureElement: ReactElement<HTMLImageElement>;
}) => {
  const content: TableContent = [
    {
      title: "Prime Authorized Signer's Name",
      content: primeAuthorizedSigner,
    },
    {
      title: "Prime Authorized Signer's Title",
      content: primeAuthorizedSignerTitle || 'Owner',
    },
    {
      title: 'Prime Acceptance Date',
      content: new Date(primeAcceptanceDate).toLocaleDateString(),
    },
    {
      title: 'Prime Signature',
      content: primeSignatureElement || <img src="" alt="signature" />,
    },
  ];
  return <AgreementTable info={content} />;
};

export default PrimeSignatureInfoTable;
