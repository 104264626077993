import { ArrowLeftIcon } from '@heroicons/react/solid';
import Footer from 'Component/Footer/Footer';
import Link from 'Component/Link/Link';
import NavBar from 'Component/NavBar/NavBar';
import { useNavigate } from 'react-router-dom';
import { classNames } from 'Util/css/css';

export const PrivacyPolicy = () => {
  const navigate = useNavigate();
  const pageClassName = 'bg-screen-primary flex flex-col';
  const containerClassName =
    'max-w-3xl mx-auto px-4 py-8 flex flex-col gap-4 bg-screen-primary overflow-auto';
  const copyClassName = 'text-sm text-onWhite-medium';
  const titleClassName = 'text-2xl mx-auto';
  const headingClassName = 'text-xl';
  const sectionHeadingClassName = 'text-lg font-regular text-primeft-900';
  const listClassName = classNames(copyClassName, 'list-disc list-inside');

  return (
    <div className={pageClassName}>
      <NavBar />
      <div className={containerClassName}>
        <Link onClick={() => navigate(-1)}>
          <>
            <ArrowLeftIcon className="mr-2 -mt-1 inline-block h-4 w-4" />
            <span>Back</span>
          </>
        </Link>
        <h1 className={titleClassName}>Privacy Policy</h1>
        <h2 className={headingClassName}>Effective Date: May 1, 2023</h2>
        <p className={copyClassName}>
          This Privacy Policy describes how Prime Financial Technologies handles personal
          information that we collect through our website, platform, and any other sites
          or services that link to this Privacy Policy (collectively, the “Services”).
        </p>
        <p className={copyClassName}>
          <p className={sectionHeadingClassName}>
            Personal information we collect <br />
            Information users submit to us:
          </p>
          <ul className={listClassName}>
            <li>
              Contact and account information, such as your first and last name, phone
              number, mailing address, email address, username, and password.
            </li>
            <li>
              Commercial loan application information, including your social security
              number, credit and employment information, financial account information,
              transaction history, and information about your business.
            </li>
            <li>
              Feedback or correspondence, such as information you provide when you contact
              us with questions, feedback, or otherwise correspond with us online.
            </li>
            <li>
              Usage information, such as information about how you use the Services and
              interact with us, including information associated with any content you
              upload to the Services or otherwise submit, and information you provide when
              you use any interactive features of the Services.
            </li>
            <li>
              Marketing information, such as your preferences for receiving communications
              about our Services, and details about how you engage with our
              communications.
            </li>
            <li>
              Other information that we may collect, which is not specifically listed
              here, but which we will use in accordance with this Privacy Policy or as
              otherwise disclosed at the time of collection.
            </li>
          </ul>
        </p>
        <p className={copyClassName}>
          <p className={sectionHeadingClassName}>
            Information we obtain from third parties:
          </p>
          <ul className={listClassName}>
            <li>
              Social media information. We may maintain pages on social media platforms,
              such as Facebook, LinkedIn, and other third-party platforms. When you visit
              or interact with our pages on those platforms, the platform provider’s
              privacy policy will apply to your interactions and their collection, use and
              processing of your personal information.
            </li>
            <li>
              Other sources. We may obtain your personal information from other third
              parties, such as marketing partners, background check providers, credit
              reporting agencies, financial institutions, publicly-available sources, and
              data providers.
            </li>
          </ul>
        </p>
        <p className={copyClassName}>
          <p className={sectionHeadingClassName}>Automatic data collection.</p> We and our
          service providers may automatically log information about you, your computer or
          mobile device, and your interactions over time with our Services, our
          communications and other online services, such as:
          <ul className={listClassName}>
            <li>
              Device data, ****such as your computer’s or mobile device’s operating system
              type and version, manufacturer and model, browser type, screen resolution,
              RAM and disk size, CPU usage, device type (e.g., phone, tablet), IP address,
              unique identifiers, language settings, mobile device carrier, radio/network
              information (e.g., WiFi, LTE, 4G), and general location information such as
              city, state or geographic area.
            </li>
            <li>
              Online activity data, such as pages or screens you viewed, how long you
              spent on a page or screen, browsing history, navigation paths between pages
              or screens, information about your activity on a page or screen, access
              times, duration of access, and whether you have opened our marketing emails
              or clicked links within them.
            </li>
          </ul>
        </p>
        <p className={copyClassName}>
          <p className={sectionHeadingClassName}>
            We use the following tools for automatic data collection:
          </p>
          <ul className={listClassName}>
            <li>
              Cookies, which are text files that websites store on a visitor‘s device to
              uniquely identify the visitor’s browser or to store information or settings
              in the browser for the purpose of helping you navigate between pages
              efficiently, remembering your preferences, enabling functionality, and
              helping us understand user activity and patterns.
            </li>
            <li>
              Local storage technologies, like HTML5, that provide cookie-equivalent
              functionality but can store larger amounts of data, including on your device
              outside of your browser in connection with specific applications.
            </li>
            <li>
              Web beacons, also known as pixel tags or clear GIFs, which are used to
              demonstrate that a webpage or email was accessed or opened, or that certain
              content was viewed or clicked.
            </li>
          </ul>
        </p>
        <p className={copyClassName}>
          <p className={sectionHeadingClassName}>
            How we use your personal information <br />
            To operate our Services:
          </p>
          <ul className={listClassName}>
            <li>Provide, operate, maintain, secure and improve our Services.</li>
            <li>Provide our users with customer service.</li>
            <li>
              Communicate with you about our Services, including by sending you
              announcements, updates, security alerts, and support and administrative
              messages.
            </li>
            <li>
              Understand your needs and interests, and personalize your experience with
              our Services and our communications.
            </li>
            <li>Respond to your requests, questions and feedback.</li>
          </ul>
        </p>
        <p className={copyClassName}>
          <p className={sectionHeadingClassName}>For research and development.</p> We may
          use your personal information for research and development purposes, including
          to analyze and improve our Services and our business. As part of these
          activities, we may create aggregated, de-identified, or other anonymous data
          from personal information we collect. We make personal information into
          anonymous data by removing information that makes the data personally
          identifiable to you. We may use this anonymous data and share it with third
          parties for our lawful business purposes, including to analyze and improve our
          Services and promote our business.
        </p>
        <p className={copyClassName}>
          <p className={sectionHeadingClassName}>Direct marketing.</p> We may from
          time-to-time send you direct marketing communications as permitted by law,
          including, but not limited to, notifying you of special promotions, offers and
          events via email and in-app notifications. You may opt out of our marketing
          communications as described in the “Opt out of marketing communications” section
          below.
        </p>
        <p className={copyClassName}>
          <p className={sectionHeadingClassName}>Compliance and protection.</p> We may use
          personal information to:
          <ul className={listClassName}>
            <li>
              Comply with applicable laws, lawful requests, and legal process, such as to
              respond to subpoenas or requests from government authorities.
            </li>
            <li>
              Protect our, your or others’ rights, privacy, safety or property (including
              by making and defending legal claims).
            </li>
            <li>
              Audit our internal processes for compliance with legal and contractual
              requirements and internal policies.
            </li>
            <li>Enforce the terms and conditions that govern our Services.</li>
            <li>
              Prevent, identify, investigate and deter fraudulent, harmful, unauthorized,
              unethical or illegal activity, including cyberattacks and identity theft.
            </li>
          </ul>
        </p>
        <p className={copyClassName}>
          <p className={sectionHeadingClassName}>
            How we share your personal information
          </p>
          <br />
          Service providers. We may share your personal information with third party
          companies and individuals that provide services on our behalf or help us operate
          our Services (such as lawyers, bankers, auditors, insurers, customer support,
          hosting, analytics, email delivery, marketing, and database management). These
          third parties also include entities that assist us in the administration,
          processing, servicing, and sale of your loan, such as fraud detection services,
          underwriting services, title companies, and financial institutions.
        </p>
        <p className={copyClassName}>
          <p className={sectionHeadingClassName}>Authorities and others.</p> We may
          disclose your personal information to law enforcement, government authorities,
          and private parties, as we believe in good faith to be necessary or appropriate
          for the compliance and protection purposes described above.
        </p>
        <p className={copyClassName}>
          <p className={sectionHeadingClassName}>Business transfers.</p>We may sell,
          transfer or otherwise share some or all of our business or assets, including
          your personal information, in connection with a business transaction (or
          potential business transaction) such as a corporate divestiture, merger,
          consolidation, acquisition, reorganization or sale of assets, or in the event of
          bankruptcy or dissolution. In such a case, we will make reasonable efforts to
          require the recipient to honor this Privacy Policy.
        </p>
        <p className={copyClassName}>
          <p className={sectionHeadingClassName}>Your choices</p>
          <br />
          <p className={sectionHeadingClassName}>
            Access or update your information.
          </p>{' '}
          You may update your personal information in your account by logging in and
          editing your information.
        </p>
        <p className={copyClassName}>
          <p className={sectionHeadingClassName}>Opt out of marketing communications. </p>
          You may opt out of marketing-related emails by following the opt-out or
          unsubscribe instructions at the bottom of the email. You may continue to receive
          service-related and other non-marketing emails.
        </p>
        <p className={copyClassName}>
          <p className={sectionHeadingClassName}>Online tracking opt-out. </p>There are a
          number of ways to limit online tracking, which we have summarized below:
          <ul className={listClassName}>
            <li>
              Blocking cookies in your browser. Most browsers let you remove or reject
              cookies. To do this, follow the instructions in your browser settings. Many
              browsers accept cookies by default until you change your settings. For more
              information about cookies, including how to see what cookies have been set
              on your device and how to manage and delete them, visit{' '}
              <a href="https://www.allaboutcookies.org/" className="underline">
                www.allaboutcookies.org
              </a>
              .
            </li>
            <li>
              Using privacy plug-ins or browsers. You can block our websites from setting
              cookies by using a browser with privacy features, like{' '}
              <a href="https://brave.com/" className="underline">
                Brave
              </a>
              , or installing browser plugins like{' '}
              <a href="https://privacybadger.org/" className="underline">
                Privacy Badger
              </a>
              ,{' '}
              <a href="https://www.ghostery.com/" className="underline">
                Ghostery
              </a>
              , or{' '}
              <a href="https://ublock.org/en" className="underline">
                uBlock Origin
              </a>
              , and configuring them to block third party cookies/trackers. You can also
              opt out of Google Analytics by downloading and installing the browser
              available at:{' '}
              <a href="https://tools.google.com/dlpage/gaoptout" className="underline">
                https://tools.google.com/dlpage/gaoptout
              </a>
              .
            </li>
          </ul>
          Note that because these opt-out mechanisms are specific to the device or browser
          on which they are exercised, you will need to opt out on every browser and
          device that you use.
        </p>
        <p className={copyClassName}>
          <p className={sectionHeadingClassName}>Do Not Track.</p> Some Internet browsers
          may be configured to send “Do Not Track” signals to the online services that you
          visit. We currently do not respond to “Do Not Track” or similar signals. To find
          out more about “Do Not Track,” please visit{' '}
          <a href="http://www.allaboutdnt.com" className="underline">
            http://www.allaboutdnt.com
          </a>
          .
        </p>
        <p className={copyClassName}>
          <p className={sectionHeadingClassName}>
            Other sites, mobile applications and services
          </p>
          <br />
          Our Services may contain links to other websites, mobile applications, and other
          online services operated by third parties. These links are not an endorsement
          of, or representation that we are affiliated with, any third party. In addition,
          our content may be included on web pages or in mobile applications or online
          services that are not associated with us. We do not control third party
          websites, mobile applications or online services, and we are not responsible for
          their actions. Other websites and services follow different rules regarding the
          collection, use and sharing of your personal information. We encourage you to
          read the privacy policies of the other websites and mobile applications and
          online services you use.
        </p>
        <p className={copyClassName}>
          <p className={sectionHeadingClassName}>Security</p>We employ a number of
          technical, organizational, and physical safeguards designed to protect the
          personal information we collect. However, no security measures are failsafe and
          we cannot guarantee the security of your personal information.
        </p>
        <p className={copyClassName}>
          <p className={sectionHeadingClassName}>Changes to this Privacy Policy</p>
          <br />
          We reserve the right to modify this Privacy Policy at any time. If we make
          material changes to this Privacy Policy, we will notify you by updating the date
          of this Privacy Policy and posting it on the website.
        </p>
        <p className={copyClassName}>
          <p className={sectionHeadingClassName}>How to contact us</p>
          <br />
          Please direct any questions or comments about this Policy or our privacy
          practices to{' '}
          <a href="mailto:support@primeft.com" className="underline">
            support@primeft.com
          </a>{' '}
          or 245 5th Avenue, Floor 17, New York, NY 10016.
        </p>
      </div>
      <Footer />
    </div>
  );
};
