import { ButtonHTMLAttributes, forwardRef } from 'react';
import { classNames } from 'Util/css/css';

export type ButtonProps = {
  children: React.ReactNode;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className = 'bg-primary-500 transition-all flex md:w-fit my-4 text-white rounded-lg items-center justify-center text-sm px-4 py-3',
      type = 'button',
      disabled = false,
      ...props
    }: ButtonProps,
    ref,
  ) => {
    return (
      <button
        type={type}
        className={classNames(
          className,
          disabled ? 'cursor-default opacity-50' : 'hover:bg-primary-700',
        )}
        disabled={disabled}
        ref={ref}
        {...props}
      >
        {children}
      </button>
    );
  },
);

Button.displayName = 'Button';

export default Button;
