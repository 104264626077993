import { ExclamationIcon, ShieldCheckIcon, XIcon } from '@heroicons/react/outline';
import { FC, SVGProps } from 'react';
import { NotificationType } from 'Util/constants';
import { classNames } from 'Util/css/css';

export type ToastParams = {
  title: string;
  message: string;
  type: NotificationType;
  autoClose?: boolean;
  showCloseIcon?: boolean;
  onClose?: () => void;
  Icon?: FC<SVGProps<SVGSVGElement>>;
};

const Toast = ({
  title,
  message,
  type,
  autoClose = false,
  showCloseIcon = false,
  onClose = () => alert('onClose not implemented'),
  Icon,
}: ToastParams) => {
  if (autoClose) {
    setTimeout(() => onClose(), 5000);
  }
  const fgColor = {
    [NotificationType.Success]: 'text-status-success-500',
    [NotificationType.Warning]: 'text-status-caution-500',
    [NotificationType.Error]: 'text-status-error-500',
  };

  const backbackgroundBaseClassName =
    'relative border-2 py-3 px-3 flex flex-row items-center gap-4 rounded-lg min-h-[80px]';
  const typeClassNames = {
    [NotificationType.Success]: 'bg-status-success-100 border-status-success-100',
    [NotificationType.Warning]: 'bg-status-caution-100 border-status-caution-100',
    [NotificationType.Error]: 'bg-status-error-100 border-status-error-100',
  };
  const typeIcons = {
    [NotificationType.Success]: (
      <div className="h-10 w-10 rounded-full bg-status-success-500 p-2">
        {Icon ? (
          <Icon className="test-primeft-100" />
        ) : (
          <ShieldCheckIcon className="text-primeft-100" />
        )}
      </div>
    ),
    [NotificationType.Warning]: (
      <div className="h-10 w-10 rounded-full bg-status-caution-500 p-2">
        {Icon ? (
          <Icon className="test-primeft-100" />
        ) : (
          <ExclamationIcon className="text-status-caution-100" />
        )}
      </div>
    ),
    [NotificationType.Error]: (
      <div className="h-10 w-10 rounded-full bg-status-error-500 p-2">
        {Icon ? (
          <Icon className="test-primeft-100" />
        ) : (
          <XIcon className="text-primeft-100" />
        )}
      </div>
    ),
  };
  const CloseIcon = () => (
    <div className="absolute top-0 right-0 h-10 w-10 cursor-pointer p-2">
      <XIcon onClick={onClose} className={fgColor[type]} />
    </div>
  );
  return (
    <div className={classNames(backbackgroundBaseClassName, typeClassNames[type])}>
      {typeIcons[type]}
      <div className="flex flex-col">
        <div className="text-sm font-semibold">{title}</div>
        <div className="text-xs text-primeft-900">{message}</div>
      </div>
      {showCloseIcon && <CloseIcon />}
    </div>
  );
};

export default Toast;
