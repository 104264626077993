import { ReactComponent as PlaidLogo } from 'Asset/plaid_logo.svg';
import Button from 'Component/Button/Button';
import { useGetLinkToken } from 'Hook/useGetLinkToken';
import { ConnectYourBankError } from 'Page/Application/ConnectYourBank/ConnectYourBank';
import { useCallback, useEffect } from 'react';
import {
  PlaidLinkOnEvent,
  PlaidLinkOnExit,
  PlaidLinkOnSuccess,
  PlaidLinkOptions,
  usePlaidLink,
} from 'react-plaid-link';
import { classNames } from 'Util/css/css';

const PlaidButton = ({
  successHandler,
  failHandler,
  styleType = 'black',
  showDisclaimer = true,
  className,
  buttonText = 'Connect Bank Account',
  disabled = false,
}: {
  successHandler: (publicToken: string) => void;
  failHandler: (error: ConnectYourBankError) => void;
  styleType?: 'white' | 'black';
  showDisclaimer?: boolean;
  className?: string;
  buttonText?: string;
  disabled?: boolean;
}) => {
  const isOAuthRedirect = window.location.href.includes('?oauth_state_id=');

  const handleGetLinkTokenError = (error: string) => {
    const linkTokenError: ConnectYourBankError = {
      message: error,
      type: 'LinkToken',
    };
    failHandler(linkTokenError);
  };

  const { linkToken } = useGetLinkToken({
    isOAuthRedirect,
    onError: handleGetLinkTokenError,
  });

  const onSuccess = useCallback<PlaidLinkOnSuccess>(
    publicToken => {
      successHandler(publicToken);
    },
    [successHandler],
  );
  const onEvent = useCallback<PlaidLinkOnEvent>((eventName, metadata) => {
    // log onEvent callbacks from Link
    // https://plaid.com/docs/link/web/#onevent
    console.log(eventName, metadata);
  }, []);
  const onExit = useCallback<PlaidLinkOnExit>(
    error => {
      const plaidError: ConnectYourBankError = {
        message: error?.error_message || '',
        type: 'Plaid',
      };
      // log onExit callbacks from Link, handle errors
      // https://plaid.com/docs/link/web/#onexit
      failHandler(plaidError);
    },
    [failHandler],
  );

  const config: PlaidLinkOptions = {
    // token must be the same token used for the first initialization of Link
    token: linkToken,
    onSuccess,
    onEvent,
    onExit,
  };

  if (isOAuthRedirect) {
    config.receivedRedirectUri = window.location.href;
  }

  const { open, ready, error } = usePlaidLink(config);
  if (error) console.error('Error in usePlaidLink: ', error);

  useEffect(() => {
    if (isOAuthRedirect && ready) {
      open();
    }
  }, [ready, open, isOAuthRedirect]);

  const baseConnectButtonClassName =
    'flex w-fit text-white font-medium rounded-lg bg-primeft-900 items-center justify-center text-xs p-3';
  const buttonClassNames = {
    white: classNames(
      baseConnectButtonClassName,
      'bg-primeft-100 hover:bg-primeft-300 text-primeft-900 md:w-[300px]',
      className ? className : '',
    ),
    black: classNames(
      baseConnectButtonClassName,
      'bg-primeft-900 hover:bg-primeft-700 transition-all text-primeft-100',
      className ? className : '',
    ),
  };

  const basePlaidLogoClassName = 'w-4 h-4 mr-2';
  const plaidLogoClassNames = {
    white: classNames(basePlaidLogoClassName, 'stroke-primeft-900'),
    black: classNames(basePlaidLogoClassName, 'stroke-primeft-100'),
  };
  return (
    <>
      <Button
        disabled={!ready || disabled}
        onClick={() => open()}
        className={buttonClassNames[styleType]}
      >
        <PlaidLogo className={plaidLogoClassNames[styleType]} />
        {buttonText}
      </Button>
      {showDisclaimer && (
        <p className="text-xs text-primeft-500">
          This application uses <span className="font-bold">Plaid</span> to connect to
          your bank accounts. By selecting &ldquo;Connect Bank Account&rdquo; you agree to
          the Plaid <span className="font-bold">End User Privacy Policy</span>.
        </p>
      )}
    </>
  );
};

export default PlaidButton;
