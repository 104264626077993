import { ArrowLeftIcon, MailIcon, PhoneIcon } from '@heroicons/react/outline';
import Footer from 'Component/Footer/Footer';
import { ImageBuilder, ImageType } from 'Component/ImageBuilder/ImageBuilder';
import Link from 'Component/Link/Link';
import OfferFAQ from 'Page/Offer/Components/FAQ/OfferFAQ';
import { useNavigate } from 'react-router-dom';

const Support = () => {
  const navigate = useNavigate();
  const containerClassName = 'min-h-screen bg-primeft-500 ';
  const topClassName = 'bg-primeft-500 flex flex-col justify-center items-center py-8';
  const titleClassName = 'text-3xl font-regular text-center mt-4';
  const subtitleClassName =
    'text-base font-regular text-primeft-500 text-center mt-2 max-w-xl px-4';
  const cardContainerClassName = 'flex flex-col md:flex-row mt-8 gap-4';
  const cardClassName =
    'flex flex-row gap-2 items-center bg-primeft-300 rounded-md shadow-lg p-4 w-full md:w-64';
  const cardContentClassName = 'flex flex-col text-left';
  const cardTitleClassName = 'font-semibold text-sm';
  const cardSubTitleClassName = 'text-sm';
  const iconClassName = 'text-primary-500 w-6 h-6';
  const linkClassName = 'text-sm underline text-left';

  return (
    <div className={containerClassName}>
      <div className="p-8">
        <Link onClick={() => navigate(-1)}>
          <ArrowLeftIcon className="mr-2 -mt-1 inline-block h-4 w-4" />
          <span>Back</span>
        </Link>
      </div>
      <div className={topClassName}>
        <ImageBuilder imageType={ImageType.SignGraphic} />
        <h1 className={titleClassName}>We’re here to help</h1>
        <span className={subtitleClassName}>
          Welcome to our support center. From here, you can contact us, or view our FAQs
          to see if your questions can be answered.
        </span>
        <div className={cardContainerClassName}>
          <div className={cardClassName}>
            <MailIcon className={iconClassName} />
            <div className={cardContentClassName}>
              <span className={cardTitleClassName}>Chat with us</span>
              <span className={cardSubTitleClassName}>Email our support team</span>
              <div className={linkClassName}>support@primeft.com</div>
            </div>
          </div>
          <div className={cardClassName}>
            <PhoneIcon className={iconClassName} />
            <div className={cardContentClassName}>
              <span className={cardTitleClassName}>Call us</span>
              <span className={cardSubTitleClassName}>Mon-Fri from 8am to 5pm</span>
              <div className={linkClassName}>+1 555-303-2292</div>
            </div>
          </div>
        </div>
      </div>
      <OfferFAQ />
      <Footer />
    </div>
  );
};

export default Support;
