import { useAuth0 } from '@auth0/auth0-react';
import axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import { createContext, useContext, useMemo } from 'react';

type ApiContextValue = {
  unauthenticatedInstance: AxiosInstance;
  instance: AxiosInstance;
};
const ApiContext = createContext<ApiContextValue | null>(null);

const getAccessOptions = {
  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
};

export function ApiProvider(props: { children?: React.ReactNode }) {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();

  const unauthenticatedInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  const instance = useMemo(() => {
    const instance = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
    });
    instance.interceptors.request.use(
      async (config: InternalAxiosRequestConfig) => {
        return await getAccessTokenSilently(getAccessOptions)
          .then(token => {
            return {
              ...config,
              headers: {
                ...config.headers,
                Authorization: `Bearer ${token}`,
              },
            } as InternalAxiosRequestConfig;
          })
          .catch(async () => {
            return await getAccessTokenWithPopup(getAccessOptions).then(token => {
              return {
                ...config,
                headers: {
                  ...config.headers,
                  Authorization: `Bearer ${token}`,
                },
              } as InternalAxiosRequestConfig;
            });
          });
      },
      error => Promise.reject(error),
    );
    return instance;
  }, [getAccessTokenSilently, getAccessTokenWithPopup]);

  return <ApiContext.Provider {...props} value={{ instance, unauthenticatedInstance }} />;
}

export function useApi() {
  const context = useContext(ApiContext);

  if (!context) {
    throw new Error('API is not provided');
  }
  return context.instance;
}

export function useUnauthenticatedApi() {
  const context = useContext(ApiContext);

  if (!context) {
    throw new Error('Unauthenticated API is not provided');
  }
  return context.unauthenticatedInstance;
}
