import { ArrowRightIcon } from '@heroicons/react/outline';
import ButtonLarge from 'Component/Button/ButtonLarge';
import { InfoLineReview } from 'Component/InfoLine/InfoLineReview';
import { useBorrower } from 'Context/BorrowerContext';
import { useUpdateLoan } from 'Hook/Loans/useUpdateLoan';
import { useGetOfferById } from 'Hook/Offers/useGetOfferById';
import { useGetBankInfo } from 'Hook/useGetBankInfo';
import { useGetLoanClearingTime } from 'Hook/useGetLoanClearingTime';
import { useLoanCalculations } from 'Hook/useLoanCalculations';
import { useEffect, useState } from 'react';
import { ButtonSubset } from 'Util/constants';
import { toCurrency } from 'Util/currency/currency';
import { isMCAOffer } from 'Util/typeHelpers/typeHelpers';
import ChangeModal from './ChangeModal';

export function MCAPlanDetails({ onContinueHandler }: { onContinueHandler: () => void }) {
  const {
    loanAmount,
    loanFee,
    totalCost,
    repaymentRate,
    totalPayments,
    installmentAmount,
  } = useLoanCalculations();
  const amount = loanAmount || 0;
  const rate = repaymentRate || 0;

  const handleSignAgreements = () => {
    if (isLoanDataChanged) {
      const loanId = loan?.id || '';
      loanUpdate.mutate({ loanId, amount, rate });
    } else {
      onContinueHandler();
    }
  };

  const { loan } = useBorrower();
  const { offer } = useGetOfferById(loan?.offer_id || '');
  const mcaOffer = offer && isMCAOffer(offer) ? offer : undefined;
  const loanUpdate = useUpdateLoan({
    onSuccess: onContinueHandler,
  });

  const { estimated_daily_revenue: dailySales = 1650 } = mcaOffer ?? {};
  const { estimatedTime } = useGetLoanClearingTime();

  const [isOpen, setIsOpen] = useState(false);
  const [isLoanDataChanged, setIsLoanDataChanged] = useState(false);
  const { institutionName, isFetching: isFetchingBankInfo } = useGetBankInfo();

  useEffect(() => {
    if (isOpen) setIsLoanDataChanged(true);
  }, [isOpen]);

  return (
    <div className="flex flex-col">
      <p className="mt-10 text-lg">You are borrowing</p>
      <div className="mb-6 flex flex-row items-center justify-between">
        <div className="">
          <span className="text-2xl font-bold md:text-4xl">
            {toCurrency(loanAmount, 0)}
          </span>
          <span className="font-regular text-lg md:text-2xl"> at a </span>
          <span className="text-2xl font-bold md:text-4xl">{`${(
            Number(repaymentRate) * 100
          ).toFixed(0)}%`}</span>
          <span className="font-regular text-lg md:text-2xl"> repayment rate </span>
        </div>
        <ButtonLarge
          subset={ButtonSubset.Clear}
          size="sm"
          onClick={() => setIsOpen(true)}
        >
          Change
        </ButtonLarge>
        {mcaOffer && (
          <ChangeModal offer={mcaOffer} isOpen={isOpen} setIsOpen={setIsOpen} />
        )}
      </div>
      <div className="my-4 grid grid-cols-2 gap-x-4 gap-y-8">
        <InfoLineReview
          title="Loan Cost"
          subtitle="The only fee associated with the loan"
          value={toCurrency(loanFee, 0)}
        />
        <InfoLineReview
          title="Final Amount Owed"
          subtitle="The sum total cost of the loan"
          value={toCurrency(totalCost + loanAmount, 0)}
        />
        <InfoLineReview
          title="Average Daily Payment"
          subtitle={`${(repaymentRate * 100).toFixed(0)}% of ${toCurrency(
            dailySales,
            0,
          )} in revenue`}
          value={toCurrency(installmentAmount, 0)}
        />
        <InfoLineReview
          title="Projected Total Payments"
          subtitle="Total amount of payments for the loan"
          value={`${totalPayments} days`}
        />
        <InfoLineReview
          title="Disbursement arrival date"
          subtitle="Time to receive funds in your account"
          value={estimatedTime}
        />
        <InfoLineReview
          title="Repayment Bank"
          subtitle="Repayment Bank you are connected to"
          value={
            isFetchingBankInfo ? <span>Loading...</span> : <span>{institutionName}</span>
          }
        />
      </div>
      <div className="mt-6 grid place-items-stretch lg:flex lg:justify-end">
        <ButtonLarge type="button" className="md:ml-auto" onClick={handleSignAgreements}>
          Confirm Details
          <ArrowRightIcon className="ml-2 h-6 w-4" />
        </ButtonLarge>
      </div>
    </div>
  );
}
