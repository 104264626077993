import { useAuth0 } from '@auth0/auth0-react';
import ButtonLarge from 'Component/Button/ButtonLarge';
import Loader from 'Component/Loader/Loader';
import { useBorrower } from 'Context/BorrowerContext';
import { usePostLoanPayment } from 'Hook/Payments/usePostLoanPayment';
import { PaymentModalLineItems } from 'Page/Dashboard/components/PaymentModal/PaymentModalLineItems';
import Loading from 'Page/Loading/Loading';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { showSuccessModal } from 'Store/PaymentModalSlice';
import { RootState } from 'Store/store';
import { toCurrency } from 'Util/currency/currency';
import { isAdmin } from 'Util/email/email';

type CreatePaymentModalProps = {
  balance: number;
};

export const CreatePaymentModal = ({ balance }: CreatePaymentModalProps) => {
  const { loan } = useBorrower();
  const navigate = useNavigate();
  const [showError, setShowError] = useState<boolean>(false);
  const { mutate: postPayment, isLoading: isLoadingPost } = usePostLoanPayment({
    loanId: loan?.id ?? '',
  });
  const { amount, date: dateStr } = useSelector((state: RootState) => state.paymentModal);
  const date = new Date(dateStr);
  date.setHours(23, 59, 0, 0);
  const dispatch = useDispatch();

  const { user: auth0User } = useAuth0();
  if (!auth0User) return <Loading title="Loading User from Auth0" />;

  if (!isAdmin(auth0User?.email ?? '')) {
    navigate('/dashboard');
    return <Loading title="Redirecting to Dashboard" />;
  }

  return (
    <div className="my-4 flex flex-col gap-4">
      {isLoadingPost && <Loader />}
      <div className="text-center">
        <h3 className="text-xl">Make a Payment Against your Loan</h3>
        <span className="text-sm text-primeft-500">
          Your current pending balance is {toCurrency(balance)}
        </span>
      </div>

      <PaymentModalLineItems
        showError={showError}
        setShowError={setShowError}
        balance={balance}
      />
      <div className="flex flex-row items-center">
        <span className="flex-1 text-xs text-primeft-500">
          Payment made until 11:59 ET will be made on the same day
        </span>
        <div className="flex-1">
          <ButtonLarge
            arrowRight={true}
            className="ml-auto"
            onClick={() =>
              postPayment(
                { amount: amount, date },
                {
                  onSuccess: () => {
                    dispatch(showSuccessModal());
                  },
                  onError: () => setShowError(true),
                },
              )
            }
            disabled={amount > balance || amount <= 0}
          >
            Make Payment
          </ButtonLarge>
        </div>
      </div>
    </div>
  );
};
