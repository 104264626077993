import Toast from 'Component/Toast/Toast';
import { useToastNotification } from 'Context/ToastNotificationContext';

export const ToastContainer = () => {
  const { notifications, closeNotification } = useToastNotification();
  return (
    <div className="absolute top-10 z-30 flex w-11/12 flex-col gap-2 shadow-xl sm:w-9/12 md:right-10 md:mr-10 md:w-4/12 lg:w-3/12">
      {notifications.map(notification => (
        <Toast
          key={notification.id}
          title={notification.title}
          message={notification.message}
          autoClose={notification.autoClose}
          showCloseIcon={notification.showCloseIcon}
          type={notification.type}
          onClose={() => closeNotification(notification.id)}
          Icon={notification.Icon}
        />
      ))}
    </div>
  );
};
