import { InfoLine } from 'Component/InfoLine/InfoLine';
import Incrementor from 'Page/Offer/Components/Repayment/CostGadget/Incrementor/Incrementor';
import { useDispatch, useSelector } from 'react-redux';
import {
  decrementAmount,
  decrementRate,
  incrementAmount,
  incrementRate,
} from 'Store/OfferSlice';
import { RootState } from 'Store/store';
import { toCurrency } from 'Util/currency/currency';
import { MCAOffer } from 'Util/types';

export const CustomOfferCalculator = ({
  offer: {
    factor_rate: factorRate,
    fee,
    capital_amount_min: amountMin,
    capital_amount_max: amountMax,
    remittance_rate_min: rateMin,
    remittance_rate_max: rateMax,
    estimated_daily_revenue: dailyRevenue,
  },
}: {
  offer: MCAOffer;
}) => {
  const containerClassName = 'flex flex-col pb-8 gap-2';
  const rowClassName = 'grid grid-cols-1 xl:grid-cols-2 gap-4 mb-2 place-items-stretch';
  const rate = useSelector((state: RootState) => state.offer.rate);
  const amount = useSelector((state: RootState) => state.offer.amount);
  const dailyPayment = rate * dailyRevenue;
  const financingCost = factorRate * amount + fee;
  const totalOwed = amount + financingCost;
  const daysToPayoff = Math.ceil(totalOwed / dailyPayment);
  const dispatch = useDispatch();
  return (
    <div className={containerClassName}>
      <div className={rowClassName}>
        <Incrementor
          title="Amount to Borrow"
          currentValue={toCurrency(amount, 0)}
          incrementFunction={() => dispatch(incrementAmount())}
          decrementFunction={() => dispatch(decrementAmount())}
          disableIncrement={amount >= amountMax}
          disableDecrement={amount <= amountMin}
          showMaximum={true}
          maxValue={toCurrency(amountMax, 0)}
        />
        <Incrementor
          title="Repayment Rate"
          currentValue={(rate * 100).toFixed(0) + '%'}
          incrementFunction={() => dispatch(incrementRate())}
          decrementFunction={() => dispatch(decrementRate())}
          disableIncrement={rate >= rateMax}
          disableDecrement={rate <= rateMin}
          showMinimum={true}
          minValue={(rateMin * 100).toFixed(0) + '%'}
        />
      </div>

      <InfoLine title="Loan Cost" amount={toCurrency(financingCost, 0)} />
      <InfoLine
        title="Final Amount Owed"
        amount={toCurrency(financingCost + amount, 0)}
      />
      <InfoLine title="Average Daily Payment" amount={toCurrency(dailyPayment, 0)}>
        <span className="text-sm text-gray-500">{`${(rate * 100).toFixed(
          0,
        )}% of ${toCurrency(dailyRevenue, 0)}`}</span>
      </InfoLine>
      <InfoLine title="Projected Total Payments" amount={`${daysToPayoff} days`} />
    </div>
  );
};
