import { useThemeContext } from 'Context/ThemeContext';
import FAQAccordian from 'Page/Offer/Components/FAQ/FAQAccordian/FAQAccordian';
import { Theme } from 'Util/constants';

export type FAQType = {
  question: string;
  answer: string;
};

const faqs = [
  {
    question: 'How much will this loan cost me?',
    answer:
      'Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Nulla vitae elit libero, a pharetra augue. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Etiam porta sem malesuad.',
  },
  {
    question: 'How often do you collect repayment?',
    answer:
      'Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Nulla vitae elit libero, a pharetra augue. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Etiam porta sem malesuad.',
  },
  {
    question: 'How long does it take to repay the advance',
    answer:
      'Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Nulla vitae elit libero, a pharetra augue. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Etiam porta sem malesuad.',
  },
  {
    question: 'Can I repay my advance early?',
    answer:
      'Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Nulla vitae elit libero, a pharetra augue. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Etiam porta sem malesuad.',
  },
  {
    question: 'What happens if my sales slow down?',
    answer:
      'Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Nulla vitae elit libero, a pharetra augue. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Etiam porta sem malesuad.',
  },
  {
    question: 'What information will I need to provide?',
    answer:
      'Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Nulla vitae elit libero, a pharetra augue. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Etiam porta sem malesuad.',
  },
];

export default function OfferFAQ() {
  const { theme } = useThemeContext();
  const sectionClassName = {
    [Theme.PrimeFT]: 'bg-gray-50',
    [Theme.Odeko]: 'bg-primeft-300',
  };
  return (
    <div className={sectionClassName[theme || Theme.PrimeFT]}>
      <div className="mx-auto max-w-7xl py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-3xl divide-y-2 divide-gray-200">
          <h2 className="text-center text-3xl tracking-tight text-gray-900">
            Frequently asked questions
          </h2>
          <dl className="mt-6 space-y-6 divide-y divide-gray-200">
            {faqs.map(faq => (
              <FAQAccordian key={faq.question} faq={faq} />
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
