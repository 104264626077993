import { AgreementTypes } from 'Util/types';

export const DocumentTitle: Record<AgreementTypes, string> = {
  offer_summary: 'Offer Summary',
  itemization: 'Itemization of Amount Financed',
  financing: 'Loan Agreement',
  unsecured_financing: 'Loan Agreement',
};

export const DocumentSubtitle: Record<AgreementTypes, string> = {
  offer_summary:
    'Below you will find the offer summary for your Closed-End Commercial Loan. Please read through carefully.',
  itemization:
    'Below you will find the itemization table for your Closed-End Commercial Loan. Please read through carefully.',
  financing:
    'Be sure to review all of the information carefully before confirming your loan. This is your last opportunity to adjust your funding details.',
  unsecured_financing:
    'Be sure to review all of the information carefully before confirming your loan. This is your last opportunity to adjust your funding details.',
};
