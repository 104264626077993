import { ClockIcon, UserIcon } from '@heroicons/react/outline';
import Link from 'Component/Link/Link';
import { PaymentTransaction } from 'Hook/Payments/useGetPaymentHistory';
import {
  PaymentStatusNames,
  useGetPaymentStatuses,
} from 'Hook/Payments/useGetPaymentStatuses';
import { useGetPaymentTypes } from 'Hook/Payments/useGetPaymentTypes';
import React from 'react';
import { useDispatch } from 'react-redux';
import { setEditModalData } from 'Store/PaymentModalSlice';
import { PaymentType } from 'Util/constants';

export const TransactionName = ({
  paymentTransaction,
  date,
  openModal,
}: {
  paymentTransaction: PaymentTransaction;
  date: string;
  openModal: () => void;
}) => {
  const dispatch = useDispatch();

  const { data: paymentTypes } = useGetPaymentTypes();
  const paymentName = paymentTypes?.filter(
    p => p.id === paymentTransaction.payment_type_id,
  )[0].name;
  const isManualPayment = paymentName === PaymentType.MANUAL;

  const { data: paymentStatuses } = useGetPaymentStatuses();
  const status = paymentStatuses?.filter(
    status => status.id === paymentTransaction?.payment_status_id,
  )[0].name;
  const isPending = status === PaymentStatusNames.PENDING;

  const scheduledDate = new Date(paymentTransaction.scheduled_date);
  const today = new Date();

  const iconClassName = 'h-4 w-4 text-primary-500';
  return (
    <div className="flex flex-row gap-1">
      {isManualPayment ? (
        <UserIcon className={iconClassName} aria-hidden="true" />
      ) : (
        <ClockIcon className={iconClassName} aria-hidden="true" />
      )}
      <span>{isManualPayment ? 'Manual Payment' : 'Automated Payment'}</span>
      {scheduledDate >= today && isManualPayment && isPending && (
        <>
          <Link
            onClick={() => {
              dispatch(
                setEditModalData({
                  paymentId: paymentTransaction.id,
                  amount: paymentTransaction.payment_amount,
                  date,
                }),
              );
              openModal();
            }}
          >
            Edit
          </Link>
        </>
      )}
    </div>
  );
};
