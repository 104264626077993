import ButtonLarge from 'Component/Button/ButtonLarge';
import { ChecklistItem } from 'Component/ChecklistItem/ChecklistItem';
import { ImageBuilder, ImageType } from 'Component/ImageBuilder/ImageBuilder';
import { useAgreementContext } from 'Context/AgreementContext';
import ApplicationLayout from 'Page/Application/layouts/ApplicationLayout';
import { ApplicationStepsCompleted } from 'Util/constants';

export const Intro = ({ onContinue }: { onContinue: () => void }) => {
  const { agreements } = useAgreementContext();
  return (
    <ApplicationLayout stepsCompleted={ApplicationStepsCompleted.SIGNING}>
      <>
        <div className="flex flex-col gap-4">
          <div>
            <ImageBuilder imageType={ImageType.SignGraphic} />
            <h1 className="text-2xl lg:text-3xl">Sign Agreements</h1>
            <p className="text-primeft-500">
              Before signing an agreement, read it over carefully to ensure you understand
              the terms of your financing. If you have any questions, you can reach out to
              our support team.
            </p>
          </div>
          {agreements.map(agreement => (
            <ChecklistItem key={agreement.id} step={agreement} />
          ))}
          <ButtonLarge arrowRight={true} onClick={onContinue}>
            Continue
          </ButtonLarge>
        </div>
      </>
    </ApplicationLayout>
  );
};
