import { forwardRef } from 'react';
import type { TSectionProps } from 'Util/types';

const THead = forwardRef<HTMLTableSectionElement, TSectionProps>(
  ({ className, ...props }, ref) => (
    <thead className={className ?? ''} ref={ref} {...props} />
  ),
);

THead.displayName = 'THead';

export default THead;
