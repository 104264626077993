import { useBorrower } from 'Context/BorrowerContext';
import { useGetUserBusiness } from 'Hook/useGetUserBusiness';
import AgreementTable, {
  TableContent,
} from 'Page/Application/Sign/components/AgreementTable';

const BusinessInfoTable = ({
  authorizedSigner,
  authorizedSignerTitle,
}: {
  authorizedSigner: string;
  authorizedSignerTitle: string;
}) => {
  const dateOfSigning = new Date().toLocaleDateString();
  const { user } = useBorrower();
  const { business } = useGetUserBusiness({ userId: user?.id });
  const content: TableContent = [
    {
      title: "Business' Legal Name",
      content: business?.name || 'Loading...',
    },
    {
      title: 'Business Trade Name/DBA',
      content: business?.name || 'Loading...',
    },
    {
      title: "Business' Address",
      content: business?.formatted_address || 'Loading...',
    },
    {
      title: "Business' Email",
      content: user?.email || '',
    },
    {
      title: "Authorized Signer's Name",
      content: authorizedSigner,
    },
    {
      title: "Authorized Signer's Title",
      content: authorizedSignerTitle || 'Owner',
    },
    {
      title: 'Date',
      content: dateOfSigning,
    },
  ];
  return <AgreementTable info={content} />;
};

export default BusinessInfoTable;
