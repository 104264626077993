/* This example requires Tailwind CSS v2.0+ */
import {
  CashIcon,
  CheckIcon,
  ClockIcon,
  OfficeBuildingIcon,
  PencilIcon,
} from '@heroicons/react/outline';
import { ImageBuilder, ImageType } from 'Component/ImageBuilder/ImageBuilder';
import React, { SVGProps } from 'react';

const features = [
  {
    name: '1. Connect To Your Bank',
    icon: OfficeBuildingIcon,
  },
  {
    name: '2. Verify Your Identity',
    icon: CheckIcon,
  },
  {
    name: '3. Sign Agreements',
    icon: PencilIcon,
  },
  {
    name: '4. Get Funded',
    icon: CashIcon,
  },
];

const Feature = ({
  name,
  icon: Icon,
}: {
  name: string;
  icon: (props: SVGProps<SVGSVGElement>) => JSX.Element;
  key: number;
}) => (
  <div>
    <div className="flow-root rounded-lg px-6 pb-8">
      <div className="-mt-6">
        <div>
          <span className="inline-flex items-center justify-center">
            <Icon className="h-6 w-6 text-primary-500" aria-hidden="true" />
          </span>
        </div>
        <h3 className="mt-2 text-lg  tracking-tight text-primeft-100">{name}</h3>
      </div>
    </div>
  </div>
);

const Sidebar = () => {
  const sidebarClasses =
    'flex flex-col text-center justify-between mw-auto bg-gradientDark lg:w-1/3 py-6 lg:ml-8 lg:px-0 min-w-[366px] md:overflow-y-auto';

  return (
    <div className={sidebarClasses}>
      <div className="flex justify-center py-8">
        <ImageBuilder imageType={ImageType.SidebarLogo} />
      </div>
      <div className="grid grid-cols-1 gap-12">
        {features.map((feature, index) => (
          <Feature key={index} {...feature} />
        ))}
      </div>
      <div className="flex flex-row items-center justify-center">
        <ClockIcon className="mr-2 h-4 w-4 text-primeft-300" aria-hidden="true" />
        <span className="text-xs text-primeft-300">
          Average time to complete is 9 minutes
        </span>
      </div>
    </div>
  );
};

export default Sidebar;
