import { useApi } from 'Context/ApiContext';
import { useState } from 'react';
import { useQuery } from 'react-query';

export const useGetDownloadSignedAgreement = ({ loanId }: { loanId: string }) => {
  const api = useApi();
  const [downloadTrigger, setDownloadTrigger] = useState<boolean>(false);
  const getSignedAgreement = async () =>
    (
      await api.get(`/agreements/loan/${loanId}/signed`, {
        responseType: 'blob',
      })
    ).data;
  useQuery(['signedAgreement', downloadTrigger], getSignedAgreement, {
    enabled: downloadTrigger,
    onSuccess: data => {
      const fileURL = URL.createObjectURL(data);
      const alink = document.createElement('a');
      alink.href = fileURL;
      alink.download = 'PrimeFT-Agreement.pdf';
      alink.click();
    },
  });
  const handleDownloadSignedAgreement = () => setDownloadTrigger(true);

  return { handleDownloadSignedAgreement };
};
