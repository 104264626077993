import { XCircleIcon } from '@heroicons/react/solid';
import { NotificationType } from 'Util/constants';
import { classNames } from 'Util/css/css';

export const NavNotificationAlert = ({
  children,
  closeNotification,
  type,
}: {
  children: JSX.Element;
  closeNotification?: () => void;
  type: NotificationType;
}) => {
  const baseClassNames = 'flex justify-between w-full p-2';
  const typeClassNames = {
    [NotificationType.Success]: classNames(baseClassNames, 'bg-status-success-500'),
    [NotificationType.Error]: classNames(baseClassNames, 'bg-status-error-500'),
    [NotificationType.Warning]: classNames(baseClassNames, 'bg-status-caution-500'),
  };
  return (
    <div className={typeClassNames[type]}>
      <div></div>
      <div>{children}</div>
      <div>
        {closeNotification && (
          <XCircleIcon
            className="h-5 w-5 cursor-pointer fill-primeft-100"
            onClick={closeNotification}
          />
        )}
      </div>
    </div>
  );
};
