import { useApi } from 'Context/ApiContext';
import { useQuery } from 'react-query';
import { Equipment } from 'Util/types';

export const useGetEquipment = () => {
  const api = useApi();
  const fetchEquipment = async () => (await api.get<Equipment[]>('/equipments')).data;
  return useQuery(['equipment'], fetchEquipment, {
    onError: error => console.error(error),
  });
};
