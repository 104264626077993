import { useUnauthenticatedApi } from 'Context/ApiContext';
import { useQuery } from 'react-query';
import { Equipment } from 'Util/types';

export const useGetEquipmentById = (equipmentId: string) => {
  const api = useUnauthenticatedApi();
  const fetchEquipment = async () =>
    (await api.get<Equipment>(`/equipments/${equipmentId}`)).data;
  return useQuery(['equipment', equipmentId], fetchEquipment, {
    enabled: !!equipmentId,
  });
};
