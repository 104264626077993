import { OfficeBuildingIcon, UserIcon } from '@heroicons/react/outline';
import ButtonLarge from 'Component/Button/ButtonLarge';
import { ChecklistItem, ChecklistItemType } from 'Component/ChecklistItem/ChecklistItem';
import { ImageBuilder, ImageType } from 'Component/ImageBuilder/ImageBuilder';
import ApplicationLayout from 'Page/Application/layouts/ApplicationLayout';
import { ApplicationStepsCompleted, ChecklistItemStatus } from 'Util/constants';
import {
  outroDescriptionText,
  introDescriptionText,
  outroTitleText,
  introTitleText,
  outroButtonText,
  outroAuthorizationText,
  introButtonText,
} from './Constants';

type VerificationProps = {
  personalComplete: boolean;
  businessComplete: boolean;
  onContinue: () => void;
};

const Verification = ({
  onContinue,
  personalComplete,
  businessComplete,
}: VerificationProps) => {
  const isOutroPage = personalComplete && businessComplete;
  const titleText = isOutroPage ? outroTitleText : introTitleText;
  const descriptionText = isOutroPage ? outroDescriptionText : introDescriptionText;
  const buttonText = isOutroPage ? outroButtonText : introButtonText;

  const checklist: ChecklistItemType[] = [
    {
      id: 1,
      icon: <UserIcon className="h-5 w-5 text-primary-500" />,
      title: 'Personal Info',
      description:
        'Verify your personal information as the business owner or acting representative.',
      status: personalComplete
        ? ChecklistItemStatus.SUCCESS
        : ChecklistItemStatus.INCOMPLETE,
    },
    {
      id: 2,
      icon: <OfficeBuildingIcon className="h-5 w-5 text-primary-500" />,
      title: 'Business Info',
      description:
        'Verify your business information by entering relevant business details.',
      status: businessComplete
        ? ChecklistItemStatus.SUCCESS
        : ChecklistItemStatus.INCOMPLETE,
    },
  ];
  return (
    <ApplicationLayout stepsCompleted={ApplicationStepsCompleted.IDENTITY_VERIFICATION}>
      <div className="flex flex-col gap-8">
        <div>
          <ImageBuilder imageType={ImageType.VerifyIdentityGraphic} />
          <h1 className="text-2xl lg:text-3xl">{titleText}</h1>
          <p className="text-primeft-500">{descriptionText}</p>
        </div>
        <div className="flex flex-col gap-3">
          {checklist.map(step => (
            <ChecklistItem key={step.id} step={step} />
          ))}
        </div>
        {isOutroPage && (
          <p className="text-xs text-primeft-500">{outroAuthorizationText}</p>
        )}
        <ButtonLarge className="md:ml-auto" arrowRight={true} onClick={onContinue}>
          {buttonText}
        </ButtonLarge>
      </div>
    </ApplicationLayout>
  );
};

export default Verification;
