import { useBorrower } from 'Context/BorrowerContext';
import { useGetEquipmentById } from 'Hook/useGetEquipmentById';
import ApplicationLayout from 'Page/Application/layouts/ApplicationLayout';

import { ApplicationStepsCompleted, OfferProductType } from 'Util/constants';
import { EquipmentPlanDetails } from './EquipmentPlanDetails';
import { MCAPlanDetails } from './MCAPlanDetails';

const ReviewPlanDetails = ({ onContinueHandler }: { onContinueHandler: () => void }) => {
  const { offer } = useBorrower();
  const { data: equipment } = useGetEquipmentById(offer?.equipment_id ?? '');

  return (
    <ApplicationLayout stepsCompleted={ApplicationStepsCompleted.REVIEW}>
      <div className="flex flex-col">
        <h1 className="text-2xl lg:text-3xl">Final Plan Review</h1>
        <p className="text-primeft-500">
          Be sure to review all of the information carefully before confirming your loan.
          {offer?.product_type_id === OfferProductType.BNPL
            ? ''
            : 'This is your last opportunity to adjust your details.'}
        </p>
        {offer?.product_type_id === OfferProductType.BNPL && equipment ? (
          <EquipmentPlanDetails onContinueHandler={onContinueHandler} />
        ) : (
          <MCAPlanDetails onContinueHandler={onContinueHandler} />
        )}
      </div>
    </ApplicationLayout>
  );
};

export default ReviewPlanDetails;
