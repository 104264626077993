import { OfferProvider } from 'Context/OfferContext';
import OfferView from 'Page/Offer/OfferView';

const Offer = () => {
  return (
    <OfferProvider>
      <OfferView />
    </OfferProvider>
  );
};

export default Offer;
