import { ReactElement } from 'react';
import { classNames } from 'Util/css/css';

type RowContent = {
  title: string;
  content: string | ReactElement<HTMLImageElement>;
};
export type TableContent = RowContent[];

const AgreementTable = ({ info }: { info: TableContent }) => {
  return (
    <table className="w-full">
      <tbody>
        {info.map((row, index) => {
          const tdClassName =
            'text-left border-[1px] border-primeft-500 p-2 text-xs flex-1';
          return (
            <tr key={index} className="my-[-1px] flex">
              <td className={classNames(tdClassName, 'mr-[-1px] font-bold')}>
                {row.title}
              </td>
              <td className={tdClassName}>{row.content}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default AgreementTable;
