import { FlagIcon } from '@heroicons/react/outline';
import PlaidButton from 'Component/PlaidButton/PlaidButton';
import { useBankConnection } from 'Context/BankConnectionContext';

export const DisconnectedBankTile = () => {
  const { clearPlaidAccessError } = useBankConnection();
  return (
    <div className="flex w-full flex-row items-center gap-3 rounded-lg border-primeft-300 bg-status-error-500 p-4">
      <div className="flex flex-grow flex-col">
        <div className="flex flex-row items-center gap-2">
          <FlagIcon className="h-5 w-5 stroke-primeft-100" />
          <h3 className="text-sm font-semibold text-primeft-100">
            Your Bank is disconnected
          </h3>
        </div>
        <span className="text-xs text-primeft-100">
          Your bank has been disconnected from your account. We will not be able to access
          until the connection is re-established.
        </span>
      </div>
      <PlaidButton
        successHandler={clearPlaidAccessError}
        failHandler={() => console.log('fail!')}
        styleType="white"
        showDisclaimer={false}
      />
    </div>
  );
};
