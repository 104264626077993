import {
  CashIcon,
  ClipboardCheckIcon,
  OfficeBuildingIcon,
  UserIcon,
  ShieldCheckIcon,
} from '@heroicons/react/outline';
import { CheckCircleIcon } from '@heroicons/react/solid';
import { useThemeContext } from 'Context/ThemeContext';
import { Theme } from 'Util/constants';
import { classNames } from 'Util/css/css';

const ProgressSteps = ({ stepsCompleted }: { stepsCompleted: number }) => {
  const { theme } = useThemeContext();
  const baseIconClassName = 'h-6 w-6';
  const iconClassName = {
    [Theme.PrimeFT]: classNames(baseIconClassName, 'text-primary-500'),
    [Theme.Odeko]: classNames(baseIconClassName, 'text-primeft-300'),
  };
  const baseSelectedIconClassName = 'h-6 w-6';
  const selectedIconClassName = {
    [Theme.PrimeFT]: classNames(baseSelectedIconClassName, 'text-primeft-100'),
    [Theme.Odeko]: classNames(baseSelectedIconClassName, 'text-primeft-700'),
  };

  const baseTextClassName = 'ml-2 text-sm';
  const textClassName = {
    [Theme.PrimeFT]: classNames(baseTextClassName, 'text-primeft-100'),
    [Theme.Odeko]: classNames(baseTextClassName, 'text-primary-300'),
  };
  const selectedClassName = {
    [Theme.PrimeFT]: classNames(baseTextClassName, 'text-primeft-100'),
    [Theme.Odeko]: classNames(baseTextClassName, 'text-primeft-700'),
  };
  const steps = [
    {
      label: 'Create Account',
      icon: <ShieldCheckIcon className={iconClassName[theme || Theme.PrimeFT]} />,
      selectedIcon: (
        <ShieldCheckIcon className={selectedIconClassName[theme || Theme.PrimeFT]} />
      ),
    },
    {
      label: 'Verify Your Identity',
      icon: <UserIcon className={iconClassName[theme || Theme.PrimeFT]} />,
      selectedIcon: (
        <UserIcon className={selectedIconClassName[theme || Theme.PrimeFT]} />
      ),
    },
    {
      label: 'Connect Your Bank',
      icon: <OfficeBuildingIcon className={iconClassName[theme || Theme.PrimeFT]} />,
      selectedIcon: (
        <OfficeBuildingIcon className={selectedIconClassName[theme || Theme.PrimeFT]} />
      ),
    },
    {
      label: 'Review Final Offer',
      icon: <ClipboardCheckIcon className={iconClassName[theme || Theme.PrimeFT]} />,
      selectedIcon: (
        <ClipboardCheckIcon className={selectedIconClassName[theme || Theme.PrimeFT]} />
      ),
    },
    {
      label: 'Sign & Execute',
      icon: <CashIcon className={iconClassName[theme || Theme.PrimeFT]} />,
      selectedIcon: (
        <CashIcon className={selectedIconClassName[theme || Theme.PrimeFT]} />
      ),
    },
  ];
  return (
    <div className="flex flex-col">
      {steps.map((step, index) => (
        <div
          key={index}
          className={classNames(
            'my-2 w-[100%] px-4 py-2',
            index == stepsCompleted ? 'rounded-3xl bg-primary-500' : '',
          )}
        >
          <div className="flex flex-row items-center">
            {stepsCompleted > index ? (
              <CheckCircleIcon className="h-6 w-6 text-status-success-500" />
            ) : stepsCompleted == index ? (
              step.selectedIcon
            ) : (
              step.icon
            )}
            <span
              className={
                stepsCompleted == index
                  ? selectedClassName[theme || Theme.PrimeFT]
                  : textClassName[theme || Theme.PrimeFT]
              }
            >
              {index + 1}. {step.label}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProgressSteps;
