import { ClockIcon } from '@heroicons/react/outline';
import ButtonLarge from 'Component/Button/ButtonLarge';
import CTA from 'Component/CTA/CTA';
import { useThemeContext } from 'Context/ThemeContext';
import { useNavigate } from 'react-router-dom';
import { Theme } from 'Util/constants';
import { classNames } from 'Util/css/css';

const OfferCTA = ({
  offerId,
  offerIsActive,
  offerBelongsToLoan,
}: {
  offerId: string;
  offerIsActive: boolean;
  offerBelongsToLoan: boolean;
}) => {
  const navigate = useNavigate();
  const { theme } = useThemeContext();
  const averageTimeClassName = {
    [Theme.PrimeFT]: 'text-primeft-300',
    [Theme.Odeko]: 'text-primeft-700',
  };
  if (offerIsActive) {
    return (
      <div className="flex flex-col gap-2 p-4">
        <ButtonLarge
          onClick={() => navigate(`/customize/${offerId}`)}
          className="mx-auto max-w-2xl"
        >
          View Your Options
        </ButtonLarge>
        <div
          className={classNames(
            'mt-2 flex items-center justify-center p-2',
            averageTimeClassName[theme || Theme.PrimeFT],
          )}
        >
          <ClockIcon className="h-4 w-4" />
          <p className="pl-2 text-sm">Average time to complete is 9 min</p>
        </div>
      </div>
    );
  }
  if (offerBelongsToLoan) {
    return <CTA onClick={() => navigate('/apply')}>Go to my application</CTA>;
  }
  return <CTA onClick={() => alert('Not implemented yet')}>Request a new offer</CTA>;
};

export default OfferCTA;
