import { AxiosError } from 'axios';
import Loader from 'Component/Loader/Loader';
import { useFlowSequence } from 'Context/FlowSequenceContext';
import { useToastNotification } from 'Context/ToastNotificationContext';
import {
  SubmitVerificationErrorResponse,
  useVerifyInformation,
} from 'Hook/useVerifyInformation';
import Verification from 'Page/Application/Verification/Verification';
import { useState } from 'react';
import { NotificationType, OnboardingServiceErrorCode } from 'Util/constants';

export const SubmitVerification = ({ onContinue }: { onContinue: () => void }) => {
  const { addNotification } = useToastNotification();
  const { refetch } = useFlowSequence();
  const [processing, setProcessing] = useState<boolean>(false);
  const verifyInformation = useVerifyInformation({
    onSuccess: () => {
      onContinue();
    },
    onError: (error: AxiosError<SubmitVerificationErrorResponse>) => {
      const errorCode = error?.response?.data?.error_code;
      if (
        errorCode === OnboardingServiceErrorCode.APPLICATION_UNDER_REVIEW ||
        errorCode === OnboardingServiceErrorCode.APPLICATION_DENIED
      ) {
        refetch();
        return;
      }
      addNotification({
        type: NotificationType.Error,
        title: 'Error verifying your information',
        message: 'Please wait a moment and try again',
      });
      setProcessing(false);
    },
  });
  return (
    <>
      {processing && <Loader />}
      <Verification
        onContinue={() => {
          setProcessing(true);
          verifyInformation.mutate();
        }}
        personalComplete={true}
        businessComplete={true}
      />
    </>
  );
};
