import { ReactComponent as NotFound } from 'Asset/NotFound.svg';
import { ReactComponent as PrimeFTLogo } from 'Asset/PrimeFTLogo.svg';
import ButtonLarge from 'Component/Button/ButtonLarge';
import Footer from 'Component/Footer/Footer';
import { useNavigate } from 'react-router-dom';
import { ButtonSubset } from 'Util/constants';
import {
  buttonClassName,
  buttonText,
  buttonTextClassName,
  containerClassName,
  notFoundIconClassName,
  pageDoesNotExistContainerClassName,
  pageDoesNotExistDescription,
  pageDoesNotExistSubtitleClassName,
  pageDoesNotExistTitle,
  pageDoesNotExistTitleClassName,
  primeLogoClassName,
} from './PageNotFound.constants';

export const PageNotFound = () => {
  const navigate = useNavigate();
  const redirectToHome = () => {
    navigate('/');
  };

  return (
    <div className={containerClassName}>
      <PrimeFTLogo className={primeLogoClassName} />
      <div className={pageDoesNotExistContainerClassName}>
        <NotFound className={notFoundIconClassName} />
        <span className={pageDoesNotExistTitleClassName}>{pageDoesNotExistTitle}</span>
        <span className={pageDoesNotExistSubtitleClassName}>
          {pageDoesNotExistDescription}
        </span>
        <ButtonLarge
          subset={ButtonSubset.Clear}
          className={buttonClassName}
          onClick={redirectToHome}
        >
          <span className={buttonTextClassName}>{buttonText}</span>
        </ButtonLarge>
      </div>
      <Footer />
    </div>
  );
};

export default PageNotFound;
