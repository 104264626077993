import { createSlice } from '@reduxjs/toolkit';

export interface OfferState {
  amount: number;
  rate: number;
  offerId: string;
  userId: string;
  loanId: string;
}

export const amountStep = 5000;
export const rateStep = 0.01;

const initialState: OfferState = {
  rate: 0.1,
  amount: 25000,
  offerId: '',
  userId: '',
  loanId: '',
};

export const offerSlice = createSlice({
  name: 'offer',
  initialState,
  reducers: {
    incrementAmount: state => {
      return { ...state, amount: state.amount + amountStep };
    },
    decrementAmount: state => {
      return { ...state, amount: state.amount - amountStep };
    },
    incrementRate: state => {
      return { ...state, rate: +(state.rate + rateStep).toFixed(2) };
    },
    decrementRate: state => {
      return { ...state, rate: +(state.rate - rateStep).toFixed(2) };
    },
    rateSet: (state, action: { payload: { rate: OfferState['rate'] } }) => {
      const { rate } = action.payload;
      return { ...state, rate };
    },
    amountSet: (state, action: { payload: { amount: OfferState['amount'] } }) => {
      const { amount } = action.payload;
      return { ...state, amount };
    },
    offerIdSet: (state, action: { payload: OfferState['offerId'] }) => {
      return { ...state, offerId: action.payload };
    },
    userIdSet: (state, action: { payload: OfferState['userId'] }) => {
      return { ...state, userId: action.payload };
    },
    loanIdSet: (state, action: { payload: OfferState['loanId'] }) => {
      return { ...state, loanId: action.payload };
    },
  },
});

export const {
  incrementAmount,
  decrementAmount,
  incrementRate,
  decrementRate,
  rateSet,
  amountSet,
  offerIdSet,
  userIdSet,
  loanIdSet,
} = offerSlice.actions;

export default offerSlice.reducer;
