import { AxiosError } from 'axios';
import { useApi } from 'Context/ApiContext';
import { useQuery } from 'react-query';

export enum PaymentStatusNames {
  PENDING = 'pending',
  CANCELLED = 'cancelled',
  PROCESSED = 'processed',
  FAILED = 'failed',
}
export type PaymentStatus = {
  id: number;
  name: PaymentStatusNames;
  created_at: string;
  updated_at: string;
};

export const useGetPaymentStatuses = () => {
  const api = useApi();

  const getPaymentStatuses = async () => (await api.get('/payments/statuses')).data;

  return useQuery<PaymentStatus[], AxiosError, PaymentStatus[]>(
    ['payment_statuses'],
    getPaymentStatuses,
  );
};
