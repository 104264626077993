import { toCurrency } from 'Util/currency/currency';

const ChartColumn = ({
  maxValue,
  value,
  rate,
  label,
}: {
  maxValue: number;
  value: number;
  rate: number;
  label: string;
}) => {
  const repaymentValue = Math.round(value * rate);
  const repaymentValueClassName =
    'text-1xl bg-primeft-900 text-white mb-4 rounded-lg p-2';
  const totalHeight = 200 * +(value / maxValue).toFixed(3);
  const bottomBarHeight = totalHeight * (1 - rate);
  const topBarHeight = totalHeight * rate;

  return (
    <div className="flex w-16 flex-col items-center justify-center">
      <div className="tooltip-arrow"></div>
      <div className={repaymentValueClassName}>{toCurrency(repaymentValue, 0)}</div>
      {value ? (
        <div>
          <div
            className="w-8 rounded-t-md bg-primary-700"
            style={{ height: `${topBarHeight}px` }}
          ></div>
          <div
            className="w-8 rounded-b-md bg-primary-500"
            style={{ height: `${bottomBarHeight}px` }}
          ></div>
        </div>
      ) : (
        <div className="h-4 w-8 rounded-b-md rounded-t-md bg-primary-500"></div>
      )}
      <div className="my-2 text-center text-sm font-semibold text-black">
        {value ? toCurrency(value, 0) : 'No Sales'}
      </div>
      <div className="mt-4 text-center text-sm font-semibold text-black">{label}</div>
    </div>
  );
};

export default ChartColumn;
