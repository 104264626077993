export const makeFirstResultNumber = (currentPage: number, resultsPerPage: number) => {
  if (currentPage === 1) return 1;
  return (currentPage - 1) * resultsPerPage;
};

export const makeLastResultNumber = (
  currentPage: number,
  resultsPerPage: number,
  resultsCount: number,
) => {
  const lastPageResultNumber = currentPage * resultsPerPage;
  return lastPageResultNumber < resultsCount
    ? currentPage * resultsPerPage
    : resultsCount;
};
