import { Theme } from 'Util/constants';
import { classNames } from 'Util/css/css';

export const baseInputClassName =
  'peer w-full h-[58px] border-b-1 border-primeft-300 text-primeft-900 placeholder-transparent focus:pt-4 pt-4 focus:outline-none focus:ring-primary-500 rounded-md';
export const inputClassName: Record<Theme, string> = {
  [Theme.PrimeFT]: classNames(
    baseInputClassName,
    'border-primeft-300 text-primeft-900 focus:ring-primary-500',
  ),
  [Theme.Odeko]: classNames(
    baseInputClassName,
    'border-primeft-700 text-primeft-700 focus:ring-primary-500 bg-primeft-500',
  ),
};

export const baseLabelClassName =
  'cursor-text absolute left-3 top-2 text-primeft-500 text-xs transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-primeft-500 peer-placeholder-shown:top-4 peer-focus:top-2 peer-focus:text-primeft-500 peer-focus:text-xs';
