import { useThemeContext } from 'Context/ThemeContext';
import { ReactNode } from 'react';
import { Theme } from 'Util/constants';
import { classNames } from 'Util/css/css';

const WidgetLayout = ({
  title,
  topRight,
  children,
}: {
  title: string;
  topRight?: ReactNode;
  children: ReactNode;
}) => {
  const { theme } = useThemeContext();
  const containerBackgroundColor = {
    [Theme.Odeko]: 'bg-primeft-300',
    [Theme.PrimeFT]: 'bg-primeft-100',
  };
  const containerClassName = classNames(
    'flex flex-col gap-4 p-4 border border-primeft-300 md:p-8 md:min-h-[380px] rounded-md',
    containerBackgroundColor[theme || Theme.PrimeFT],
  );
  const headerContainerClassName = 'flex flex-row justify-between items-center';
  const titleClassName = 'text-md font-semibold';

  return (
    <div className={containerClassName}>
      <div className={headerContainerClassName}>
        <div className={titleClassName}>{title}</div>
        {topRight}
      </div>
      {children}
    </div>
  );
};

export default WidgetLayout;
