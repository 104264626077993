import {
  CalendarIcon,
  CashIcon,
  ChartPieIcon,
  ClockIcon,
  CreditCardIcon,
  ReceiptTaxIcon,
} from '@heroicons/react/outline';
import { ArrowDownIcon } from '@heroicons/react/solid';
import Link from 'Component/Link/Link';
import { useThemeContext } from 'Context/ThemeContext';
import { useGetDownloadSignedAgreement } from 'Hook/useGetDownloadSignedAgreement';
import { useLoanCalculations } from 'Hook/useLoanCalculations';
import { ActiveInfoLine } from 'Page/Dashboard/components/ActiveInfoLine';
import WidgetLayout from 'Page/Dashboard/components/WidgetLayout';
import { Theme } from 'Util/constants';
import { classNames } from 'Util/css/css';
import { toCurrency } from 'Util/currency/currency';
import { Loan } from 'Util/types';

export const EquipmentPaymentPlan = ({ loan }: { loan: Loan | undefined }) => {
  const { handleDownloadSignedAgreement } = useGetDownloadSignedAgreement({
    loanId: loan?.id || '',
  });
  const {
    loanAmount,
    totalLoanAmount,
    totalCost,
    totalPayments,
    installmentAmount,
    frequency,
  } = useLoanCalculations();
  const { theme } = useThemeContext();
  const linkColorClassName = {
    [Theme.PrimeFT]: 'text-primary-500 hover:text-primary-700',
    [Theme.Odeko]: 'text-primeft-700 hover:text-primeft-500',
  };
  const columnClassName = 'flex flex-col w-1/2 gap-16';
  const paymentFrequencyWeeks = Math.floor(frequency / 7);

  return (
    <WidgetLayout
      title={'Equipment Payment Plan'}
      topRight={
        <Link onClick={handleDownloadSignedAgreement}>
          <div
            className={classNames(
              'mx-auto flex flex-row items-center justify-center text-xs font-semibold text-primary-500',
              linkColorClassName[theme || Theme.PrimeFT],
            )}
          >
            Download Financing Agreement
            <ArrowDownIcon className="ml-2 h-3 w-3" />
          </div>
        </Link>
      }
    >
      <div className="flex flex-row gap-4 pt-6">
        <div className={columnClassName}>
          <ActiveInfoLine
            icon={<CreditCardIcon />}
            title="Loan Amount"
            amount={toCurrency(loanAmount)}
          />
          <ActiveInfoLine
            icon={<CashIcon />}
            title="Total Owed"
            amount={toCurrency(totalLoanAmount)}
          />
          <ActiveInfoLine
            icon={<ClockIcon />}
            title="Payment Frequency"
            amount={`${paymentFrequencyWeeks} Weeks`}
          />
        </div>
        <div className={columnClassName}>
          <ActiveInfoLine
            icon={<ChartPieIcon />}
            title="Finance Charge"
            amount={toCurrency(totalCost)}
          />
          <ActiveInfoLine
            icon={<ReceiptTaxIcon />}
            title="Payment"
            amount={toCurrency(installmentAmount ?? 0)}
          />
          <ActiveInfoLine
            icon={<CalendarIcon />}
            title="Total Payments"
            amount={`${totalPayments}`}
          />
        </div>
      </div>
    </WidgetLayout>
  );
};
