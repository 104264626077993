export function useOfferDetailsFromLocalStorage() {
  const amount = localStorage.getItem('offerAmount');
  const rate = localStorage.getItem('offerRate');
  const offerId = localStorage.getItem('offerId');

  return {
    amount,
    rate,
    offerId,
  };
}
