import React, { forwardRef } from 'react';
import { StyledWithChildren } from 'Util/types';

type THeadCellProps = StyledWithChildren & React.HTMLProps<HTMLTableCellElement>;

const THeadCell = forwardRef<HTMLTableCellElement, THeadCellProps>(
  ({ className, children, ...props }, ref) => (
    <th
      scope="col"
      className={`rounded-md border border-primeft-300 px-3 py-3.5 text-left text-bodySmall font-semibold text-primeft-300 ${
        className ?? ''
      }`}
      ref={ref}
      {...props}
    >
      {children}
    </th>
  ),
);

THeadCell.displayName = 'THeadCell';

export default THeadCell;
