import { UnknownIssue } from 'Component/UnknownIssue/UnknownIssue';
import ApplicationLayout from 'Page/Application/layouts/ApplicationLayout';
import { ApplicationStepsCompleted } from 'Util/constants';

export const UnderwritingDenied = () => {
  const titleText = 'Cannot continue with the application';
  const subtitleText =
    'We are unable to move forward with the application for undisclosed reasons. We are sorry for the inconvenience.';

  return (
    <ApplicationLayout stepsCompleted={ApplicationStepsCompleted.REVIEW}>
      <UnknownIssue titleText={titleText} subtitleText={subtitleText} />
    </ApplicationLayout>
  );
};
