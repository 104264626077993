import THead from 'Component/THead/THead';
import THeadCell from 'Component/THeadCell/THeadCell';
import THeadRow from 'Component/THeadRow/THeadRow';
import { useThemeContext } from 'Context/ThemeContext';
import React, { forwardRef } from 'react';
import { Theme } from 'Util/constants';
import type { TableElementProps } from 'Util/types';

type TableProps = TableElementProps & React.HTMLProps<HTMLTableElement>;

const Table = forwardRef<HTMLTableElement, TableProps>(
  ({ children, className, tableConfig, ...props }, ref) => {
    const { theme } = useThemeContext();
    const theadClassName = {
      [Theme.Odeko]: 'sticky top-0 bg-primeft-500 rounded-md',
      [Theme.PrimeFT]: 'sticky top-0 bg-primeft-300 rounded-md',
    };
    return (
      <table className={`min-w-full divide-y ${className ?? ''}`} ref={ref} {...props}>
        {tableConfig && (
          <THead className={theadClassName[theme || Theme.PrimeFT]}>
            <THeadRow>
              {tableConfig.columns.map(column => (
                <THeadCell className={column.className} key={column.title}>
                  <div className="flex items-center">
                    <span className="mr-1 text-primeft-900">{column.title}</span>
                  </div>
                </THeadCell>
              ))}
            </THeadRow>
          </THead>
        )}
        {children}
      </table>
    );
  },
);

Table.displayName = 'Table';

export default Table;
