import Modal from 'Component/Modal/Modal';
import { useBorrower } from 'Context/BorrowerContext';
import { CreatePaymentModal } from 'Page/Dashboard/components/PaymentModal/CreatePaymentModal';
import { DeletePaymentModal } from 'Page/Dashboard/components/PaymentModal/DeletePaymentModal';
import { EditPaymentModal } from 'Page/Dashboard/components/PaymentModal/EditPaymentModal';
import { SuccessPaymentModal } from 'Page/Dashboard/components/PaymentModal/SuccessPaymentModal';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, PaymentModalMode } from 'Store/PaymentModalSlice';
import { RootState } from 'Store/store';
import { ModalSize } from 'Util/constants';

export const PaymentModal = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}) => {
  const { loanDetails } = useBorrower();
  const { mode, paymentId } = useSelector((state: RootState) => state.paymentModal);
  const dispatch = useDispatch();
  const balance = loanDetails?.balance ?? 0;
  const pending_payments = loanDetails?.pending_payments ?? 0;
  const remainingBalance = balance - pending_payments;
  const handleClose = () => {
    setIsOpen(false);
    setTimeout(() => dispatch(closeModal()), 500);
  };
  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={open => (open ? setIsOpen(true) : handleClose())}
      label="Manual Payments"
      size={ModalSize.Small}
    >
      {mode === PaymentModalMode.CREATE && (
        <CreatePaymentModal balance={remainingBalance} />
      )}
      {mode === PaymentModalMode.EDIT && (
        <EditPaymentModal paymentId={paymentId} balance={remainingBalance} />
      )}
      {mode === PaymentModalMode.DELETE && (
        <DeletePaymentModal paymentId={paymentId} handleClose={handleClose} />
      )}
      {mode === PaymentModalMode.SUCCESS && (
        <SuccessPaymentModal handleClose={handleClose} />
      )}
    </Modal>
  );
};
