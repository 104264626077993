export const ActiveInfoLine = ({
  icon,
  title,
  amount,
}: {
  icon: React.ReactElement;
  title: string;
  amount: string;
}) => {
  return (
    <div className="flex flex-row items-center justify-between py-1">
      <span className="flex flex-row gap-2">
        <span className="h-6 w-6 text-primary-500">{icon}</span>
        <span className="text-sm">{title}</span>
      </span>
      <div className="mx-4 flex flex-grow border-[0.5px] border-dashed" />
      <span className="text-md font-medium">{amount}</span>
    </div>
  );
};
