import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/solid';
import { ButtonHTMLAttributes, forwardRef } from 'react';
import { ButtonSubset } from 'Util/constants';
import { classNames } from 'Util/css/css';
import { getButtonLargeClassNames } from './button-utils';

export type ButtonLargeProps = {
  children: React.ReactNode;
  IconStart?: React.ReactNode;
  IconEnd?: React.ReactNode;
  className?: string;
  subset?: ButtonSubset;
  arrowLeft?: boolean;
  arrowRight?: boolean;
  disabled?: boolean;
  size?: 'sm' | 'lg';
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const ButtonLarge = forwardRef<HTMLButtonElement, ButtonLargeProps>(
  (
    {
      children,
      className = '',
      disabled,
      subset = ButtonSubset.Fill,
      arrowLeft,
      arrowRight,
      size = 'lg',
      IconStart,
      IconEnd,
      ...props
    }: ButtonLargeProps,
    ref,
  ) => {
    const { baseClassName, buttonClassNames, textClassNames, sizesClassName } =
      getButtonLargeClassNames({ disabled, subset, size });

    const baseIconClassName = 'group-hover:text-button-primary';
    const iconClassName: Record<typeof size, string> = {
      lg: classNames(baseIconClassName, 'h-4 w-4'),
      sm: classNames(baseIconClassName, 'h-3 w-3'),
    };

    return (
      <button
        className={classNames(
          baseClassName,
          sizesClassName,
          buttonClassNames,
          textClassNames,
          className,
        )}
        ref={ref}
        disabled={disabled}
        {...props}
      >
        {IconStart}
        {arrowLeft && (
          <ArrowLeftIcon
            className={classNames('mr-2', iconClassName[size], textClassNames)}
            aria-hidden="true"
          />
        )}
        {children}
        {arrowRight && (
          <ArrowRightIcon
            className={classNames('ml-2', iconClassName[size], textClassNames)}
            aria-hidden="true"
          />
        )}
        {IconEnd}
      </button>
    );
  },
);

ButtonLarge.displayName = 'Button';

export default ButtonLarge;
