import { forwardRef } from 'react';
import type { TSectionProps } from 'Util/types';

const TBody = forwardRef<HTMLTableSectionElement, TSectionProps>(
  ({ className, ...props }, ref) => (
    <tbody
      className={`divide-y divide-primeft-300 ${className ?? ''}`}
      ref={ref}
      {...props}
    />
  ),
);

TBody.displayName = 'TBody';

export default TBody;
