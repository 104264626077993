import { Loader } from '@googlemaps/js-api-loader';
import Input from 'Component/Input/Input';
import { convertPlaceAddressComponentsToAddress } from 'Component/Input/utils/utils';
import { ComponentProps, useEffect, useState } from 'react';

export type AddressParams = {
  address_line_1: string;
  address_line_2: string;
  address_city: string;
  address_state: string;
  address_postal_code: string;
  address_country_code: string;
};

export default function AddressInput({
  control,
  name,
  label,
  setAddress,
  divClassName,
  setAddressComponents,
  error,
  rules,
}: {
  control: any;
  name: 'first_name' | 'last_name' | 'dob' | 'ssn' | 'business_name' | 'address' | 'ein';
  label: string;
  setAddress: (newValue: google.maps.places.AutocompletePrediction) => void;
  divClassName?: string;
  setAddressComponents: (newValue: AddressParams) => void;
} & ComponentProps<typeof Input>) {
  const apiKey = process.env.REACT_APP_PLACES_API_KEY || '';

  const [placesAutocompleteService, setPlacesAutocompleteService] = useState<
    google.maps.places.AutocompleteService | undefined
  >(undefined);
  const [placesService, setPlacesService] = useState<
    google.maps.places.PlacesService | undefined
  >(undefined);
  const [sessionToken, setSessionToken] = useState<
    google.maps.places.AutocompleteSessionToken | undefined
  >(undefined);
  const [suggestions, setSuggestions] = useState<
    google.maps.places.AutocompletePrediction[] | null
  >(null);

  const initializeService = () => {
    try {
      if (!window.google) throw new Error('Google script not loaded');
      if (!window.google.maps) throw new Error('Google maps script not loaded');
      if (!window.google.maps.places)
        throw new Error('Google maps places script not loaded');
    } catch (e) {
      console.error(e);
      return;
    }

    setPlacesAutocompleteService(new window.google.maps.places.AutocompleteService());
    setPlacesService(
      new window.google.maps.places.PlacesService(document.createElement('div')),
    );
    setSessionToken(new google.maps.places.AutocompleteSessionToken());
  };

  useEffect(() => {
    const init = async () => {
      try {
        if (!window.google || !window.google.maps || !window.google.maps.places) {
          await new Loader({
            apiKey,
            libraries: ['places'],
          }).load();
        }
        initializeService();
      } catch (error) {
        console.error(error);
      }
    };

    if (apiKey) init();
    else initializeService();
  }, [apiKey]);

  const loadSuggestions = (value: string) => {
    if (value == '') {
      setSuggestions(null);
      return;
    }
    if (placesAutocompleteService && sessionToken) {
      placesAutocompleteService.getPlacePredictions(
        {
          input: value,
          sessionToken,
        },
        (predictions, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            setSuggestions(predictions);
          }
        },
      );
    }
  };

  return (
    <div className={divClassName}>
      <div className="relative">
        <Input
          name={name}
          label={label}
          control={control}
          sideEffect={loadSuggestions}
          error={error}
          rules={rules}
        />
        {suggestions && suggestions.length > 0 && (
          <div className="relative">
            <ul className="absolute top-0 z-10 block w-full text-gray-700 transition-all">
              {suggestions.map(suggestion => {
                return (
                  <li
                    key={suggestion.place_id}
                    className="block w-full cursor-pointer border-x-2 border-b-2 border-primeft-300 bg-primeft-100 p-4 hover:bg-primeft-900 hover:text-primeft-100"
                    onClick={() => {
                      placesService?.getDetails(
                        { placeId: suggestion.place_id },
                        (place, status) => {
                          if (
                            status === window.google.maps.places.PlacesServiceStatus.OK &&
                            place?.address_components != undefined
                          ) {
                            setAddressComponents(
                              convertPlaceAddressComponentsToAddress(
                                place.address_components,
                              ),
                            );
                          }
                        },
                      );
                      setAddress(suggestion);
                      setSuggestions(null);
                    }}
                  >
                    <span>{suggestion.description}</span>
                  </li>
                );
              })}
              <li className="logo -mt-2 flex justify-end border-x-2 border-b-2 border-primeft-300 bg-primeft-100">
                <img
                  src="https://developers.google.com/static/maps/documentation/images/google_on_white.png"
                  alt="Powered by Google"
                />
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}
