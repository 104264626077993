import { useAuth0 } from '@auth0/auth0-react';
import { useBorrower } from 'Context/BorrowerContext';
import { useGetLoanAppStatus } from 'Hook/Loans/useGetLoanAppStatus';
import { useOfferDetailsFromLocalStorage } from 'Hook/Offers/useOfferDetailsFromLocalStorage';
import { useSetupAccount } from 'Hook/useSetupAccount';
import { createContext, useContext, useEffect, useState } from 'react';
import { LoanApplicationStatuses } from 'Util/constants';

type FlowSequenceContextValue = {
  currentStep: LoanApplicationStatuses | 'loading';
  refetch: () => void;
};

const FlowSequenceContext = createContext<FlowSequenceContextValue | null>(null);

export function FlowSequenceContextProvider(props: { children?: React.ReactNode }) {
  const [currentStep, setCurrentStep] = useState<LoanApplicationStatuses | 'loading'>(
    'loading',
  );
  const { loan, isLoading: isLoadingBorrower } = useBorrower();
  const { data, refetch } = useGetLoanAppStatus({ loanId: loan?.id });
  const { amount, rate, offerId } = useOfferDetailsFromLocalStorage();
  const { user: auth0User } = useAuth0();
  const {
    mutateAsync: setupAccount,
    isLoading: isLoadingSetupAccount,
    isError,
    isSuccess,
  } = useSetupAccount();

  useEffect(() => {
    if (
      auth0User &&
      !loan &&
      !isLoadingBorrower &&
      !isLoadingSetupAccount &&
      !isError &&
      !isSuccess &&
      offerId &&
      amount
    ) {
      console.log('setting up account');
      setupAccount({
        auth0_id: auth0User.sub || '',
        email: auth0User.email || '',
        capital_amount: +amount,
        remittance_rate: +(rate ?? 0),
        offer_id: offerId,
      });
    }
  }, [
    auth0User,
    loan,
    isLoadingBorrower,
    isLoadingSetupAccount,
    isError,
    isSuccess,
    offerId,
    amount,
    rate,
    setupAccount,
  ]);

  useEffect(() => {
    if (!offerId && !isLoadingBorrower && !loan) {
      setCurrentStep(LoanApplicationStatuses.NO_OFFER);
    }
  }, [offerId, isLoadingBorrower, loan]);

  useEffect(() => {
    if (data?.app_status) {
      setCurrentStep(data.app_status);
    }
  }, [data?.app_status]);

  return <FlowSequenceContext.Provider {...props} value={{ currentStep, refetch }} />;
}

export function useFlowSequence() {
  const context = useContext(FlowSequenceContext);

  if (!context) {
    throw new Error('FlowSequence Context is not provided');
  }
  return context;
}
