import ButtonLarge from 'Component/Button/ButtonLarge';
import Loader from 'Component/Loader/Loader';
import { useAgreementContext } from 'Context/AgreementContext';
import { useBorrower } from 'Context/BorrowerContext';
import { useToastNotification } from 'Context/ToastNotificationContext';
import { usePostSignedAgreement } from 'Hook/usePostSignedAgreement';
import ApplicationLayout from 'Page/Application/layouts/ApplicationLayout';
import { FinancingAgreement } from 'Page/Application/Sign/components/FinancingAgreement';
import { Itemization } from 'Page/Application/Sign/components/Itemization';
import { OfferSummary } from 'Page/Application/Sign/components/OfferSummary';
import { DocumentSubtitle, DocumentTitle } from 'Page/Application/Sign/constants';
import { useState } from 'react';
import {
  ApplicationStepsCompleted,
  ChecklistItemStatus,
  NotificationType,
} from 'Util/constants';
import { AgreementTypes } from 'Util/types';

export const DocumentView = ({ agreementType }: { agreementType: AgreementTypes }) => {
  const { addNotification } = useToastNotification();
  const [processing, setProcessing] = useState<boolean>(false);
  const { loan, user } = useBorrower();
  const [signature, setSignature] = useState<string>('');
  const [validSignature, setValidSignature] = useState(false);
  const { setAgreements } = useAgreementContext();

  const { mutate: postSignedAgreement } = usePostSignedAgreement({
    onSuccess: () => {
      setAgreements(prev => {
        return prev.map(agreement => {
          if (agreement.agreementType === agreementType) {
            return { ...agreement, status: ChecklistItemStatus.SUCCESS };
          }
          return agreement;
        });
      });
      setProcessing(false);
    },
    onError: () => {
      addNotification({
        type: NotificationType.Error,
        title: 'Error',
        message: 'There was an error signing your agreement',
      });
      setProcessing(false);
    },
  });

  const handleChangeSignature = (signature: string): void => {
    setSignature(signature);
    if (user && user.fullName === signature) {
      setValidSignature(true);
    } else {
      setValidSignature(false);
    }
  };

  return (
    <ApplicationLayout
      stepsCompleted={ApplicationStepsCompleted.SIGNING}
      size={agreementType !== 'itemization' ? 'large' : 'normal'}
    >
      <div className="flex flex-col gap-4">
        {processing && <Loader />}
        <div>
          <h1 className="text-2xl lg:text-3xl">{DocumentTitle[agreementType]}</h1>
          <h2 className="text-onWhite-medium">{DocumentSubtitle[agreementType]}</h2>
        </div>
        {agreementType === 'financing' || agreementType === 'unsecured_financing' ? (
          <FinancingAgreement
            signature={signature}
            setSignature={handleChangeSignature}
            validSignature={validSignature}
          />
        ) : agreementType === 'offer_summary' ? (
          <OfferSummary />
        ) : agreementType === 'itemization' ? (
          <Itemization />
        ) : (
          <div>No such agreement type</div>
        )}
        <ButtonLarge
          arrowRight={true}
          onClick={() => {
            setProcessing(true);
            postSignedAgreement({
              agreement_type: agreementType,
              loan_id: loan?.id || '',
              use_of_funds: 'test',
              provided_signature: signature,
            });
          }}
          disabled={agreementType === 'financing' && !validSignature && !processing}
        >
          Accept
        </ButtonLarge>
      </div>
    </ApplicationLayout>
  );
};
