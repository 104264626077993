import { useAuth0 } from '@auth0/auth0-react';
import { useApi } from 'Context/ApiContext';
import { useQuery } from 'react-query';
import { LoanApplicationStatuses } from 'Util/constants';

type AppStatusType = {
  app_status: LoanApplicationStatuses;
};

export const useGetLoanAppStatus = ({ loanId }: { loanId?: string }) => {
  const api = useApi();
  const { isAuthenticated } = useAuth0();
  const fetchLoanAppStatus = async () =>
    (await api.get<AppStatusType>(`/loans/${loanId}/app_status`)).data;
  const { data, error, isLoading, refetch } = useQuery(
    ['loanAppStatus', isAuthenticated, loanId],
    fetchLoanAppStatus,
    {
      refetchOnWindowFocus: false,
      enabled: isAuthenticated && !!loanId,
    },
  );
  return { data, error, isLoading, refetch };
};
