import { useBorrower } from 'Context/BorrowerContext';
import { useGetAgreementsInfo } from 'Hook/useGetAgreementsInfo';
import { classNames } from 'Util/css/css';
import { toCurrency } from 'Util/currency/currency';

export const Itemization = () => {
  const { loan } = useBorrower();
  const { data } = useGetAgreementsInfo(loan?.id);
  if (!data) return <div>Loading...</div>;
  const { givenDirectly, paidBySupplier, prepaidFinanceCharges } = data.itemization;
  const amountOnBehalfOfBorrower = givenDirectly + paidBySupplier;
  const amountFinanced = amountOnBehalfOfBorrower - prepaidFinanceCharges;
  const borderClassName = 'border -mt-[1px] border-black';
  const fullWidthClassName = 'col-span-4 flex flex-row justify-between p-2';
  const halfWidthClassName = 'col-span-2 flex flex-row justify-between p-2';
  const quarterWidthClassName = 'col-span-1 flex flex-row justify-end -ml-[1px] p-2';
  return (
    <div className="grid grid-cols-4">
      <div className={classNames(borderClassName, fullWidthClassName)}>
        <span className="col-span-2">1. Amount Given Directly to You</span>
        <span className="text-right">{toCurrency(givenDirectly)}</span>
      </div>
      <div className={classNames(borderClassName, fullWidthClassName)}>
        <span>2. Amount Paid on Your Behalf to Your Supplier or Retailer</span>
        <span>{toCurrency(paidBySupplier)}</span>
      </div>
      <div className={classNames(borderClassName, fullWidthClassName)}>
        <span>3. Amount Provided to You or on Your Behalf (1+2)</span>
        <span>{toCurrency(amountOnBehalfOfBorrower)}</span>
      </div>
      <div className={classNames(borderClassName, fullWidthClassName)}>
        <span>4. Prepaid Finance Charges</span>
        <span>{toCurrency(prepaidFinanceCharges)}</span>
      </div>
      <div className={classNames(borderClassName, halfWidthClassName)}>
        <span>5. Amount Financed (3 minus 4)</span>
      </div>
      <div className={classNames(borderClassName, quarterWidthClassName)}>
        <span>{toCurrency(amountFinanced)}</span>
      </div>
    </div>
  );
};
