import { UnknownIssue } from 'Component/UnknownIssue/UnknownIssue';
import ApplicationLayout from 'Page/Application/layouts/ApplicationLayout';
import { ApplicationStepsCompleted } from 'Util/constants';

export const OnboardingDenied = () => {
  const titleText = 'Cannot continue with the application';
  const subtitleText =
    "I'm sorry, but your loan application has been paused due to the information you entered. Please contact us to get to the bottom of this.";

  return (
    <ApplicationLayout stepsCompleted={ApplicationStepsCompleted.IDENTITY_VERIFICATION}>
      <UnknownIssue titleText={titleText} subtitleText={subtitleText} />
    </ApplicationLayout>
  );
};
