import { withAuthenticationRequired } from '@auth0/auth0-react';
import { WithAuthenticationRequiredOptions } from '@auth0/auth0-react/src/with-authentication-required';
import Loading from 'Page/Loading/Loading';
import { FC } from 'react';

// Documentation: https://stackoverflow.com/questions/71744479/passing-props-to-the-components-of-protectedroute-in-auth0
const ProtectedPage: FC<{
  component: FC;
  options?: WithAuthenticationRequiredOptions;
}> = ({ component, options }) => {
  const Component: FC = withAuthenticationRequired(component, {
    onRedirecting: () => <Loading title={'Connecting with Auth0'} />,
    ...options,
  });

  return <Component />;
};

export default ProtectedPage;
