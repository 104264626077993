import { inputClassName, baseLabelClassName } from 'Component/Input/input-constants';
import { useThemeContext } from 'Context/ThemeContext';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { Theme } from 'Util/constants';

type SelectProps<TFieldValues extends FieldValues = FieldValues, TContext = any> = {
  control: Control<TFieldValues, TContext>;
  name: Path<TFieldValues>;
  label: string;
  options: {
    value: string;
    label?: string;
  }[];
};

function Select<TFieldValues extends FieldValues = FieldValues, TContext = any>({
  control,
  name,
  label,
  options,
}: SelectProps<TFieldValues, TContext>) {
  const { theme } = useThemeContext();

  return (
    <div className="relative">
      <Controller<TFieldValues>
        name={name}
        control={control}
        render={({ field }) => (
          <select
            {...field}
            id={field.name}
            className={inputClassName[theme ?? Theme.PrimeFT]}
            onChange={field.onChange}
          >
            {options.map(item => (
              <option key={item.value} value={item.value}>
                {item.label ?? item.value}
              </option>
            ))}
          </select>
        )}
      />
      <label htmlFor={name} className={baseLabelClassName}>
        {label}
      </label>
    </div>
  );
}

export default Select;
