import { useThemeContext } from 'Context/ThemeContext';
import { useNavigate } from 'react-router-dom';
import { FooterNavigation, Theme } from 'Util/constants';

export default function Footer() {
  const { theme } = useThemeContext();
  const navigate = useNavigate();
  const footerClassName = {
    [Theme.PrimeFT]: '',
    [Theme.Odeko]: 'bg-primeft-300',
  };
  return (
    <footer className={footerClassName[theme || Theme.PrimeFT]}>
      <div className="mx-auto max-w-7xl overflow-hidden py-4 px-4 sm:px-6 lg:px-8">
        <nav className="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
          {FooterNavigation.map(item => (
            <div key={item.name} className="px-4 py-2">
              <span
                onClick={() => navigate(`/${item.slug}`)}
                className="cursor-pointer text-xs text-onWhite-medium hover:text-onWhite-dark"
              >
                {item.name}
              </span>
            </div>
          ))}
        </nav>
        <p className="mt-1 text-center text-xs text-onWhite-medium">
          &copy; 2023 Prime Financial Technologies, Inc.
        </p>
      </div>
    </footer>
  );
}
