import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { useThemeContext } from 'Context/ThemeContext';
import { Theme } from 'Util/constants';
import { classNames } from 'Util/css/css';

type IncrementorProps = {
  title: string;
  currentValue: string;
  incrementFunction: () => void;
  decrementFunction: () => void;
  disableIncrement: boolean;
  disableDecrement: boolean;
  showMinimum?: boolean;
  showMaximum?: boolean;
  minValue?: string;
  maxValue?: string;
};
const Incrementor = ({
  title,
  currentValue,
  incrementFunction,
  decrementFunction,
  disableIncrement,
  disableDecrement,
  showMinimum = false,
  showMaximum = false,
  minValue = '',
  maxValue = '',
}: IncrementorProps) => {
  const { theme } = useThemeContext();
  const tileClassName =
    'bg-primeft-500 px-4 py-2 rounded-lg border border-500 flex flex-row justify-between';
  const baseModifyButtonClassName =
    'rounded-full h-8 w-8 flex justify-center items-center shadow-lg';
  const modifyButtonClassName = {
    [Theme.PrimeFT]: 'bg-primeft-100',
    [Theme.Odeko]: 'bg-primary-500',
  };
  const chevronClassName = 'fill-complimentary-black h-6 w-6';
  const disabledClassName = 'opacity-30 cursor-default';
  return (
    <div className={tileClassName}>
      <div className="flex flex-col gap-1 text-left">
        <span className="text-xs">{title}</span>
        <span className="text-3xl font-semibold">{currentValue}</span>
        {showMinimum && (
          <span className="text-xs text-primeft-500">{minValue} minimum</span>
        )}
        {showMaximum && (
          <span className="text-xs text-primeft-500">{maxValue} maximum</span>
        )}
      </div>
      <div className="flex-start flex flex-row gap-2">
        <button
          onClick={decrementFunction}
          disabled={disableDecrement}
          className={disableDecrement ? disabledClassName : ''}
        >
          <div
            className={classNames(
              baseModifyButtonClassName,
              modifyButtonClassName[theme || Theme.PrimeFT],
            )}
          >
            <ChevronLeftIcon className={chevronClassName} />
          </div>
        </button>
        <button
          onClick={incrementFunction}
          disabled={disableIncrement}
          className={disableIncrement ? disabledClassName : ''}
        >
          <div
            className={classNames(
              baseModifyButtonClassName,
              modifyButtonClassName[theme || Theme.PrimeFT],
            )}
          >
            <ChevronRightIcon className={chevronClassName} />
          </div>
        </button>
      </div>
    </div>
  );
};

export default Incrementor;
