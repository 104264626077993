import { useAuth0 } from '@auth0/auth0-react';
import { useApi } from 'Context/ApiContext';
import { useQuery } from 'react-query';
import { User, UserAPIPayload } from 'Util/types';
import { mapUserResponseToUser } from './mapUserResponseToUser';

const useGetUser: () => {
  isLoading: boolean;
  isError: boolean;
  user?: User;
} = () => {
  const { user: auth0User, isAuthenticated, isLoading: isLoadingAuth0 } = useAuth0();
  const api = useApi();
  const params = new URLSearchParams([['auth0_id', auth0User?.sub || '']]);

  const fetchUsers = async () =>
    (await api.get<UserAPIPayload[]>('/users', { params })).data;

  const {
    data: userResponse,
    isLoading: isLoadingUsers,
    isError,
  } = useQuery(['user', isAuthenticated], fetchUsers, {
    enabled: isAuthenticated,
    select: (data: UserAPIPayload[]) => data[0],
  });

  const isLoading = isLoadingAuth0 || isLoadingUsers;
  return {
    isLoading,
    user: userResponse ? mapUserResponseToUser(userResponse) : undefined,
    isError,
  };
};

export default useGetUser;
