import { ReactComponent as UnknownIssue } from 'Asset/UnknownIssue.svg';
import NavBar from 'Component/NavBar/NavBar';
import { Link } from 'react-router-dom';
import { NavRoute } from 'Util/constants';

const NoOffer = () => {
  const containerClassName =
    'min-h-screen bg-primeft-100 grid grid-rows-[min-content,1fr]';
  const contentClassName = 'flex flex-col justify-center items-center';
  const titleClassName = 'text-3xl font-regular text-center mt-4';
  const subtitleClassName =
    'text-base font-regular text-primeft-500 text-center mt-2 max-w-xl px-4';

  const titleText = 'No loans associated with account';
  const subtitleText =
    "We're sorry to inform you that we cannot find any active loans associated with your account at this time. If you believe this to be an error, please contact our customer support team for further assistance.";
  const buttonText = 'Contact Us';

  return (
    <div className={containerClassName}>
      <NavBar />
      <div className={contentClassName}>
        <UnknownIssue />
        <h1 className={titleClassName}>{titleText}</h1>
        <span className={subtitleClassName}>{subtitleText}</span>
        <Link
          className="pt-4 text-sm text-button-complimentary"
          to={`/${NavRoute.Support}`}
        >
          {buttonText}
        </Link>
      </div>
    </div>
  );
};

export default NoOffer;
