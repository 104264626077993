import { useBorrower } from 'Context/BorrowerContext';
import { useGetLoanTermsBNPLByLoanId } from 'Hook/LoanTerms/useGetLoanTermsBNPLByLoanId';
import { useGetOfferById } from 'Hook/Offers/useGetOfferById';

type LoanCalculations = {
  /**
   * loan.balance
   */
  balance: number;
  /**
   * From Loan Terms
   *
   * total_loan_amount - fee_amount
   */
  loanAmount: number;
  loanFee: number;
  totalCost: number;
  totalOwed: number;
  /**
   * loanTerms.totalLoanAmount
   */
  totalLoanAmount: number;
  repaymentRate: number;
  /**
   * Number of payments left
   */
  totalPayments: number;
  installmentAmount: number;
  totalPaymentsWeeks: string;
  /**
   * totalOwed / installmentAmount
   */
  remainingPayments: number;
  factorRate: number;
  /**
   * Payment frequency (days)
   */
  frequency: number;
  /**
   * payments that were executed through the worker and just need bank confirmation
   */
  pendingPaymentsAmount: number;
  /**
   * number of pending payments
   */
  pendingPaymentsNumber: number;
};

export const useLoanCalculations = (): LoanCalculations => {
  const { loanDetails: loan } = useBorrower();
  const { offer } = useGetOfferById(loan?.offer_id ?? '');
  const { data: loanTerms } = useGetLoanTermsBNPLByLoanId({ loanId: loan?.id ?? '' });
  const repaymentRate = loan?.remittance_rate ?? 0;
  const loanAmount = loanTerms
    ? loanTerms.totalLoanAmount - loanTerms.feeAmount
    : loan?.capital_amount ?? 0;
  const factorRate = offer?.factor_rate ?? 0;
  const loanFee = loanTerms?.feeAmount ?? offer?.fee ?? 0;
  const totalCost = loanTerms?.feeAmount ?? factorRate * loanAmount + loanFee; // aka Financing Fee
  const installmentAmount =
    loanTerms?.remittancePayment ?? offer?.default_remittance_amount ?? 0;
  const pendingPayments = loan?.pending_payments ?? 0;
  const totalOwed = loanAmount + totalCost - pendingPayments / installmentAmount;
  const totalPayments = Math.round(totalOwed / installmentAmount);
  const frequency = loanTerms?.remittanceFrequency ?? 14;
  const totalPaymentsWeeks = `${Math.ceil(totalPayments * (frequency / 7))} Weeks`;
  const remainingPayments = Math.round(totalOwed / installmentAmount);

  return {
    balance: loan?.balance ?? 0,
    loanAmount,
    loanFee,
    totalCost,
    totalOwed,
    totalLoanAmount: loanTerms?.totalLoanAmount ?? 0,
    repaymentRate,
    totalPayments,
    installmentAmount,
    totalPaymentsWeeks,
    remainingPayments,
    factorRate,
    frequency,
    pendingPaymentsAmount: pendingPayments,
    pendingPaymentsNumber: Math.round(pendingPayments / installmentAmount),
  };
};
