import { AxiosError } from 'axios';
import { useApi } from 'Context/ApiContext';
import { useMutation } from 'react-query';
import { AgreementTypes } from 'Util/types';

export type PostSignedAgreementParams = {
  user_agent: string;
  agreement_type: AgreementTypes;
  loan_id: string;
  use_of_funds: string;
  provided_signature: string;
};

type PostSignedAgreementResponse = {
  status: string;
};

export const usePostSignedAgreement = ({
  onSuccess,
  onError,
}: {
  onSuccess: () => void;
  onError: () => void;
}) => {
  const api = useApi();

  const postSignedAgreement = async (payload: PostSignedAgreementParams) =>
    (await api.post('/agreements/loan/signed', payload)).data;
  return useMutation<
    PostSignedAgreementResponse,
    AxiosError,
    Omit<PostSignedAgreementParams, 'user_agent'>
  >(
    async payload => postSignedAgreement({ ...payload, user_agent: navigator.userAgent }),
    {
      onSuccess: () => {
        onSuccess();
      },
      onError: error => {
        console.error('Error signing agreement: ', error);
        onError();
      },
    },
  );
};
