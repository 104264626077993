import bankImage from 'Asset/illustration_bank.png';
import signImage from 'Asset/illustration_sign.png';
import verifyImage from 'Asset/illustration_verify.png';
import { ReactComponent as Leaf } from 'Asset/leaf.svg';
import CoffeeCup from 'Asset/loading_coffee.json';

import odekoBankImage from 'Asset/odeko_bank.svg';
import { ReactComponent as OdekoLeaf } from 'Asset/odeko_leaf.svg';
import odekoSignImage from 'Asset/odeko_sign.png';
import odekoVerifyImage from 'Asset/odeko_verify.png';
import { ReactComponent as Odeko } from 'Asset/OdekoDarkLogo.svg';
import OdekoPending from 'Asset/OdekoPending.png';
import OdekoRoadblock from 'Asset/OdekoRoadblock.png';
import { ReactComponent as OdekoWhite } from 'Asset/OdekoWhiteLogo.svg';
import PrimePending from 'Asset/PrimePending.png';
import PrimeRoadblock from 'Asset/PrimeRoadblock.png';
import { useThemeContext } from 'Context/ThemeContext';
import Lottie from 'lottie-react';
import { Theme } from 'Util/constants';
import { ReactComponent as PrimeFTLogo } from '../../assets/PrimeFTLogo.svg';
import ProcessingAnimation from '../../assets/processing_animation.json';
import OdekoProcessingAnimation from '../../assets/processing_odeko.json';

export enum ImageType {
  SidebarLogo = 'sidebarLogo',
  DashboardLogo = 'dashboardLogo',
  OfferLogo = 'offerLogo',
  EquipmentOfferLogo = 'equipmentOfferLogo',
  ConnectBankGraphic = 'connectBankGraphic',
  VerifyIdentityGraphic = 'verifyIdentityGraphic',
  SignGraphic = 'signGraphic',
  FinanceProvider = 'financeProvider',
  Spinner = 'spinner',
  Processing = 'processing',
  LeafGraphic = 'leafGraphic',
  RoadblockGraphic = 'errorGraphic',
  ReviewGraphic = 'reviewGraphic',
}

type ImageBuilderProps = {
  imageType: ImageType;
  style?: string;
};

export const ImageBuilder = (props: ImageBuilderProps) => {
  const { theme } = useThemeContext();

  switch (theme) {
    case Theme.Odeko:
      switch (props.imageType) {
        case ImageType.SidebarLogo:
          return <OdekoWhite className="h-8 w-14" />;
        case ImageType.DashboardLogo:
          return <Odeko className="h-8 w-14 fill-primary-500" />;
        case ImageType.OfferLogo:
          return <Odeko className="h-8 w-14 fill-primary-500" />;
        case ImageType.EquipmentOfferLogo:
          return <Odeko className="h-12 w-20 fill-primary-500" />;
        case ImageType.ConnectBankGraphic:
          return <img src={odekoBankImage} className="h-48 w-48" />;
        case ImageType.VerifyIdentityGraphic:
          return <img src={odekoVerifyImage} className="h-48 w-48" />;
        case ImageType.SignGraphic:
          return <img src={odekoSignImage} className="h-48 w-48" />;
        case ImageType.FinanceProvider:
          return <PrimeFTLogo className="h-6 w-20 fill-constant-500" />;
        case ImageType.Spinner:
          return <Lottie animationData={CoffeeCup} loop={true} className="h-36 w-36" />;
        case ImageType.Processing:
          return (
            <Lottie
              animationData={OdekoProcessingAnimation}
              style={{
                height: 300,
                paddingLeft: 20,
              }}
              loop={true}
            />
          );
        case ImageType.LeafGraphic:
          return <OdekoLeaf />;
        case ImageType.RoadblockGraphic:
          return <img src={OdekoRoadblock} className="h-48 w-48" />;
        case ImageType.ReviewGraphic:
          return <img src={OdekoPending} className="h-48 w-48" />;
        default:
          return <div>default</div>;
      }
    default:
      switch (props.imageType) {
        case ImageType.SidebarLogo:
          return <PrimeFTLogo className="h-6 w-20 fill-primeft-100" />;
        case ImageType.DashboardLogo:
          return <PrimeFTLogo className="h-6 w-20 fill-primary-500" />;
        case ImageType.OfferLogo:
          return <PrimeFTLogo className="h-6 w-20 fill-primary-500" />;
        case ImageType.EquipmentOfferLogo:
          return <PrimeFTLogo className="w-30 h-10 fill-primary-500" />;
        case ImageType.ConnectBankGraphic:
          return <img src={bankImage} className="h-48 w-48" />;
        case ImageType.VerifyIdentityGraphic:
          return <img src={verifyImage} className="h-48 w-48" />;
        case ImageType.SignGraphic:
          return <img src={signImage} className="h-48 w-48" />;
        case ImageType.FinanceProvider:
          return <PrimeFTLogo className="h-6 w-20 fill-constant-500" />;
        case ImageType.Spinner:
          return (
            <div
              className="spinner-border h-8 w-8 animate-spin rounded-full border-4 border-r-black"
              role="status"
            >
              <span className="invisible">Loading...</span>
            </div>
          );
        case ImageType.Processing:
          return (
            <Lottie
              animationData={ProcessingAnimation}
              style={{
                height: 300,
                paddingLeft: 20,
              }}
              loop={true}
            />
          );
        case ImageType.LeafGraphic:
          return <Leaf />;
        case ImageType.RoadblockGraphic:
          return <img src={PrimeRoadblock} className="h-48 w-48" />;
        case ImageType.ReviewGraphic:
          return <img src={PrimePending} className="h-48 w-48" />;
        default:
          return <div>default</div>;
      }
  }
};
