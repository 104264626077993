import { ButtonSubset } from 'Util/constants';
import { classNames } from 'Util/css/css';

type ButtonLargeOptions = {
  disabled?: boolean;
  subset: ButtonSubset;
  size: 'sm' | 'lg';
};

export const getButtonLargeClassNames: (options: ButtonLargeOptions) => {
  baseClassName: string;
  textClassNames: string;
  buttonClassNames: string;
  sizesClassName: string;
} = ({ disabled = false, subset = ButtonSubset.Fill, size = 'lg' }) => {
  const styles: {
    baseClassName: string;
    textClassNames: Record<ButtonSubset, string>;
    buttonClassNames: Record<ButtonSubset, string>;
    sizesClassName: Record<typeof size, string>;
  } = {
    baseClassName:
      'transition-all flex md:w-fit rounded-lg items-center font-medium justify-center',
    textClassNames: {
      [ButtonSubset.Fill]: 'text-button-primary',
      [ButtonSubset.Border]: 'text-button-complimentary hover:text-button-primary group',
      [ButtonSubset.Clear]: 'text-button-complimentary',
      [ButtonSubset.Destructive]: 'text-white',
      [ButtonSubset.DestructiveClear]: 'text-status-error-500',
    },
    buttonClassNames: {
      [ButtonSubset.Fill]: classNames(
        'bg-button-primary',
        disabled ? 'opacity-50 cursor-default' : 'hover:bg-button-hover',
      ),
      [ButtonSubset.Border]: classNames(
        'border-2 border-button-primary',
        disabled ? 'opacity-50 cursor-default' : 'hover:bg-button-primary',
      ),
      [ButtonSubset.Clear]: classNames(
        'border-2 border-transparent',
        disabled
          ? 'opacity-50 cursor-default'
          : 'hover:border-2 hover:border-button-primary',
      ),
      [ButtonSubset.Destructive]: classNames(
        'bg-status-error-500',
        disabled ? 'opacity-50 cursor-default' : 'hover:bg-status-error-500',
      ),
      [ButtonSubset.DestructiveClear]: classNames(
        'border-2 border-transparent',
        disabled ? 'opacity-50 cursor-default' : 'hover:border-status-error-500',
      ),
    },
    sizesClassName: {
      sm: 'text-xs h-10 px-4 py-2',
      lg: 'text-sm lg:px-10 py-3 px-2',
    },
  };

  return {
    baseClassName: styles.baseClassName,
    textClassNames: styles.textClassNames[subset],
    buttonClassNames: styles.buttonClassNames[subset],
    sizesClassName: styles.sizesClassName[size],
  };
};
