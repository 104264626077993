import { ImageBuilder, ImageType } from 'Component/ImageBuilder/ImageBuilder';
import ApplicationLayout from 'Page/Application/layouts/ApplicationLayout';
import { Link } from 'react-router-dom';
import { ApplicationStepsCompleted, NavRoute } from 'Util/constants';

export const UnderwritingReview = () => {
  const titleText = 'Final review is underway...';
  const subtitleText =
    'You final plan is now under review. Check back here for updates, or contact us to learn more on what’s going on.';
  const buttonText = 'Questions? Contact Us';
  const waitingTimeText = 'approximate wait time:';
  const waitingTime = '~24hrs';

  return (
    <ApplicationLayout stepsCompleted={ApplicationStepsCompleted.REVIEW}>
      <div className="flex flex-col items-center gap-2">
        <ImageBuilder imageType={ImageType.ReviewGraphic} />
        <h1 className="mt-2 text-center text-3xl">{titleText}</h1>
        <span className="max-w-xl px-4 text-center text-onPrimary">{subtitleText}</span>
        <div className="flex flex-col gap-1">
          <span className="text-center text-sm font-semibold">{waitingTimeText}</span>
          <span className="text-xm text-center">{waitingTime}</span>
        </div>
        <Link
          className="pt-4 text-sm text-button-complimentary"
          to={`/${NavRoute.Support}`}
        >
          {buttonText}
        </Link>
      </div>
    </ApplicationLayout>
  );
};
