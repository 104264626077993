import {
  CashIcon,
  ChartPieIcon,
  CreditCardIcon,
  PresentationChartBarIcon,
  ReceiptTaxIcon,
} from '@heroicons/react/outline';
import { ArrowDownIcon } from '@heroicons/react/solid';
import Link from 'Component/Link/Link';
import { useThemeContext } from 'Context/ThemeContext';
import { useGetDownloadSignedAgreement } from 'Hook/useGetDownloadSignedAgreement';
import { useLoanCalculations } from 'Hook/useLoanCalculations';
import { ActiveInfoLine } from 'Page/Dashboard/components/ActiveInfoLine';
import WidgetLayout from 'Page/Dashboard/components/WidgetLayout';
import { LoanStatus, Theme } from 'Util/constants';
import { classNames } from 'Util/css/css';
import { toCurrency } from 'Util/currency/currency';
import { Loan, LoanDetails, Offer } from 'Util/types';

type TermsSummaryProps = {
  loan: Loan | undefined;
  offer: Offer | undefined;
  loanDetails: LoanDetails | undefined;
};

const TermsSummary = ({ loan, loanDetails, offer }: TermsSummaryProps) => {
  const { handleDownloadSignedAgreement } = useGetDownloadSignedAgreement({
    loanId: loan?.id || '',
  });
  const { theme } = useThemeContext();
  const { loanAmount, loanFee, totalCost, repaymentRate, totalPayments } =
    useLoanCalculations();
  if (!loan || !offer)
    return (
      <WidgetLayout title="Summary of Terms">
        <h1 className="text-center">Loading... </h1>
      </WidgetLayout>
    );
  const isLoanRetired =
    loan.balance === 0 && loanDetails?.loan_status_name === LoanStatus.Retired;
  const linkColorClassName = {
    [Theme.PrimeFT]: 'text-primary-500 hover:text-primary-700',
    [Theme.Odeko]: 'text-primeft-700 hover:text-primeft-500',
  };
  return (
    <WidgetLayout
      title={'Summary of Terms'}
      topRight={
        <Link onClick={handleDownloadSignedAgreement}>
          <div
            className={classNames(
              'mx-auto flex flex-row items-center justify-center text-xs font-semibold text-primary-500',
              linkColorClassName[theme || Theme.PrimeFT],
            )}
          >
            Download Financing Agreement
            <ArrowDownIcon className="ml-2 h-3 w-3" />
          </div>
        </Link>
      }
    >
      <div className="flex flex-col gap-4 pb-2">
        {!isLoanRetired && (
          <>
            <ActiveInfoLine
              icon={<CreditCardIcon />}
              title={'Loan Amount'}
              amount={loanAmount ? toCurrency(loanAmount) : '$-----'}
            />
            <ActiveInfoLine
              icon={<ChartPieIcon />}
              title={'Loan Fee'}
              amount={toCurrency(loanFee)}
            />
            <ActiveInfoLine
              icon={<CashIcon />}
              title={'Total Cost'}
              amount={toCurrency(totalCost)}
            />
            <ActiveInfoLine
              icon={<ReceiptTaxIcon />}
              title={'Repayment Rate'}
              amount={`${repaymentRate * 100}%`}
            />
            <ActiveInfoLine
              icon={<PresentationChartBarIcon />}
              title={'Total Payments'}
              amount={totalPayments.toString()}
            />
          </>
        )}
      </div>
    </WidgetLayout>
  );
};

export default TermsSummary;
