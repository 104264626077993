import Table from 'Component/Table.tsx/Table';
import TBody from 'Component/TBody/TBody';
import TCell from 'Component/TCell.tsx/TCell';
import TRow from 'Component/TRow/TRow';
import {
  MoneyAPIClearanceStatus,
  PaymentTransaction,
} from 'Hook/Payments/useGetPaymentHistory';
import {
  PaymentStatusNames,
  useGetPaymentStatuses,
} from 'Hook/Payments/useGetPaymentStatuses';
import { PaymentModal } from 'Page/Dashboard/components/PaymentModal/PaymentModal';
import { TransactionName } from 'Page/Dashboard/components/TransactionHistory/TransactionName';
import { TransactionTypePill } from 'Page/Dashboard/components/TransactionHistory/TransactionTypePill';
import React, { forwardRef, useState } from 'react';
import { toCurrency } from 'Util/currency/currency';
import { dateToLocalDateString } from 'Util/date/date';

type TransactionHistoryTableProps = {
  transactions: PaymentTransaction[];
};

const TABLE_CONFIG = {
  columns: [
    {
      title: 'Name',
    },
    {
      title: 'Date',
    },
    {
      title: 'Status',
    },
    {
      title: 'Amount',
    },
  ],
};

type TableProps = TransactionHistoryTableProps & React.HTMLProps<HTMLTableElement>;

export const TransactionHistoryTable = forwardRef<HTMLTableElement, TableProps>(
  ({ transactions }, ref) => {
    const { data: paymentStatuses } = useGetPaymentStatuses();
    const [isOpen, setIsOpen] = useState(false);
    return (
      <div className="overflow-x-auto">
        <Table tableConfig={TABLE_CONFIG} ref={ref}>
          <TBody>
            {transactions?.map(loanPayment => {
              const date = loanPayment?.scheduled_date
                ? new Date(loanPayment.scheduled_date)
                : new Date();
              const isCancelled =
                paymentStatuses?.filter(
                  status => status.id === loanPayment?.payment_status_id,
                )[0].name === PaymentStatusNames.CANCELLED;
              const moneyApiStatus =
                loanPayment?.money_api_clearance_status || 'scheduled';
              const status = isCancelled
                ? MoneyAPIClearanceStatus.CANCELLED
                : moneyApiStatus;
              return (
                <TRow key={loanPayment.id}>
                  <TCell>
                    <TransactionName
                      paymentTransaction={loanPayment}
                      date={date.toISOString()}
                      openModal={() => setIsOpen(true)}
                    />
                  </TCell>
                  <TCell>{dateToLocalDateString(date, 'medium')}</TCell>
                  <TCell>
                    <TransactionTypePill type={status} />
                  </TCell>
                  <TCell>{toCurrency(-loanPayment.payment_amount)}</TCell>
                </TRow>
              );
            })}
          </TBody>
        </Table>
        <PaymentModal isOpen={isOpen} setIsOpen={setIsOpen} />
      </div>
    );
  },
);

TransactionHistoryTable.displayName = 'Transaction History';
