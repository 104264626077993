import Loader from 'Component/Loader/Loader';
import TablePagination from 'Component/TablePagination.tsx/TablePagination';
import { useGetPaymentHistory } from 'Hook/Payments/useGetPaymentHistory';
import { TransactionHistoryTable } from 'Page/Dashboard/components/TransactionHistory/TransactionHistoryTable';
import { WhatsNext } from 'Page/Dashboard/components/WhatsNext';
import React, { useRef, useState } from 'react';

const TransactionHistory = () => {
  const tableRef = useRef<HTMLTableElement>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const resultsPerPage = 10;
  const { data: paymentHistory } = useGetPaymentHistory({
    currentPage,
    resultsPerPage,
  });
  const transactions = paymentHistory?.items || [];
  const totalResults = paymentHistory?.count || 0;
  return (
    <div className="relative flex flex-col gap-2 py-4 md:py-8">
      {!paymentHistory ? (
        <div className="mt-60">
          <Loader />
        </div>
      ) : transactions.length === 0 ? (
        <WhatsNext />
      ) : (
        <>
          <div className="flex flex-row justify-between py-4">
            <p className="text-md font-semibold">Transaction History</p>
            <p className="text-sm text-primeft-500">Updated Yesterday</p>
          </div>
          <TransactionHistoryTable transactions={transactions} ref={tableRef} />
          <TablePagination
            pageCount={Math.ceil((totalResults || 1) / resultsPerPage)}
            resultsCount={totalResults || 0}
            currentPage={currentPage}
            resultsPerPage={resultsPerPage}
            onPageChange={pageNum => {
              setCurrentPage(pageNum);
            }}
          />
        </>
      )}
    </div>
  );
};

export default TransactionHistory;
