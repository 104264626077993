import Footer from 'Component/Footer/Footer';
import { OfferProvider, useOffer } from 'Context/OfferContext';
import { useThemeContext } from 'Context/ThemeContext';
import Loading from 'Page/Loading/Loading';
import { useNavigate } from 'react-router-dom';
import { NavRoute, Theme } from 'Util/constants';
import { classNames } from 'Util/css/css';
import { isMCAOffer } from 'Util/typeHelpers/typeHelpers';
import Main from './Main';
import Sidebar from './Sidebar';

const Customize = () => (
  <OfferProvider>
    <CustomizeOffer />
  </OfferProvider>
);

const CustomizeOffer = () => {
  const { offer, isLoading } = useOffer();
  const mcaOffer = offer && isMCAOffer(offer) ? offer : undefined;
  const navigate = useNavigate();
  const { theme } = useThemeContext();
  if (!!offer && !!offer.equipment_id) {
    navigate(`/${NavRoute.Offer}/${offer.id}`);
    return null;
  }

  if (!isLoading && !offer) {
    navigate('/unknown');
    return null;
  }

  if (!isLoading && offer && offer.status !== 'active') {
    navigate(`/${NavRoute.Offer}/${offer.id}`);
    return null;
  }

  const backgroundClassName = {
    [Theme.PrimeFT]: '',
    [Theme.Odeko]: 'bg-primeft-300',
  };

  return theme ? (
    <div
      className={classNames(
        'flex h-screen flex-col justify-between md:flex-row',
        backgroundClassName[theme],
      )}
    >
      {mcaOffer ? (
        <>
          <Main offer={mcaOffer} />
          <Sidebar />
          <div className="md:hidden">
            <Footer />
          </div>
        </>
      ) : (
        <Loading title="Loading your offer" />
      )}
    </div>
  ) : (
    <Loading title="Loading your offer" />
  );
};

export default Customize;
