/* This example requires Tailwind CSS v2.0+ */
import {
  CurrencyDollarIcon,
  LightningBoltIcon,
  BadgeCheckIcon,
} from '@heroicons/react/outline';
import FeatureTile from 'Page/Offer/Components/Feature/FeatureTile';

const features = [
  {
    name: 'Apply in Minutes',
    description:
      'No lengthy forms, credit checks, or collateral necessary. Get approved in a couple clicks.',
    icon: LightningBoltIcon,
  },
  {
    name: 'Fixed Pricing',
    description:
      'You owe the same total amount regardles of how long it takes to pay back. No interest or penalties.',
    icon: CurrencyDollarIcon,
  },
  {
    name: 'Repay from Sales',
    description:
      'Payments are collected as a percentage of your credit card sales, on a flexible timeline.',
    icon: BadgeCheckIcon,
  },
];

export default function OfferFeature() {
  return (
    <div className="relative ">
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="mt-2">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {features.map(feature => (
              <FeatureTile key={feature.name} feature={feature} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
