import { useApi } from 'Context/ApiContext';
import { useBorrower } from 'Context/BorrowerContext';
import { useQuery } from 'react-query';

export enum MoneyAPIClearanceStatus {
  PROCESSED = 'processed',
  PENDING = 'pending',
  CANCELLED = 'cancelled',
  FAILED = 'failed',
}

export type PaymentTransaction = {
  id: string;
  payment_type_id: number;
  payment_status_id: number;
  payment_amount: number;
  scheduled_date: string;
  money_api_clearance_status: MoneyAPIClearanceStatus;
};

type PaymentHistoryResponse = {
  items: PaymentTransaction[];
  count: number;
  page: number;
  per_page: number;
};

export const useGetPaymentHistory = ({
  currentPage,
  resultsPerPage,
}: {
  currentPage: number;
  resultsPerPage: number;
}) => {
  const api = useApi();
  const { loan } = useBorrower();
  const params = { page: currentPage, per_page: resultsPerPage };
  const fetchPaymentHistory = async () =>
    (await api.get<PaymentHistoryResponse>(`payments/history/${loan?.id}`, { params }))
      .data;
  return useQuery(['paymentHistory', currentPage], fetchPaymentHistory, {
    enabled: !!loan?.id,
    select: data => ({
      ...data,
      items: data.items.map(item => ({
        ...item,
        scheduled_date: item.scheduled_date ? item.scheduled_date + 'Z' : '',
      })),
    }),
  });
};
