import Modal from 'Component/Modal/Modal';
import Table from 'Component/Table.tsx/Table';
import TBody from 'Component/TBody/TBody';
import TCell from 'Component/TCell.tsx/TCell';
import TRow from 'Component/TRow/TRow';
import { useGetPartners } from 'Hook/Partners/useGetPartners';
import { Dispatch, SetStateAction } from 'react';

export const PartnerSelectionModal = ({
  isOpen,
  setIsOpen,
  setPartnerId,
}: {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setPartnerId: (id: string) => void;
}) => {
  const { data: partners } = useGetPartners();
  const TABLE_CONFIG = {
    columns: [
      {
        title: 'ID',
      },
      {
        title: 'Name',
      },
      {
        title: 'Theme',
      },
      {
        title: 'Created At',
      },
    ],
  };
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <h1 className="text-center text-2xl font-bold">Select Partner</h1>
      <h3 className="text-center text-lg text-onWhite-medium">
        Click a row to insert Partner ID
      </h3>
      <Table tableConfig={TABLE_CONFIG}>
        <TBody>
          {partners?.map(partner => {
            return (
              <TRow
                key={partner.id}
                onClick={() => {
                  setPartnerId(partner.id);
                  setIsOpen(() => false);
                }}
              >
                <TCell>{partner.id}</TCell>
                <TCell>{partner.name}</TCell>
                <TCell>{partner.theme}</TCell>
                <TCell>{partner.created_at}</TCell>
              </TRow>
            );
          })}
        </TBody>
      </Table>
    </Modal>
  );
};
