import { ImageBuilder, ImageType } from 'Component/ImageBuilder/ImageBuilder';

const Loading = ({ title }: { title: string }) => {
  return (
    <div className="flex h-screen flex-col items-center justify-center">
      <div className="flex flex-col items-center gap-10">
        <ImageBuilder imageType={ImageType.Spinner} />
        <div className="flex flex-col items-center">
          <h1 className="text-2xl">{title}</h1>
          <p className="text-sm text-primeft-500">This may take a few seconds</p>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Loading;
