const AgreementOverviewSection = () => (
  <div className="mb-5 flex flex-col items-center border-b-[1px] border-primeft-300 pb-5">
    <h4 className="text-sm text-onWhite-light">PRIME FINANCIAL TECHNOLOGIES, INC.</h4>
    <h2 className="mb-8 text-center text-2xl uppercase">
      Closed-end Commercial Loan And Security Agreement
    </h2>
    <p className="mt-8 whitespace-pre-wrap text-sm text-primeft-700">
      This Agreement governs the Loan provided to you by us, and the Offer Summary are
      part of this Agreement. Please read this Agreement carefully.{' '}
      <b>
        THIS AGREEMENT INCLUDES AN ARBITRATION CLAUSE IN SECTION 28. ARBITRATION IS A
        METHOD OF RESOLVING DISPUTES. IT WILL AFFECT YOUR RIGHTS, IF THERE IS A DISPUTE.
      </b>{' '}
      By signing this Agreement, you acknowledge receipt of a completed copy of this
      Agreement and your understanding of its terms, including the Arbitration Clause, and
      you also accept all the terms in this Agreement.
      <br />
      <br />
      <i>
        Note: Throughout this Agreement, we provide brief overviews of the contents of
        each section. These overviews are for convenience only, are not complete
        summaries, and are not to be deemed part of this Agreement. Please read your
        entire Agreement and keep it for your records.
      </i>
    </p>
  </div>
);

export default AgreementOverviewSection;
