import { classNames } from 'Util/css/css';

const Link = ({
  children,
  className = '',
  colorClassName = 'text-button-complimentary',
  ...props
}: {
  children: React.ReactNode;
  className?: string;
  colorClassName?: string;
  onClick: () => void;
}) => {
  return (
    <span
      className={classNames(colorClassName, 'cursor-pointer font-semibold', className)}
      {...props}
    >
      {children}
    </span>
  );
};

export default Link;
