import { useThemeContext } from 'Context/ThemeContext';
import { Theme } from 'Util/constants';
import { classNames } from 'Util/css/css';

const FeatureTile = ({
  feature,
}: {
  feature: {
    name: string;
    icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
    description: string;
  };
}) => {
  const { theme } = useThemeContext();
  const containerClassName = {
    [Theme.PrimeFT]: 'bg-primeft-100 bg-opacity-10',
    [Theme.Odeko]: 'bg-primeft-700',
  };
  const iconCLassName = {
    [Theme.PrimeFT]: 'text-primeft-100',
    [Theme.Odeko]: 'text-primeft-700',
  };
  return (
    <div className="pt-6">
      <div
        className={classNames(
          containerClassName[theme || Theme.PrimeFT],
          'flow-root min-h-full rounded-lg px-6 pb-8',
        )}
      >
        <div className="-mt-6">
          <div>
            <span className="inline-flex items-center justify-center rounded-md bg-primary-500 p-3 shadow-lg">
              <feature.icon
                className={classNames(
                  iconCLassName[theme || Theme.PrimeFT],
                  'h-6 w-6 text-primeft-100',
                )}
                aria-hidden="true"
              />
            </span>
          </div>
          <h3 className="mt-8 text-xl font-medium text-primeft-100 sm:text-2xl">
            {feature.name}
          </h3>
          <h5 className="mt-2 text-base text-primeft-300">{feature.description}</h5>
        </div>
      </div>
    </div>
  );
};

export default FeatureTile;
