import { configureStore } from '@reduxjs/toolkit';
import PaymentModalSlice from 'Store/PaymentModalSlice';
import AuthSlice from './AuthSlice';
import LoanSlice from './LoanSlice';
import OfferSlice from './OfferSlice';

const store = configureStore({
  reducer: {
    offer: OfferSlice,
    auth: AuthSlice,
    loan: LoanSlice,
    paymentModal: PaymentModalSlice,
  },
});
export type RootState = ReturnType<typeof store.getState>;

export default store;
