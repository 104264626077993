import { ArrowRightIcon } from '@heroicons/react/outline';
import ButtonLarge from 'Component/Button/ButtonLarge';
import { EquipmentDetailSummary } from 'Component/EquipmentDetailSummary/EquipmentDetailSummary';
import { InfoLineReview } from 'Component/InfoLine/InfoLineReview';
import { useBorrower } from 'Context/BorrowerContext';
import { useGetOfferById } from 'Hook/Offers/useGetOfferById';
import { useGetBankInfo } from 'Hook/useGetBankInfo';
import { useGetEquipmentById } from 'Hook/useGetEquipmentById';
import { useGetLoanClearingTime } from 'Hook/useGetLoanClearingTime';
import { useLoanCalculations } from 'Hook/useLoanCalculations';
import { toCurrency } from 'Util/currency/currency';

export function EquipmentPlanDetails({
  onContinueHandler,
}: {
  onContinueHandler: () => void;
}) {
  const {
    loanAmount,
    totalOwed,
    totalPaymentsWeeks,
    installmentAmount,
    loanFee,
    frequency,
  } = useLoanCalculations();

  const { loan } = useBorrower();
  const { offer } = useGetOfferById(loan?.offer_id || '');
  const { data: equipment } = useGetEquipmentById(offer?.equipment_id || '');

  const { estimatedTime } = useGetLoanClearingTime();

  const { institutionName, isFetching: isFetchingBankInfo } = useGetBankInfo();

  return (
    <div className="mt-4 flex flex-col gap-4">
      <EquipmentDetailSummary equipment={equipment} />
      <div className="mt-4 grid grid-cols-2 gap-x-4 gap-y-6">
        <InfoLineReview
          title="Loan Amount"
          subtitle="The amount you are borrowing"
          value={toCurrency(loanAmount)}
        />
        <InfoLineReview
          title="Finance Charge"
          subtitle="The only fee associated with the loan"
          value={toCurrency(loanFee)}
        />
        <InfoLineReview
          title="Total Amount Owed"
          subtitle="The sum total cost of the loan"
          value={toCurrency(totalOwed)}
        />
        <InfoLineReview
          title="Payment"
          subtitle="The amount you will pay each payment period"
          value={toCurrency(installmentAmount)}
        />
        <InfoLineReview
          title="Payment Frequency"
          subtitle="The length of time between payments"
          value={`${frequency} Days`}
        />
        <InfoLineReview
          title="Time to Repay"
          subtitle="Total amount of time to pay the loan off"
          value={totalPaymentsWeeks}
        />
        <InfoLineReview
          title="Disbursement arrival date"
          subtitle="Time to receive funds in your account"
          value={estimatedTime}
        />
        <InfoLineReview
          title="Repayment Bank"
          subtitle="Repayment Bank you are connected to"
          value={
            isFetchingBankInfo ? <span>Loading...</span> : <span>{institutionName}</span>
          }
        />
      </div>
      <div className="mt-4 grid place-items-stretch lg:flex lg:justify-end">
        <ButtonLarge type="button" onClick={onContinueHandler}>
          Confirm Details
          <ArrowRightIcon className="ml-2 h-6 w-4" />
        </ButtonLarge>
      </div>
    </div>
  );
}
