import { classNames } from 'Util/css/css';

export const MiniInput = ({
  mask,
  value,
  setValue,
  placeholder,
  className = '',
}: {
  mask: (value: string) => string;
  value: string;
  setValue: (value: string) => void;
  placeholder?: string;
  className?: string;
}) => {
  return (
    <input
      value={value}
      onChange={e => setValue(mask(e.target.value))}
      className={classNames('w-fit p-4 text-right', className)}
      placeholder={placeholder}
    />
  );
};
