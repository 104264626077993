import { BrowserRouter as Router, useRoutes } from 'react-router-dom';
import { routes } from './Routes';

const Routes = () => useRoutes(routes);

const RootNavigation = () => (
  <>
    <Router>
      <Routes />
    </Router>
  </>
);

export default RootNavigation;
