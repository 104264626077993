import {
  ChartPieIcon,
  CreditCardIcon,
  CurrencyDollarIcon,
  ReceiptTaxIcon,
} from '@heroicons/react/outline';

import { useLoanCalculations } from 'Hook/useLoanCalculations';
import { toCurrency } from 'Util/currency/currency';
import { PlanDetail } from './Sidebar';

export const MCASidebarPlanDetail = () => {
  const { loanAmount, loanFee, totalCost, repaymentRate } = useLoanCalculations();

  return (
    <div className="flex flex-col gap-4 rounded-lg bg-primeft-100 bg-opacity-10 p-4">
      <span className="text-primeft-100">Your Plan</span>
      <PlanDetail
        Icon={CreditCardIcon}
        description="Loan Amount"
        value={toCurrency(loanAmount)}
      />
      <PlanDetail
        Icon={ChartPieIcon}
        description="Loan Fee"
        value={toCurrency(loanFee)}
      />
      <PlanDetail
        Icon={CurrencyDollarIcon}
        description="Total Cost"
        value={toCurrency(totalCost)}
      />
      <PlanDetail
        Icon={ReceiptTaxIcon}
        description="Repayment Rate"
        value={`${repaymentRate * 100}%`}
      />
    </div>
  );
};
