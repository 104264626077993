import { useThemeContext } from 'Context/ThemeContext';
import ReactPaginate from 'react-paginate';
import { Theme } from 'Util/constants';
import {
  breakClassName,
  containerClassName,
  pageActiveClassName,
  pageClassName,
  disabledClassName,
} from './TablePagination.constants';
import TablePageControl from './TablePaginationControl/TablePageControl';
import TablePaginationInfo from './TablePaginationInfo/TablePaginationInfo';

type TablePaginationProps = {
  pageCount: number;
  resultsCount: number;
  currentPage: number;
  resultsPerPage: number;
  onPageChange: (page: number) => void;
};

const TablePagination = ({
  pageCount,
  resultsCount,
  currentPage,
  resultsPerPage,
  onPageChange,
}: TablePaginationProps) => {
  const { theme } = useThemeContext();
  return (
    <div className="mt-auto flex min-h-[76px] items-center justify-between py-3 pl-4">
      <div className="sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <TablePaginationInfo
          currentPage={currentPage}
          resultsPerPage={resultsPerPage}
          resultsCount={resultsCount}
        />
        <div>
          <nav
            className="relative z-0 inline-flex items-center -space-x-px"
            aria-label="Pagination"
          >
            <ReactPaginate
              previousLabel={<TablePageControl direction="previous" />}
              nextLabel={<TablePageControl direction="next" />}
              pageCount={pageCount >= currentPage ? pageCount : currentPage} //Avoid library warning for when pageCount isn't loaded yet
              pageRangeDisplayed={4}
              marginPagesDisplayed={2}
              containerClassName={containerClassName}
              pageClassName={pageClassName[theme || Theme.PrimeFT]}
              activeClassName={pageActiveClassName[theme || Theme.PrimeFT]}
              breakClassName={breakClassName[theme || Theme.PrimeFT]}
              disabledClassName={disabledClassName}
              forcePage={currentPage - 1}
              onPageChange={page => onPageChange(page.selected + 1)}
            />
          </nav>
        </div>
      </div>
    </div>
  );
};

export default TablePagination;
