import { createSlice } from '@reduxjs/toolkit';

export interface LoanState {
  showSuccessMessage: boolean;
}

const initialState: LoanState = {
  showSuccessMessage: true,
};

export const loanSlice = createSlice({
  name: 'loan',
  initialState,
  reducers: {
    setShowSuccessMessage: (
      state,
      action: { payload: LoanState['showSuccessMessage'] },
    ) => {
      return { ...state, showSuccessMessage: action.payload };
    },
  },
});

export const { setShowSuccessMessage } = loanSlice.actions;

export default loanSlice.reducer;
