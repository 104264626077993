import { BadgeCheckIcon } from '@heroicons/react/solid';
import ButtonLarge from 'Component/Button/ButtonLarge';
import { useGetBankInfo } from 'Hook/useGetBankInfo';
import { useSelector } from 'react-redux';
import { RootState } from 'Store/store';
import { toCurrency } from 'Util/currency/currency';
import { dateToLocalDateString } from 'Util/date/date';

export const SuccessPaymentModal = ({ handleClose }: { handleClose: () => void }) => {
  const { institutionName } = useGetBankInfo();
  const successTileClassName =
    'flex flex-col flex-1 items-center gap-4 bg-primeft-300 rounded-md p-4';
  const { amount, date } = useSelector((state: RootState) => state.paymentModal);
  return (
    <div className="flex flex-col justify-around gap-10">
      <div className="text-center">
        <BadgeCheckIcon className="mx-auto h-10 w-10 text-status-success-500" />
        <h3 className="text-xl">You&apos;ve scheduled a one time payment</h3>
        <span className="text-sm text-primeft-500">
          Please wait up to 2-3 business days for the payment to officially transfer to
          the loan
        </span>
      </div>
      <div className="flex flex-row justify-center gap-4">
        <div className={successTileClassName}>
          <span className="text-sm">Bank Account:</span>
          <span className="font-bold">{institutionName}</span>
        </div>
        <div className={successTileClassName}>
          <span className="text-sm">Payment Amount:</span>
          <span className="font-bold">{toCurrency(amount)}</span>
        </div>
        <div className={successTileClassName}>
          <span className="text-sm">Deliver By:</span>
          <span className="font-bold">
            {dateToLocalDateString(new Date(date), 'medium')}
          </span>
        </div>
      </div>
      <div className="flex flex-row items-center">
        <ButtonLarge className="ml-auto" onClick={handleClose}>
          Done
        </ButtonLarge>
      </div>
    </div>
  );
};
