import ButtonLarge from 'Component/Button/ButtonLarge';
import Input from 'Component/Input/Input';
import { maskCurrency, maskDate, maskPercentage } from 'Component/Input/utils/masks';
import Link from 'Component/Link/Link';
import NavBar from 'Component/NavBar/NavBar';
import Select from 'Component/Select/Select';
import { ToastContainer } from 'Component/Toast/ToastContainer';
import { useToastNotification } from 'Context/ToastNotificationContext';
import { defaultCreateOfferFormValues } from 'Fixture/offer';
import { PostOfferPayload, usePostOffer } from 'Hook/Offers/usePostOffer';
import { EquipmentSelectionModal } from 'Page/Dashboard/Admin/EquipmentSelectionModal';
import { OfferCreatedModal } from 'Page/Dashboard/Admin/OfferCreatedModal';
import { PartnerSelectionModal } from 'Page/Dashboard/Admin/PartnerSelectionModal';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ButtonSubset, NotificationType, OfferProductType } from 'Util/constants';
import { fromCurrency } from 'Util/currency/currency';
import { dateIsValid } from 'Util/date/date';
import { fromPercentage } from 'Util/values/percentage';

type OfferFormInput = {
  business_name: string;
  capital_amount_max: string;
  capital_amount_min: string;
  remittance_rate_min: string;
  remittance_rate_max: string;
  factor_rate: string;
  fee: string;
  default_remittance_amount: string;
  max_remittance_amount: string;
  expires_at: string;
  partner_id: string;
  equipment_id: string;
  product_type_id: OfferProductType;
};

const convertOfferFormToOfferPayload = (data: OfferFormInput): PostOfferPayload => {
  const expiresAt = new Date(data.expires_at);
  expiresAt.setHours(23, 59, 0, 0);

  return {
    business_name: data.business_name,
    capital_amount_min: fromCurrency(data.capital_amount_min),
    capital_amount_max: fromCurrency(data.capital_amount_max),
    estimated_daily_revenue: 0,
    expires_at: expiresAt.toISOString(),
    factor_rate: fromPercentage(data.factor_rate),
    fee: fromCurrency(data.fee),
    remittance_rate_min: fromPercentage(data.remittance_rate_min),
    remittance_rate_max: fromPercentage(data.remittance_rate_max),
    default_remittance_amount: fromCurrency(data.default_remittance_amount),
    max_remittance_amount: fromCurrency(data.max_remittance_amount),
    equipment_id: data.equipment_id,
    partner_id: data.partner_id,
    product_type_id: data.product_type_id,
  };
};

export const CreateOffer = () => {
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<OfferFormInput>({
    defaultValues: defaultCreateOfferFormValues,
  });
  const [partnerModalIsOpen, setPartnerModalIsOpen] = useState(false);
  const [equipmentModalIsOpen, setEquipmentModalIsOpen] = useState(false);
  const [createdOfferModalIsOpen, setCreatedOfferModalIsOpen] = useState(false);
  const [offerId, setOfferId] = useState<string>('');
  const { mutate: postOffer } = usePostOffer();
  const { addNotification } = useToastNotification();
  const onSubmit: SubmitHandler<OfferFormInput> = data => {
    postOffer(convertOfferFormToOfferPayload(data), {
      onSuccess: offer => {
        setOfferId(offer.id);
        setCreatedOfferModalIsOpen(true);
      },
      onError: () =>
        addNotification({
          title: 'Error',
          message: 'Failed to create offer',
          type: NotificationType.Error,
        }),
    });
  };

  return (
    <div className="relative min-h-screen">
      <ToastContainer />
      <NavBar />
      <div className="mx-auto my-20 flex max-w-2xl flex-col gap-10">
        <div className="flex flex-col gap-2">
          <h1 className="text-3xl">Create Offer</h1>
          <h3 className="text-onWhite-medium">
            Enter business, offer, partner, and equipment information
          </h3>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <h2 className="col-span-2 text-xl">1. Business Info</h2>
          <Input
            control={control}
            name="business_name"
            label="Business Name"
            rules={{ required: true }}
            error={errors.business_name}
          />
          {/*<Input*/}
          {/*  control={control}*/}
          {/*  name="business_email"*/}
          {/*  label="Business Email"*/}
          {/*  rules={{ required: true }}*/}
          {/*  error={errors.business_email}*/}
          {/*/>*/}
        </div>
        <div className="grid grid-cols-2 gap-4">
          <h2 className="col-span-2 text-xl">2. Loan Info</h2>
          <Input
            control={control}
            name="capital_amount_min"
            label="Capital Amount Min"
            modifier={maskCurrency}
            rules={{ required: true }}
            error={errors.capital_amount_min}
          />
          <Input
            control={control}
            name="capital_amount_max"
            label="Capital Amount Max"
            modifier={maskCurrency}
            rules={{
              required: true,
              validate: value =>
                fromCurrency(value) >= fromCurrency(getValues('capital_amount_min')) ||
                'Must be greater than min',
            }}
            error={errors.capital_amount_max}
          />
          <Input
            control={control}
            name="remittance_rate_min"
            label="Remittance Rate Min"
            modifier={maskPercentage}
            rules={{ required: true }}
            error={errors.remittance_rate_min}
          />
          <Input
            control={control}
            name="remittance_rate_max"
            label="Remittance Rate Max"
            modifier={maskPercentage}
            rules={{
              required: true,
              validate: value =>
                fromPercentage(value) >=
                  fromPercentage(getValues('remittance_rate_min')) ||
                'Must be greater than min',
            }}
            error={errors.remittance_rate_max}
          />
          <Input
            control={control}
            name="factor_rate"
            label="Factor Rate"
            modifier={maskPercentage}
            rules={{ required: true }}
            error={errors.factor_rate}
          />
          <Input
            control={control}
            name="fee"
            label="Fee"
            modifier={maskCurrency}
            rules={{ required: true }}
            error={errors.fee}
          />
          <Input
            control={control}
            name="default_remittance_amount"
            label="Default Remittance Amount"
            modifier={maskCurrency}
            rules={{ required: true }}
            error={errors.default_remittance_amount}
          />
          <Input
            control={control}
            name="max_remittance_amount"
            label="Max Remittance Amount"
            modifier={maskCurrency}
            rules={{ required: true }}
            error={errors.max_remittance_amount}
          />
          <Input
            control={control}
            name="expires_at"
            label="Expiration Date"
            modifier={maskDate}
            rules={{
              required: true,
              validate: (value: string) => !!dateIsValid(value),
            }}
            error={errors.expires_at}
          />
          <Select
            control={control}
            label="Product Type"
            name="product_type_id"
            options={[
              { value: OfferProductType.BNPL, label: 'BNPL' },
              { value: OfferProductType.MCA, label: 'MCA' },
            ]}
          />
        </div>
        <div className="grid grid-cols-4 gap-4">
          <h2 className="col-span-4 text-xl">3. Linked Info</h2>
          <Input
            control={control}
            name="partner_id"
            label="Partner ID"
            divClassName="col-span-3"
            rules={{ required: true }}
            error={errors.partner_id}
          />
          <Link
            onClick={() => {
              setPartnerModalIsOpen(true);
            }}
          >
            Choose Partner
          </Link>
          <Input
            control={control}
            name="equipment_id"
            label="Equipment ID"
            divClassName="col-span-3"
            rules={{ required: true }}
            error={errors.equipment_id}
          />
          <Link
            onClick={() => {
              setEquipmentModalIsOpen(true);
            }}
          >
            Choose Equipment
          </Link>
        </div>
        <div className="flex justify-end">
          <ButtonLarge subset={ButtonSubset.Clear}>Cancel</ButtonLarge>
          <ButtonLarge onClick={handleSubmit(onSubmit)}>Create Offer</ButtonLarge>
        </div>
      </div>
      <PartnerSelectionModal
        isOpen={partnerModalIsOpen}
        setIsOpen={setPartnerModalIsOpen}
        setPartnerId={partnerId => setValue('partner_id', partnerId)}
      />
      <EquipmentSelectionModal
        isOpen={equipmentModalIsOpen}
        setIsOpen={setEquipmentModalIsOpen}
        setEquipmentId={equipmentId => setValue('equipment_id', equipmentId)}
      />
      <OfferCreatedModal
        offerId={offerId}
        isOpen={createdOfferModalIsOpen}
        setIsOpen={setCreatedOfferModalIsOpen}
      />
    </div>
  );
};
