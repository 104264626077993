import { AxiosError } from 'axios';
import { useApi } from 'Context/ApiContext';
import { useMutation } from 'react-query';
import { APIErrorCodes } from 'Util/constants';

export type PostPublicTokenParams = {
  public_token: string;
  user_id: string;
};

export type PostPublicTokenResponse = {
  msg: string;
};

export type PostPublicTokenError = AxiosError<{
  msg: string;
  error_code: APIErrorCodes[];
}>;

export const usePostPublicToken = ({
  onSuccess,
  onError,
}: {
  onSuccess: () => void;
  onError: (error: PostPublicTokenError) => void;
}) => {
  const api = useApi();

  const postPublicToken = async (payload: PostPublicTokenParams) =>
    (await api.post('/public_token_exchange', payload)).data;

  return useMutation<
    PostPublicTokenResponse,
    PostPublicTokenError,
    PostPublicTokenParams
  >(async payload => postPublicToken(payload), {
    onSuccess: data => {
      console.log('public token exchanged', data);
      onSuccess();
    },
    onError: (error: PostPublicTokenError) => {
      console.log('Error exchanging public token: ', error);
      onError(error);
    },
  });
};
