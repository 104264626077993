import { useAuth0 } from '@auth0/auth0-react';
import { useApi } from 'Context/ApiContext';
import { useQuery } from 'react-query';
import { LoanDetails } from 'Util/types';

export const useGetLoanDetails = (loanId?: string) => {
  const api = useApi();
  const { isAuthenticated } = useAuth0();
  const fetchLoanDetails = async () =>
    (await api.get<LoanDetails>(`/loans/${loanId}`)).data;
  const { data: loanDetails, isLoading } = useQuery(
    ['loanDetails', loanId],
    fetchLoanDetails,
    {
      enabled: !!loanId && isAuthenticated,
      onError: error => console.error(error),
    },
  );
  return { loanDetails, isLoading };
};
