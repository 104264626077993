import background from 'Asset/signin_bg.png';
import Footer from 'Component/Footer/Footer';
import Login from 'Component/Login/Login';

const Home = () => {
  return (
    <div
      style={{ backgroundImage: `url(${background})` }}
      className="flex h-screen w-auto flex-col items-center justify-between bg-screen-secondary bg-cover"
    >
      <Login />
      <Footer />
    </div>
  );
};

export default Home;
