import { CalendarIcon } from '@heroicons/react/outline';
import { CheckCircleIcon } from '@heroicons/react/solid';
import { ReactComponent as PrimeFTLogo } from 'Asset/PrimeFTLogo.svg';
import ButtonLarge from 'Component/Button/ButtonLarge';
import Toast from 'Component/Toast/Toast';
import { useThemeContext } from 'Context/ThemeContext';
import { useLoanCalculations } from 'Hook/useLoanCalculations';
import { PaymentModal } from 'Page/Dashboard/components/PaymentModal/PaymentModal';
import WidgetLayout from 'Page/Dashboard/components/WidgetLayout';
import { useState } from 'react';
import { LoanStatus, NotificationType, OfferProductType, Theme } from 'Util/constants';
import { classNames } from 'Util/css/css';
import { toCurrency } from 'Util/currency/currency';
import { Loan, LoanDetails, Offer } from 'Util/types';
import ConnectedBanks from './ConnectedBanks/ConnectedBanks';

// We are temporarily disabling the payment button until
// we have thoroughly tested the payment flow
const SHOW_PAYMENT_BUTTON = false;

type BalanceProps = {
  loan: Loan | undefined;
  offer: Offer | undefined;
  loanDetails: LoanDetails | undefined;
};

const Balance = ({ loan, loanDetails, offer }: BalanceProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { theme, partner } = useThemeContext();
  const { remainingPayments, pendingPaymentsAmount, pendingPaymentsNumber, balance } =
    useLoanCalculations();

  if (!loan || !offer) {
    return (
      <WidgetLayout title="Summary of Terms">
        <h1 className="text-center">Loading... </h1>
      </WidgetLayout>
    );
  }

  const isLoanPending = loanDetails?.loan_status_name === LoanStatus.Pending;
  const isLoanActive = loanDetails?.loan_status_name === LoanStatus.Remittance;
  const isLoanRetired =
    loan.balance === 0 && loanDetails?.loan_status_name === LoanStatus.Retired;

  const capitalProviderClassName = 'text-primeft-500 flex flex-row items-center';
  const pendingPaymentsSectionClassNames =
    'w-full p-4 rounded-md flex flex-col md:flex-row items-center';
  const contrastBackgroundColor = {
    [Theme.Odeko]: 'bg-primeft-500',
    [Theme.PrimeFT]: 'bg-primeft-300',
  };

  const partnerName = partner?.name ?? 'your partner';
  const processingText =
    offer?.product_type_id === OfferProductType.BNPL
      ? `Funds are currently being sent to ${partnerName}. Your payments will not start until the equipment is delivered to you.`
      : 'Your funds will arrive in you bank account in the next 48 hours.';
  const projectedPaymentsText =
    offer?.product_type_id === OfferProductType.BNPL
      ? `${remainingPayments} payments left until repaid`
      : `${remainingPayments} payments projected until repaid`;

  const paymentsDisabled = balance - pendingPaymentsAmount <= 0;

  return (
    <WidgetLayout
      title={'Loan Balance'}
      topRight={
        <div className={capitalProviderClassName}>
          <span className="text-xs text-primeft-900">Provided by:</span>
          <PrimeFTLogo className="ml-2 h-6 w-10 fill-constant-500" />
        </div>
      }
    >
      <div className="my-4">
        <div className="flex flex-row items-end gap-20">
          <div>
            <div className="flex flex-row items-center justify-center">
              <div className="text-4xl font-semibold text-primeft-900">
                {toCurrency(balance)}
              </div>
              {isLoanRetired && (
                <CheckCircleIcon className="mx-auto ml-2 h-6 w-6 text-status-success-500" />
              )}
            </div>
            <p className="pt-2 text-label font-medium text-primeft-500">
              Remaining Balance
            </p>
          </div>
          {!!pendingPaymentsAmount && (
            <div>
              <div className="text-xl font-semibold text-primeft-900">
                {toCurrency(pendingPaymentsAmount)}
              </div>
              <p className="pt-2 text-label font-medium text-primeft-500">
                Pending Payments
              </p>
            </div>
          )}
        </div>
      </div>
      {paymentsDisabled && (
        <Toast
          type={NotificationType.Warning}
          title="Your loan is not repaid until all your pending payments are complete"
          message="Payments can take up to 4 business days to clear"
          Icon={() => (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="h-[24px] w-[24px] rounded-3xl bg-status-caution-500 text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M7.5 21L3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5"
              />
            </svg>
          )}
        />
      )}
      {isLoanActive && !paymentsDisabled && (
        <div
          className={classNames(
            pendingPaymentsSectionClassNames,
            contrastBackgroundColor[theme || Theme.PrimeFT],
            'justify-between',
          )}
        >
          <div className="flex flex-row items-center justify-start">
            <CalendarIcon className="mr-1 h-4 w-4 text-primary-500" />
            <p className="text-sm">
              {projectedPaymentsText}
              {pendingPaymentsNumber > 0 && (
                <i className="text-primeft-500"> ({pendingPaymentsNumber} pending)</i>
              )}
            </p>
          </div>

          {SHOW_PAYMENT_BUTTON && (
            <ButtonLarge
              className="w-full md:w-auto"
              size="sm"
              onClick={() => setIsOpen(true)}
            >
              Make a Payment
            </ButtonLarge>
          )}

          <PaymentModal isOpen={isOpen} setIsOpen={setIsOpen} />
        </div>
      )}
      {isLoanPending && (
        <Toast
          type={NotificationType.Warning}
          title="Processing Funds"
          message={processingText}
        />
      )}
      {isLoanRetired && (
        <Toast
          type={NotificationType.Success}
          title="Loan Paid Off"
          message="You have sucessfully paid off your plan!"
        />
      )}
      <ConnectedBanks />
    </WidgetLayout>
  );
};

export default Balance;
