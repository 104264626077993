import { AxiosError } from 'axios';
import { useApi } from 'Context/ApiContext';
import { useMutation } from 'react-query';

type PostUnderwritingPayload = {
  loan_id: string;
};

export type PostUnderwritingResponse = {
  status: 'approved' | 'denied';
  qualified_high_amount: number;
  qualified_low_amount: number;
  values_changed: boolean;
  values_need_update: boolean;
};

export const usePostUnderwriting = ({
  onSuccessHandler,
  onErrorHandler,
}: {
  onSuccessHandler: () => void;
  onErrorHandler: () => void;
}) => {
  const api = useApi();

  const postUnderwriting = async ({ loan_id }: PostUnderwritingPayload) =>
    (await api.post(`/underwriting/${loan_id}`)).data;

  return useMutation<PostUnderwritingResponse, AxiosError, PostUnderwritingPayload>(
    async payload => postUnderwriting(payload),
    {
      onSuccess: onSuccessHandler,
      onError: onErrorHandler,
    },
  );
};
