import ProtectedPage from 'Navigation/ProtectedPage';
import Account from 'Page/Account/Account';
import Apply from 'Page/Apply';
import CreateAccount from 'Page/CreateAccount/CreateAccount';
import { CreateOffer } from 'Page/Dashboard/Admin/CreateOffer';
import Dashboard from 'Page/Dashboard/Dashboard';
import { ESign } from 'Page/ESign/ESign';
import Home from 'Page/Home/Home';
import NoOffer from 'Page/NoOffer/NoOffer';
import PageNotFound from 'Page/NotFound/PageNotFound';
import CustomizeOffer from 'Page/Offer/Customize/CustomizeOffer';
import Offer from 'Page/Offer/Offer';
import { PrivacyPolicy } from 'Page/PrivacyPolicy/PrivacyPolicy';
import Processing from 'Page/Processing/Processing';
import Support from 'Page/Support/Support';
import { TermsOfService } from 'Page/TermsOfService/TermsOfService';
import { RouteObject } from 'react-router-dom';
import { NavRoute } from 'Util/constants';

export const routes: RouteObject[] = [
  {
    path: `/${NavRoute.Offer}/:id`,
    element: <Offer />,
  },
  {
    path: `/${NavRoute.CreateAccount}`,
    element: <CreateAccount />,
  },
  {
    path: `/${NavRoute.Account}`,
    element: <Account />,
  },
  { path: `/${NavRoute.Customize}/:id`, element: <CustomizeOffer /> },
  {
    path: `/${NavRoute.Apply}`,
    element: <ProtectedPage component={Apply} />,
  },
  {
    path: `/${NavRoute.Application}`,
    element: <ProtectedPage component={Apply} />,
  },
  { path: `/${NavRoute.Dashboard}`, element: <ProtectedPage component={Dashboard} /> },
  { path: `/${NavRoute.Processing}`, element: <Processing /> },
  { path: '/', element: <Home /> },
  { path: `/${NavRoute.Unknown}`, element: <PageNotFound /> },
  { path: `/${NavRoute.NoOffer}`, element: <ProtectedPage component={NoOffer} /> },
  { path: `/${NavRoute.Support}`, element: <Support /> },
  { path: `/${NavRoute.TermsOfService}`, element: <TermsOfService /> },
  { path: `/${NavRoute.PrivacyPolicy}`, element: <PrivacyPolicy /> },
  {
    path: `/${NavRoute.CreateOffer}`,
    element: <ProtectedPage component={CreateOffer} />,
  },
  {
    path: `/${NavRoute.ESign}`,
    element: <ESign />,
  },
];
