import React, { forwardRef } from 'react';
import { StyledWithChildren } from 'Util/types';

type TCellProps = StyledWithChildren & React.HTMLProps<HTMLTableCellElement>;

const TCell = forwardRef<HTMLTableCellElement, TCellProps>(
  ({ className, children, ...props }, ref) => (
    <td
      className={`font-regular whitespace-nowrap px-3 py-4 text-bodySmall text-primeft-500 ${
        className ?? ''
      }`}
      ref={ref}
      {...props}
    >
      {children}
    </td>
  ),
);

TCell.displayName = 'TCell';

export default TCell;
