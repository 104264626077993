import { AxiosError } from 'axios';
import { useApi } from 'Context/ApiContext';
import { useMutation } from 'react-query';
import { Loan } from 'Util/types';

export type UpdateLoanParams = {
  loanId: string;
  amount: number;
  rate: number;
};

export type UpdateLoanResponse = {
  id: string;
  capital_amount: number;
  remittance_rate: number;
};

export function useUpdateLoan({ onSuccess }: { onSuccess?: () => void } = {}) {
  const api = useApi();

  const makeLoanUpdate = async ({ loanId, amount, rate }: UpdateLoanParams) => {
    const { data: loan } = await api.patch<Loan>(`/loans/${loanId}`, {
      capital_amount: amount,
      remittance_rate: rate,
    });

    return {
      id: loan.id,
      capital_amount: loan.capital_amount,
      remittance_rate: loan.remittance_rate,
    };
  };

  return useMutation<UpdateLoanResponse, AxiosError, UpdateLoanParams>(
    async loanParams => makeLoanUpdate(loanParams),
    {
      onSuccess: ({ id, capital_amount, remittance_rate }) => {
        console.log(`Loan ${id} updated to ${capital_amount} and ${remittance_rate}`);
        if (onSuccess) onSuccess();
      },
      onError: error => {
        console.log('Error updating the loan: ', error);
      },
    },
  );
}
