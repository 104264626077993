export enum APIErrorCodes {
  LINK_TOKEN_FAILED = 'BRWAPI-8001',
  PLAID_ITEM_LOGIN_REQUIRED = 'BRWAPI-8002',
  MISSING_CHECKING_ACCOUNT = 'BRWAPI-8004',
  MISSING_ACH_INFO = 'BRWAPI-8005',
}

export enum ApplicationStepsCompleted {
  CREATE_ACCOUNT = 0,
  IDENTITY_VERIFICATION = 1,
  BANK_ACCOUNT_CONNECTION = 2,
  REVIEW = 3,
  SIGNING = 4,
}

export enum ButtonSubset {
  Fill = 'primary',
  Border = 'border',
  Clear = 'clear',
  Destructive = 'destructive',
  DestructiveClear = 'destructive-clear',
}

export enum ChecklistItemStatus {
  INCOMPLETE = 'incomplete',
  SUCCESS = 'success',
  FAILED = 'failed',
}

export enum LoanApplicationStatuses {
  CONNECT_BANK = 'connect_bank',
  KYC = 'kyc',
  KYB = 'kyb',
  SUBMIT_ONBOARDING = 'submit_verification',
  ONBOARDING_REVIEW = 'onboarding_under_review',
  ONBOARDING_DENIED = 'onboarding_denied',
  UNDERWRITING = 'underwriting',
  UNDERWRITING_REVIEW = 'underwriting_review',
  UNDERWRITING_DENIED = 'underwriting_denied',
  REVIEW = 'review',
  FINANCING_SIGNED = 'financing_signed',
  OFFER_SUMMARY_SIGNED = 'offer_summary_signed',
  ITEMIZATION_SIGNED = 'itemization_signed',
  COMPLETE = 'complete',
  NO_OFFER = 'no_offer',
}

export enum LoanStatus {
  Application = 'application',
  Pending = 'disbursement_pending',
  Remittance = 'remittance',
  Retired = 'retired',
}

export enum NavRoute {
  Account = 'account',
  Application = 'application',
  Apply = 'apply',
  CreateAccount = 'create-account',
  CreateOffer = 'create-offer',
  Customize = 'customize',
  Dashboard = 'dashboard',
  ESign = 'e-sign',
  NoOffer = 'no-offer',
  Offer = 'offer',
  PrivacyPolicy = 'privacy-policy',
  Processing = 'processing',
  Support = 'support',
  TermsOfService = 'terms-of-service',
  VerifyAcccount = 'verify-account',
  Unknown = '*',
}

export enum ModalSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export enum NotificationType {
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
}

export enum OfferStatus {
  Active = 'active',
  Claimed = 'claimed',
  Expired = 'expired',
}

export enum PaymentType {
  MANUAL = 'manual',
  AUTOMATED = 'automated',
  AUTOMATED_DEFAULT = 'automated_default',
  AUTOMATED_MAX = 'automated_max',
}

export enum PlaidVerificationMsg {
  VERIFIED = 'verified',
  UNVERIFIED = 'unverified',
}

export enum TransactionType {
  REMITTANCE = 'remittance',
  DISBURSEMENT = 'disbursement',
  DEBIT = 'debit',
  CREDIT = 'credit',
}

export enum Theme {
  PrimeFT = 'primeft',
  Odeko = 'odeko',
}

export enum OnboardingServiceErrorCode {
  APPLICATION_UNDER_REVIEW = 'OBS-001',
  APPLICATION_DENIED = 'OBS-002',
}

export const FooterNavigation: { name: string; slug: NavRoute }[] = [
  { name: 'Privacy Policy', slug: NavRoute.PrivacyPolicy },
  { name: 'Terms of Service', slug: NavRoute.TermsOfService },
  { name: 'E-Sign', slug: NavRoute.ESign },
];

export enum OfferProductType {
  BNPL = 'bnpl', // BNPL == Equipment
  MCA = 'mca',
}
