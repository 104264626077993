import { useAuth0 } from '@auth0/auth0-react';
import { ArrowLeftIcon } from '@heroicons/react/solid';
import ButtonLarge from 'Component/Button/ButtonLarge';
import Footer from 'Component/Footer/Footer';
import Link from 'Component/Link/Link';
import { useThemeContext } from 'Context/ThemeContext';
import { CustomOfferCalculator } from 'Page/Offer/Customize/Calculator';
import { saveOfferDetails } from 'Page/Offer/utils';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'Store/store';
import { NavRoute, Theme } from 'Util/constants';
import { MCAOffer as OfferType } from 'Util/types';

const MainHeaderNav = ({ loginOnClick }: { loginOnClick: () => void }) => {
  const { loginWithRedirect, user, isLoading } = useAuth0();
  const offer_id = useSelector((state: RootState) => state.offer.offerId);
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(`/${NavRoute.Offer}/${offer_id}`);
  };
  const { theme } = useThemeContext();

  const linkClassName = {
    [Theme.PrimeFT]: '',
    [Theme.Odeko]: 'text-primeft-700',
  };
  return (
    <div className="flex flex-row justify-between pt-16 pb-8">
      <Link
        onClick={() => handleBack()}
        className={linkClassName[theme || Theme.PrimeFT]}
      >
        <ArrowLeftIcon className="mr-2 -mt-1 inline-block h-4 w-4" /> Back
      </Link>
      {!isLoading && !user && (
        <Link
          onClick={() => {
            loginOnClick();
            loginWithRedirect();
          }}
        >
          Log In
        </Link>
      )}
    </div>
  );
};

const MainHeader = () => {
  return (
    <div>
      <h2 className="mb-2 font-sans text-xl text-primeft-900 lg:text-3xl">
        Customize Your Plan
      </h2>
      <p className="mb-6 text-sm text-primeft-500">
        Before applying for a loan, consider two factors: the amount of funding you need
        and your repayment rate. Having a clear idea of your funding needs and choosing an
        appropriate repayment rate will ensure you get the most accurate loan amount.
      </p>
    </div>
  );
};

const Main = ({ offer }: { offer: OfferType }) => {
  const { loginWithRedirect } = useAuth0();
  const amount = useSelector((state: RootState) => state.offer.amount);
  const rate = useSelector((state: RootState) => state.offer.rate);

  return (
    <main className="mx-auto flex max-w-2xl flex-col justify-between px-8 md:overflow-y-auto">
      <MainHeaderNav
        loginOnClick={() => {
          saveOfferDetails({
            rate,
            amount,
            offerId: offer.id,
          });
        }}
      />
      <div className="grid gap-2">
        <MainHeader />
        <CustomOfferCalculator offer={offer} />
        <div className="grid place-items-stretch lg:flex lg:justify-end">
          <ButtonLarge
            type="button"
            arrowRight={true}
            className="md:ml-auto"
            onClick={() => {
              saveOfferDetails({
                rate,
                amount,
                offerId: offer.id,
              });
              loginWithRedirect({ screen_hint: 'signup' });
            }}
          >
            Get Started
          </ButtonLarge>
        </div>
      </div>
      <div className="hidden md:block">
        <Footer />
      </div>
    </main>
  );
};

export default Main;
