import { OfficeBuildingIcon } from '@heroicons/react/solid';

export function BankTile(props: {
  fetchingBankInfo: boolean;
  institutionLogo: string;
  institutionName: string;
  accountNumber: string;
}) {
  return (
    <div className="flex flex-row py-2">
      <div className="flex flex-row justify-start gap-4">
        {props.fetchingBankInfo ? (
          <>
            <OfficeBuildingIcon className="my-auto h-8 w-8 text-primary-500" />
            <span className="my-auto text-sm text-primary-500">Loading...</span>
          </>
        ) : (
          <>
            {props.institutionLogo ? (
              <img
                className="my-auto h-12 w-12"
                src={props.institutionLogo}
                alt="Bank Logo"
              />
            ) : (
              <OfficeBuildingIcon className="my-auto h-8 w-8 text-primary-500" />
            )}
            <div className="my-auto flex flex-col">
              <p className="text-right text-sm font-semibold">{props.institutionName}</p>
              <p className="text-right text-[10px] text-primeft-500">
                Account ending in {props.accountNumber}
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
