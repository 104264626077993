import { AddressParams } from 'Component/Input/AddressInput';

export const convertPlaceAddressComponentsToAddress = (
  addressComponents: google.maps.GeocoderAddressComponent[],
): AddressParams => {
  const address: AddressParams = {
    address_line_1: '',
    address_line_2: '',
    address_city: '',
    address_state: '',
    address_postal_code: '',
    address_country_code: '',
  };

  for (const component of addressComponents) {
    const componentType = component.types[0];

    switch (componentType) {
      case 'street_number': {
        address.address_line_1 = component.long_name;
        break;
      }

      case 'route': {
        address.address_line_1 += ` ${component.short_name}`;
        break;
      }

      case 'subpremise': {
        address.address_line_2 = component.long_name;
        break;
      }

      case 'locality': {
        address.address_city = component.long_name;
        break;
      }

      case 'sublocality_level_1': {
        address.address_city = component.long_name;
        break;
      }

      case 'administrative_area_level_1': {
        address.address_state = component.short_name;
        break;
      }

      case 'postal_code': {
        address.address_postal_code = component.long_name;
        break;
      }

      case 'country': {
        address.address_country_code = component.short_name;
        break;
      }

      default: {
        break;
      }
    }
  }

  return address;
};
