import { useNavigate } from 'react-router-dom';
import { FooterNavigation } from 'Util/constants';

export const SidebarFooter = () => {
  const navigate = useNavigate();
  return (
    <footer>
      <div className="mx-auto max-w-7xl overflow-hidden py-4 px-4 sm:px-2 lg:px-4">
        <nav className="flex flex-row justify-between" aria-label="Footer">
          {FooterNavigation.map(item => (
            <div key={item.name}>
              <span
                onClick={() => navigate(`/${item.slug}`)}
                className="cursor-pointer text-xs text-primeft-100"
              >
                {item.name}
              </span>
            </div>
          ))}
        </nav>
      </div>
    </footer>
  );
};
