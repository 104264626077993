import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/solid';
import { Fragment, ReactNode } from 'react';
import { ModalSize } from 'Util/constants';
import { classNames } from 'Util/css/css';

type ModalProps = {
  children: ReactNode;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  size?: ModalSize;
  label?: string;
};

const Modal = ({
  children,
  isOpen,
  setIsOpen,
  size = ModalSize.Medium,
  label = '',
}: ModalProps) => {
  const renderBackground = () => (
    <Transition.Child
      as={Fragment}
      enter="ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
    </Transition.Child>
  );

  const renderTopElements = () => (
    <>
      <span className="text-xs text-primeft-900">{label}</span>
      <button
        type="button"
        className="ml-auto rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
        onClick={() => setIsOpen(false)}
      >
        <XIcon className="h-5 w-5" aria-hidden="true" />
      </button>
    </>
  );
  const widths = {
    [ModalSize.Small]: 'max-w-lg',
    [ModalSize.Medium]: 'max-w-2xl',
    [ModalSize.Large]: 'max-w-5xl',
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setIsOpen}>
        {renderBackground()}
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-2 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={classNames(
                  'transform overflow-hidden rounded-lg bg-white px-2 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8  sm:w-full sm:p-4',
                  widths[size],
                )}
              >
                <div className="flex items-center pb-2">{renderTopElements()}</div>
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
