import { ImageBuilder, ImageType } from 'Component/ImageBuilder/ImageBuilder';

const ReviewAnimation = ({ pageTitle }: { pageTitle: string }) => {
  const pageContainerClassName = 'flex flex-col justify-center items-center h-screen';

  return (
    <div className={pageContainerClassName}>
      <ImageBuilder imageType={ImageType.Processing} />
      <h1 className="text-3xl">{pageTitle}</h1>
      <p className="text-primeft-500">This may take a few seconds</p>
    </div>
  );
};

export default ReviewAnimation;
