export const introTitleText = "Let's verify who you are";
export const introDescriptionText =
  "Before we can finalize your loan, we're required to ensure we have the right info on file for you... we promise, this'll be quick!";
export const introButtonText = 'Start Verification';
export const outroTitleText = 'Successfully entered';
export const outroDescriptionText =
  'You have successfully entered the information. We will now look to see if the information you have provided matches our records.';
export const outroButtonText = 'Submit';
export const outroAuthorizationText =
  'By clicking “Submit,” I authorize and instruct Prime Financial Technologies, Inc., its financial institution partners, and its service providers to obtain business and consumer reports about me from one or more business or consumer reporting agencies or other third parties, and to use the information contained in any such report to [verify my identity; determine my eligibility for a loan or any other product or service; service, review, maintain, or collect on any loan provided or serviced by Prime Financial Technologies, Inc., its successors, or its financial institution partners; provide marketing, pre-qualification, or other information about products or services to me]; and for any other purpose permitted by applicable law.';
