import { Auth0Provider } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import { AdminBar } from 'Component/AdminBar/AdminBar';
import { ApiProvider } from 'Context/ApiContext';
import './App.css';
import { BorrowerContextProvider } from 'Context/BorrowerContext';
import { NavNotificationProvider } from 'Context/NavNotificationContext';
import { ThemeProvider } from 'Context/ThemeContext';
import { ToastNotificationProvider } from 'Context/ToastNotificationContext';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider as ReduxProvider } from 'react-redux/es/exports';
import store from 'Store/store';
import RootNavigation from './navigation/RootNavigation';

const staleTime = 60 * 1000 * 5; // Five minutes in milliseconds
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: staleTime,
    },
  },
});

function App() {
  return (
    <Sentry.ErrorBoundary>
      <ReduxProvider store={store}>
        <Auth0Provider
          domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
          clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ''}
          redirectUri={window.location.origin + '/application'}
        >
          <ApiProvider>
            <QueryClientProvider client={queryClient}>
              <ThemeProvider>
                <BorrowerContextProvider>
                  <NavNotificationProvider>
                    <ToastNotificationProvider>
                      <AdminBar>
                        <RootNavigation />
                      </AdminBar>
                    </ToastNotificationProvider>
                  </NavNotificationProvider>
                </BorrowerContextProvider>
              </ThemeProvider>
            </QueryClientProvider>
          </ApiProvider>
        </Auth0Provider>
      </ReduxProvider>
    </Sentry.ErrorBoundary>
  );
}

export default App;
