import { useAuth0 } from '@auth0/auth0-react';
import { ImageBuilder, ImageType } from 'Component/ImageBuilder/ImageBuilder';
import LinkComponent from 'Component/Link/Link';
import { useThemeContext } from 'Context/ThemeContext';
import { OfferStatus, Theme } from 'Util/constants';
import { classNames } from 'Util/css/css';
import { daysUntil } from 'Util/date/date';

export default function OfferHeader({
  name,
  headline,
  offerStatus = OfferStatus.Active,
  expiresAt,
}: {
  name: string;
  headline?: string;
  offerStatus: OfferStatus;
  expiresAt: string;
}) {
  const { loginWithRedirect } = useAuth0();
  const { theme } = useThemeContext();
  const subline = `Offer expires in ${daysUntil(new Date(expiresAt))} days`;
  const baseBusinessNameClassName = 'text-2xl md:text-4xl mb-4 font-semibold';
  const businessNameClassName = {
    [Theme.PrimeFT]: 'text-primeft-100 md:text-4xl md:mb-8',
    [Theme.Odeko]: 'text-primeft-700 md:text-xl md:mb-4',
  };
  const containerClassName = 'max-w-7xl mx-auto py-8 px-4 sm:py-8 sm:px-6 lg:px-8';
  const baseHeadlineClassName = 'mt-1 max-w-3xl m-auto text-2xl sm:text-4xl';
  const headlineClassName = {
    [Theme.PrimeFT]: 'text-primeft-100',
    [Theme.Odeko]: 'text-primeft-700',
  };
  const baseSublineClassName = 'max-w-xl mt-5 mx-auto text-primeft-300';
  const sublineClassName = {
    [Theme.PrimeFT]: 'text-primeft-300',
    [Theme.Odeko]: 'text-primeft-900',
  };
  return (
    <div className={containerClassName}>
      <div className="flex flex-col justify-between py-8 md:flex-row">
        <div className="flex-1"></div>
        <ImageBuilder imageType={ImageType.OfferLogo} />
        <div className="flex flex-1 flex-row items-center text-sm text-primeft-100">
          {(offerStatus == OfferStatus.Claimed || offerStatus == OfferStatus.Expired) && (
            <div className="mx-auto md:ml-auto">
              <span className="mr-2">Already a member?</span>
              <LinkComponent onClick={() => loginWithRedirect()}>
                <span className="cursor-pointer text-primeft-100 underline">Sign In</span>
              </LinkComponent>
            </div>
          )}
        </div>
      </div>
      <h1
        className={classNames(
          baseBusinessNameClassName,
          businessNameClassName[theme || Theme.PrimeFT],
        )}
      >
        {name}
      </h1>
      {offerStatus === OfferStatus.Active && (
        <>
          <h2
            className={classNames(
              baseHeadlineClassName,
              headlineClassName[theme || Theme.PrimeFT],
            )}
          >
            {headline}
          </h2>
          <h4
            className={classNames(
              baseSublineClassName,
              sublineClassName[theme || Theme.PrimeFT],
            )}
          >
            {subline}
          </h4>
        </>
      )}
    </div>
  );
}
