import { useThemeContext } from 'Context/ThemeContext';
import { Theme } from 'Util/constants';
import { classNames } from 'Util/css/css';
import { makeFirstResultNumber, makeLastResultNumber } from '../TablePagination.utils';

type TablePaginationInfoProps = {
  currentPage: number;
  resultsPerPage: number;
  resultsCount: number;
};

const TablePaginationInfo = ({
  currentPage,
  resultsPerPage,
  resultsCount,
}: TablePaginationInfoProps) => {
  const { theme } = useThemeContext();
  const baseClassName = 'text-body font-regular';
  const textClassName = {
    [Theme.PrimeFT]: 'text-primeft-300',
    [Theme.Odeko]: 'text-primeft-700',
  };
  return (
    <div>
      <p className={classNames(baseClassName, textClassName[theme || Theme.PrimeFT])}>
        {`Showing ${makeFirstResultNumber(
          currentPage,
          resultsPerPage,
        )} to ${makeLastResultNumber(
          currentPage,
          resultsPerPage,
          resultsCount,
        )} of ${resultsCount} results`}
      </p>
    </div>
  );
};

export default TablePaginationInfo;
