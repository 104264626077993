import { useBorrower } from 'Context/BorrowerContext';
import { useToastNotification } from 'Context/ToastNotificationContext';
import { useGetLoanAppStatus } from 'Hook/Loans/useGetLoanAppStatus';
import { usePostUnderwriting } from 'Hook/usePostUnderwriting';
import { UnderwritingProcessing } from 'Page/Underwriting/UnderwritingProcessing';
import { UnderwritingReview } from 'Page/Underwriting/UnderwritingReview';
import { useEffect, useState } from 'react';
import { NotificationType } from 'Util/constants';

export const Underwriting = ({
  onContinue,
  status,
}: {
  onContinue: () => void;
  status: 'UNDERWRITING' | 'REVIEW';
}) => {
  const { addNotification } = useToastNotification();
  const [showProcessing, setShowProcessing] = useState(true);
  const [showError, setShowError] = useState(false);
  const { mutate: postUnderwriting } = usePostUnderwriting({
    onSuccessHandler: () => {
      onContinue();
      setShowProcessing(false);
    },
    onErrorHandler: () => {
      addNotification({
        type: NotificationType.Error,
        title: 'Error',
        message: 'Error processing your information',
      });
      setShowError(true);
    },
  });
  const { loan } = useBorrower();
  const { isLoading } = useGetLoanAppStatus({ loanId: loan?.id });
  useEffect(() => {
    if (loan?.id) {
      setTimeout(() => {
        postUnderwriting({ loan_id: loan.id });
      }, 3000); // Delay 3 seconds to show animation
    }
  }, [postUnderwriting, loan]);

  if (showError) {
    return <UnderwritingReview />;
  }
  if (showProcessing || isLoading) {
    return <UnderwritingProcessing />;
  }
  if (status === 'REVIEW') {
    return <UnderwritingReview />;
  }
  return <UnderwritingProcessing />;
};
