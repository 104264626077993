import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';
import { FAQType } from 'Page/Offer/Components/FAQ/OfferFAQ';
import { classNames } from 'Util/css/css';

const FAQAccordian = ({ faq }: { faq: FAQType }) => {
  const questionClassName = 'text-sm md:text-base font-medium text-gray-900';
  const answerClassName = 'text-sm md:text-base text-gray-500 text-left';
  const chevronWrapperClassName = 'ml-6 h-7 flex items-center';
  return (
    <Disclosure as="div" key={faq.question} className="pt-6">
      {({ open }) => (
        <>
          <dt className="text-lg">
            <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-400">
              <span className={questionClassName}>{faq.question}</span>
              <span className={chevronWrapperClassName}>
                <ChevronDownIcon
                  className={classNames(
                    open ? '-rotate-180' : 'rotate-0',
                    'h-6 w-6 transform',
                  )}
                  aria-hidden="true"
                />
              </span>
            </Disclosure.Button>
          </dt>
          <Disclosure.Panel as="dd" className="mt-2 pr-12">
            <p className={answerClassName}>{faq.answer}</p>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default FAQAccordian;
