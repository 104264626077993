import ButtonLarge from 'Component/Button/ButtonLarge';
import Loader from 'Component/Loader/Loader';
import { useUpdateLoanPayment } from 'Hook/Payments/useUpdateLoanPayment';
import { PaymentModalLineItems } from 'Page/Dashboard/components/PaymentModal/PaymentModalLineItems';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showDeleteModal, showSuccessModal } from 'Store/PaymentModalSlice';
import { RootState } from 'Store/store';
import { ButtonSubset } from 'Util/constants';
import { toCurrency } from 'Util/currency/currency';

type EditPaymentModalProps = {
  paymentId: string;
  balance: number;
};

export const EditPaymentModal = ({ paymentId, balance }: EditPaymentModalProps) => {
  const [showError, setShowError] = useState<boolean>(false);
  const { mutate: updatePayment, isLoading } = useUpdateLoanPayment();
  const dispatch = useDispatch();
  const { amount, date } = useSelector((state: RootState) => state.paymentModal);
  return (
    <div className="my-4 flex flex-col gap-4">
      {isLoading && <Loader />}
      <div className="text-center">
        <h3 className="text-xl">Update your scheduled payment</h3>
        <span className="text-sm text-primeft-500">
          Your current pending balance is {toCurrency(balance)}
        </span>
      </div>
      <PaymentModalLineItems
        balance={balance}
        showError={showError}
        setShowError={setShowError}
      />
      <div className="flex flex-row items-center justify-between">
        <ButtonLarge
          subset={ButtonSubset.DestructiveClear}
          onClick={() => {
            dispatch(showDeleteModal());
          }}
        >
          Cancel Payment
        </ButtonLarge>
        <ButtonLarge
          arrowRight={true}
          onClick={() => {
            if (!paymentId) {
              console.error('Payment ID is not defined');
              setShowError(true);
              return;
            }
            updatePayment(
              {
                paymentId,
                loanPaymentParams: {
                  payment_amount: amount,
                  scheduled_date: date.replace('Z', ''),
                },
              },
              {
                onSuccess: () => {
                  dispatch(showSuccessModal());
                },
                onError: () => setShowError(true),
              },
            );
          }}
          disabled={amount > balance || amount <= 0}
        >
          Update
        </ButtonLarge>
      </div>
    </div>
  );
};
