import { OfferProductType, OfferStatus } from 'Util/constants';
import { BNPLOffer, MCAOffer, Offer } from 'Util/types';

const offer: Offer = {
  id: '1',
  business_name: 'Test Business',
  capital_amount_max: 100000,
  capital_amount_min: 10000,
  expires_at: '2021-06-30T23:59:59.000Z',
  created_at: '2021-06-01T23:59:59.000Z',
  updated_at: '2021-06-01T23:59:59.000Z',
  estimated_daily_revenue: 1300,
  factor_rate: null,
  fee: null,
  remittance_rate_max: 0.1,
  remittance_rate_min: 0.1,
  status: OfferStatus.Active,
  default_remittance_amount: 1000,
  max_remittance_amount: 1000,
  partner_id: 'ABC123',
  equipment_id: '1',
  product_type_id: OfferProductType.MCA,
};

export default offer;

export const mcaOffer: MCAOffer = {
  id: '1',
  business_name: 'Test Business',
  capital_amount_max: 100000,
  capital_amount_min: 10000,
  expires_at: '2021-06-30T23:59:59.000Z',
  created_at: '2021-06-01T23:59:59.000Z',
  updated_at: '2021-06-01T23:59:59.000Z',
  estimated_daily_revenue: 1300,
  factor_rate: 0.1,
  fee: 1000,
  remittance_rate_max: 0.1,
  remittance_rate_min: 0.1,
  status: OfferStatus.Active,
  default_remittance_amount: 1000,
  max_remittance_amount: 1000,
  partner_id: 'ABC123',
  equipment_id: '1',
  product_type_id: OfferProductType.MCA,
};

export const bnplOffer: BNPLOffer = {
  id: '1',
  business_name: 'Test Business',
  expires_at: '2021-06-30T23:59:59.000Z',
  created_at: '2021-06-01T23:59:59.000Z',
  updated_at: '2021-06-01T23:59:59.000Z',
  status: OfferStatus.Active,
  partner_id: 'ABC123',
  equipment_id: '1',
  product_type_id: OfferProductType.BNPL,
};

export const defaultCreateOfferFormValues = {
  capital_amount_max: '$1000.00',
  capital_amount_min: '$100.00',
  expires_at: '02/02/2024',
  factor_rate: '10.00%',
  fee: '$10.00',
  remittance_rate_max: '15.00%',
  remittance_rate_min: '5.00%',
  default_remittance_amount: '$100.00',
  max_remittance_amount: '$1000.00',
  product_type_id: OfferProductType.BNPL,
};
