export const saveOfferDetails = ({
  rate,
  amount,
  offerId,
}: {
  rate?: number | null;
  amount: number;
  offerId: string;
}) => {
  localStorage.setItem('offerRate', rate?.toString() ?? '');
  localStorage.setItem('offerAmount', amount.toString());
  localStorage.setItem('offerId', offerId);
};
