import { ArrowLeftIcon } from '@heroicons/react/solid';
import Footer from 'Component/Footer/Footer';
import Link from 'Component/Link/Link';
import NavBar from 'Component/NavBar/NavBar';
import { useNavigate } from 'react-router-dom';

export const TermsOfService = () => {
  const navigate = useNavigate();
  const pageClassName = 'bg-screen-primary flex flex-col';
  const containerClassName =
    'max-w-3xl mx-auto px-4 py-8 flex flex-col gap-4 bg-screen-primary';
  const copyClassName = 'text-sm text-onWhite-medium';
  const titleClassName = 'text-2xl mx-auto';
  const headingClassName = 'text-xl';
  const listItemClassName = 'ml-4 list-decimal';
  return (
    <div className={pageClassName}>
      <NavBar />
      <div className={containerClassName}>
        <Link onClick={() => navigate(-1)}>
          <>
            <ArrowLeftIcon className="mr-2 -mt-1 inline-block h-4 w-4" />
            <span>Back</span>
          </>
        </Link>
        <h1 className={titleClassName}>Terms of Service</h1>
        <h2 className={headingClassName}>PrimeFT User Portal</h2>

        <p>
          Effective as of <b>01 SEPT, 2022</b>
        </p>
        <p className={copyClassName}>
          These PrimeFT User Portal Terms of Service (the <b>“Terms of Service”</b>) are a
          legal agreement between Prime Financial Technologies, Inc. (<b>“PrimeFT”</b>,{' '}
          <b>“Prime”</b>, <b>“we”</b>
          or <b>“us”</b>) and you, the individual or entity who is accessing the PrimeFT
          User Portal (“you”). By using the PrimeFT User Portal, you agree to be bound by
          these terms and conditions. If you are accessing or using the PrimeFT User
          Portal on behalf of your company, you represent that you are authorized to
          accept this Agreement on behalf of your company, and all references to “you”
          reference your company.
        </p>

        <ol className={listItemClassName}>
          <li>General.</li>
        </ol>
        <p className={copyClassName}>
          The PrimeFT User Portal (“<b>PrimeFT User Portal</b>”) is an online interface
          maintained by PrimeFT that enables you to view and manage information relating
          to loans facilitated by PrimeFT in your capacity as either a borrower or lender.
          You are ultimately responsible for the transactions you may have with a borrower
          or lender, as applicable. In addition, these terms supplement any other
          agreements you may have in place with PrimeFT. In the event of a conflict
          between these Terms of Service and your written agreement with PrimeFT, the
          terms of the written agreement will control.
        </p>
        <p className={copyClassName}>
          PrimeFT reserves the right to modify or discontinue, temporarily or permanently,
          the PrimeFT User Portal (or any part thereof) with or without notice. You agree
          that PrimeFT will not be liable to you or to any third party for any
          modification, suspension or discontinuance of the PrimeFT User Portal.
        </p>

        <ol start={2} className={listItemClassName}>
          <li>Account Registration.</li>
        </ol>
        <p className={copyClassName}>
          You may be required to register with an account with PrimeFT in order to access
          and use certain features of the PrimeFT User Portal. If you choose to register
          for the PrimeFT User Portal, you agree to provide and maintain true, accurate,
          current, and complete information about yourself as prompted by the registration
          form. Registration data and certain other information about you are governed by
          our Privacy Policy.
        </p>
        <p className={copyClassName}>
          You are responsible for maintaining the confidentiality of your password and
          account details, if any, and are fully responsible for any and all activities
          that occur under your password or account. You agree to (a) immediately notify
          PrimeFT of any unauthorized use of your password or account or any other breach
          of security, and (b) ensure that you exit from your account at the end of each
          session when accessing the PrimeFT User Portal. PrimeFT will not be liable for
          any loss or damage arising from your failure to comply with this paragraph.
        </p>

        <ol start={3} className={listItemClassName}>
          <li>Restricted Activities.</li>
        </ol>
        <p className={copyClassName}>
          You must use the PrimeFT User Portal in a lawful manner, and must obey all laws,
          rules, and regulations (<b>“Laws”</b>) that apply to your use of the PrimeFT
          User Portal. You must not, and must not allow others to: (i) access or attempt
          to access non-public PrimeFT systems, programs, data, or services; (ii) work
          around any of the technical limitations of the PrimeFT User Portal or enable
          disabled or prohibited functionality; (iii) reverse engineer or attempt to
          reverse engineer the PrimeFT User Portal except as expressly permitted by Laws;
          (iv) perform or attempt to perform any actions that would interfere with the
          normal operation of the PrimeFT User Portal or affect our other users&apos; use
          of the PrimeFT User Portal; or (v) impose an unreasonable or disproportionately
          large load on the PrimeFT User Portal.
        </p>

        <ol start={4} className={listItemClassName}>
          <li>Third Party Services.</li>
        </ol>
        <p className={copyClassName}>
          The PrimeFT User Portal may provide links or other access to services, sites,
          technology, and resources that are provided or otherwise made available by third
          parties (the “<b>Third-Party Services</b>”). Third-Party Services may also be
          subject to additional terms and conditions, privacy policies, or other
          agreements with such third party, and you may be required to authenticate to or
          create separate accounts to use Third-Party Services on the websites or via the
          technology platforms of their respective providers. Some Third-Party Services
          will provide us with access to certain information that you have provided to
          third parties, including through such Third-Party Services, and we will use,
          store and disclose such information in accordance with our Privacy Policy.
        </p>
        <p className={copyClassName}>
          In order to use the payment functionality of PrimeFT User Portal, you must open
          a &quot;Dwolla Account&quot; provided by Dwolla, Inc. and you must accept the
          Dwolla Terms of Service and Privacy Policy. Any funds held in or transferred
          through your Dwolla Account are held or transferred by Dwolla&apos;s financial
          institution partners. You must be at least 18 years old to create a Dwolla
          Account. You authorize PrimeFT to collect and share with Dwolla your personal
          information including full name, date of birth, social security number, physical
          address, email address and financial information, and you are responsible for
          the accuracy and completeness of that data. You understand that you will access
          and manage your Dwolla Account through the PrimeFT User Portal, and Dwolla
          account notifications will be sent by PrimeFT, not Dwolla. PrimeFT will provide
          customer support for your Dwolla Account activity, and can be reached as set
          forth below.
        </p>

        <ol start={5} className={listItemClassName}>
          <li>Representations and Warranties.</li>
        </ol>
        <p className={copyClassName}>
          You represent and warrant to PrimeFT that (i) you have full power and authority
          to enter into these Terms of Service; (ii) your activities in connection with
          the PrimeFT User Portal do not and will not violate, infringe, or misappropriate
          any third party&apos;s copyright, trademark, right of privacy or publicity, or
          other personal or proprietary right, and (iii) you will not use the PrimeFT User
          Portal in a manner that is defamatory, obscene, unlawful, threatening, abusive,
          tortious, offensive or harassing.
        </p>

        <ol start={6} className={listItemClassName}>
          <li>Disclaimer.</li>
        </ol>
        <p className={copyClassName}>
          The PrimeFT User Portal may be temporarily unavailable for scheduled maintenance
          or for unscheduled emergency maintenance, either by PrimeFT or by third-party
          providers, or because of other causes beyond our reasonable control, but PrimeFT
          may use reasonable efforts to provide advance notice in writing or by email of
          any scheduled service disruption. HOWEVER, THE PRIMEFT USER PORTAL, AND ALL
          SERVER AND NETWORK COMPONENTS ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE”
          BASIS WITHOUT ANY WARRANTIES OF ANY KIND, AND PRIMEFT EXPRESSLY DISCLAIMS ANY
          AND ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO,
          THE IMPLIED WARRANTIES OF MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR
          PURPOSE, AND NON-INFRINGEMENT. YOU ACKNOWLEDGE THAT PRIMEFT DOES NOT WARRANT
          THAT THE PRIMEFT USER PORTAL WILL BE UNINTERRUPTED, TIMELY, SECURE, ERROR-FREE
          OR VIRUS-FREE, NOR DOES IT MAKE ANY WARRANTY AS TO THE RESULTS THAT MAY BE
          OBTAINED FROM USE OF THE PRIMEFT USER PORTAL, AND NO INFORMATION, ADVICE OR
          SERVICES OBTAINED BY YOU FROM PRIMEFT THROUGH THE PRIMEFT USER PORTAL SHALL
          CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THESE TERMS OF SERVICE.
        </p>

        <ol start={7} className={listItemClassName}>
          <li>Limitation of Liability.</li>
        </ol>
        <p className={copyClassName}>
          UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY (WHETHER IN CONTRACT, TORT, OR
          OTHERWISE) SHALL PRIMEFT BE LIABLE TO YOU OR ANY THIRD PARTY FOR (A) ANY
          INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, CONSEQUENTIAL OR PUNITIVE DAMAGES,
          INCLUDING LOST PROFITS, LOST SALES OR BUSINESS, LOST DATA, OR (B) FOR ANY DIRECT
          DAMAGES, COSTS, LOSSES OR LIABILITIES IN EXCESS OF THE FEES ACTUALLY PAID BY YOU
          IN THE SIX (6) MONTHS PRECEDING THE EVENT GIVING RISE TO YOUR CLAIM OR, IF NO
          FEES APPLY, ONE HUNDRED ($100) U.S. DOLLARS. THE PROVISIONS OF THIS SECTION
          ALLOCATE THE RISKS UNDER THESE TERMS OF SERVICE BETWEEN THE PARTIES, AND THE
          PARTIES HAVE RELIED ON THESE LIMITATIONS IN DETERMINING WHETHER TO ENTER INTO
          THESE TERMS OF SERVICE.
        </p>
        <p className={copyClassName}>
          Some states do not allow the exclusion of implied warranties or limitation of
          liability for incidental or consequential damages, which means that some of the
          above limitations may not apply to you. IN THESE STATES, PRIMEFT&apos;S
          LIABILITY WILL BE LIMITED TO THE GREATEST EXTENT PERMITTED BY LAW.
        </p>

        <ol start={8} className={listItemClassName}>
          <li>Indemnification.</li>
        </ol>
        <p className={copyClassName}>
          You shall defend, indemnify, and hold harmless PrimeFT from and against any
          claims, actions or demands, including without limitation reasonable legal and
          accounting fees, arising or resulting from your breach of these Terms of
          Service, or your other access, contribution to, use or misuse of the PrimeFT
          User Portal. PrimeFT shall provide notice to you of any such claim, suit or
          demand. PrimeFT reserves the right to assume the exclusive defense and control
          of any matter which is subject to indemnification under this section. In such
          case, you agree to cooperate with any reasonable requests assisting
          PrimeFT&apos;s defense of such matter.
        </p>

        <ol start={9} className={listItemClassName}>
          <li>Term and Termination.</li>
        </ol>
        <p className={copyClassName}>
          These Terms of Service apply to you starting on the date you first access or use
          the PrimeFT User Portal and continue until PrimeFT terminates these Terms or
          Service, or until you stop using the PrimeFT User Portal. If after stopping, you
          start to use the PrimeFT User Portal again, you are consenting to these Terms of
          Service. We may terminate these Terms of Service at any time for any reason by
          providing you notice. We may suspend your access to the PrimeFT User Portal, or
          immediately terminate these Terms of Service, if (i) you use the PrimeFT User
          Portal in a prohibited manner or otherwise do not comply with any of the
          provisions of these Terms of Service; or (ii) any Law requires us to do so, in
          which case you will no longer be able to access the PrimeFT User Portal. Upon
          termination of your use of the PrimeFT User Portal or termination of these Terms
          of Service for any reason, in addition to this section, the following sections
          will survive termination: Sections 5 through 13.
        </p>

        <ol start={10} className={listItemClassName}>
          <li>Governing Law.</li>
        </ol>
        <p className={copyClassName}>
          These Terms of Service will be governed by the laws of California, except for
          California&apos;s choice of law rules, and by applicable federal United States
          laws. Each party agrees to submit to personal and exclusive jurisdiction of the
          courts located in San Francisco, California, and each party waives all
          objections to that jurisdiction and venue.
        </p>

        <ol start={11} className={listItemClassName}>
          <li>Assignment.</li>
        </ol>
        <p className={copyClassName}>
          You may not assign these Terms of Service or any of your rights or obligations,
          by operation of law or otherwise, without our prior written approval and any
          attempted assignment will be void. We reserve the right to freely assign these
          Terms of Service and our rights and obligations to any third party without
          notice or consent. Subject to the foregoing, these Terms of Service will be
          binding upon and inure to the benefit of you and us, their successors and
          permitted assigns.
        </p>

        <ol start={12} className={listItemClassName}>
          <li>Miscellaneous.</li>
        </ol>
        <p className={copyClassName}>
          PrimeFT&apos;s failure to exercise or enforce any right or provision of the
          Terms of Service will not be considered a waiver of that right or provision. If
          any provision of these Terms of Service is found by any court of competent
          jurisdiction to be unenforceable or invalid, that provision will be limited or
          eliminated to the minimum extent necessary so that these Terms of Service
          otherwise remain in full force and effect and remain enforceable between the
          parties. Headings are for reference purposes only and in no way define, limit,
          construe or describe the scope or extent of the section. These Terms of Service
          constitutes the entire agreement between you and PrimeFT with respect to your
          use of the PrimeFT User Portal. These Terms of Service are not intended and will
          not be construed to create any rights or remedies in any parties other than you
          and PrimeFT, and no other person will have the ability to assert any rights as a
          third party beneficiary under these Terms of Service. These Terms of Service do
          not limit any rights that PrimeFT may have under trade secret, copyright, patent
          or other laws.
        </p>

        <ol start={13} className={listItemClassName}>
          <li>Contact Us.</li>
        </ol>
        <p className={copyClassName}>
          Please contact us at <b>support@primeft.com</b> to pose any questions regarding
          these Terms of Service or the PrimeFT User Portal.
        </p>
      </div>
      <Footer />
    </div>
  );
};
