import ButtonLarge from 'Component/Button/ButtonLarge';
import AddressInput, { AddressParams } from 'Component/Input/AddressInput';
import Input from 'Component/Input/Input';
import { maskDate, maskPhone, maskSSN } from 'Component/Input/utils/masks';
import Loader from 'Component/Loader/Loader';
import { useBorrower } from 'Context/BorrowerContext';
import { useToastNotification } from 'Context/ToastNotificationContext';
import { useVerifyUserInfo } from 'Hook/useVerifyUserInfo';
import ApplicationLayout from 'Page/Application/layouts/ApplicationLayout';
import Verification from 'Page/Application/Verification/Verification';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { setFirstName, setLastName } from 'Store/AuthSlice';
import { ApplicationStepsCompleted, NotificationType } from 'Util/constants';
import { dateOfBirthIsValid } from 'Util/date/date';
import { isEmailValid } from 'Util/email/email';

type PersonalFormInput = {
  first_name: string;
  last_name: string;
  dob: string;
  ssn: string;
  phone: string;
  address: string;
  email: string;
};

const Personal = ({ onContinue }: { onContinue: () => void }) => {
  const [showIntro, setShowIntro] = useState(true);
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors, isDirty },
  } = useForm<PersonalFormInput>();
  const { addNotification } = useToastNotification();
  const [processing, setProcessing] = useState<boolean>(false);
  const [addressComponents, setAddressComponents] = useState<AddressParams | null>(null);
  const verifyUser = useVerifyUserInfo({
    onSuccess: () => {
      onContinue();
    },
    onError: () => {
      addNotification({
        type: NotificationType.Error,
        title: 'Error verifying your information',
        message: 'Please wait a moment and try again',
      });
      setProcessing(false);
    },
  });
  const { loan } = useBorrower();
  const onSubmit: SubmitHandler<PersonalFormInput> = async (data: PersonalFormInput) => {
    if (addressComponents === null) {
      addNotification({
        type: NotificationType.Error,
        title: 'Address is required',
        message: 'Please select an address using the dropdown',
      });
      return;
    }
    verifyUser.mutate({
      loan_id: loan?.id || '',
      applicant_first_name: data.first_name,
      applicant_last_name: data.last_name,
      document_ssn: data.ssn,
      birth_date: data.dob,
      phone_number: data.phone,
      email_address: data.email,
      address_line_1: addressComponents.address_line_1,
      address_line_2: addressComponents.address_line_2,
      address_city: addressComponents.address_city,
      address_state: addressComponents.address_state,
      address_postal_code: addressComponents.address_postal_code,
      address_country_code: addressComponents.address_country_code,
    });
    setProcessing(true);
    dispatch(setFirstName(data.first_name));
    dispatch(setLastName(data.last_name));
  };

  if (showIntro) {
    return (
      <Verification
        onContinue={() => setShowIntro(false)}
        personalComplete={false}
        businessComplete={false}
      />
    );
  }

  return (
    <ApplicationLayout stepsCompleted={ApplicationStepsCompleted.IDENTITY_VERIFICATION}>
      <>
        {processing && <Loader />}
        <div className="relative flex flex-col gap-8">
          <div>
            <h1 className="text-2xl lg:text-3xl">Confirm Your Personal Information</h1>
            <p className="text-primeft-500">
              When verifying your personal identity, please be sure to provide information
              that matches your government-issued identification.
            </p>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className="mt-4 flex flex-col gap-4">
            <div className="grid grid-cols-6 gap-4">
              <Input
                name="first_name"
                label="First Name"
                divClassName="col-span-3"
                control={control}
                rules={{ required: true }}
                error={errors.first_name}
              />
              <Input
                name="last_name"
                label="Last Name"
                divClassName="col-span-3"
                control={control}
                rules={{ required: true }}
                error={errors.last_name}
              />
            </div>
            <div className="grid grid-cols-6 gap-4">
              <AddressInput
                name="address"
                label="Home Address"
                divClassName="col-span-6"
                control={control}
                setAddress={newValue => {
                  setValue('address', newValue.description);
                }}
                setAddressComponents={setAddressComponents}
                error={errors.address}
                rules={{ required: true, validate: () => addressComponents !== null }}
              />
            </div>
            <div className="grid grid-cols-6 gap-4">
              <Input
                name="email"
                label="Personal Email"
                divClassName="col-span-6"
                control={control}
                rules={{
                  required: true,
                  validate: (value: string) => isEmailValid(value),
                }}
                error={errors.email}
              />
            </div>
            <div className="grid grid-cols-6 gap-4">
              <Input
                name="phone"
                label="Phone Number"
                divClassName="col-span-6"
                control={control}
                rules={{
                  required: true,
                  validate: (value: string) => value.replace(/\D/g, '').length === 10,
                }}
                error={errors.phone}
                modifier={maskPhone}
              />
            </div>
            <div className="grid grid-cols-6 gap-4">
              <Input
                name="dob"
                label="Date of Birth"
                divClassName="col-span-2"
                control={control}
                rules={{
                  required: true,
                  validate: dateOfBirthIsValid,
                }}
                error={errors.dob}
                modifier={maskDate}
              />
              <Input
                name="ssn"
                type="password"
                label="Social Security Number"
                divClassName="col-span-4"
                control={control}
                rules={{
                  required: true,
                  validate: (value: string) => value.replace(/\D/g, '').length === 9,
                }}
                error={errors.ssn}
                modifier={maskSSN}
              />
            </div>

            <ButtonLarge
              type="submit"
              disabled={!isDirty || processing}
              className="md:ml-auto"
              arrowRight={true}
            >
              Continue
            </ButtonLarge>
          </form>
        </div>
      </>
    </ApplicationLayout>
  );
};

export default Personal;
