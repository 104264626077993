import { useUnauthenticatedApi } from 'Context/ApiContext';
import { useQuery } from 'react-query';
import { Partner } from 'Util/types';

export const useGetPartnerById = (
  partnerId: string,
  onSuccess?: (data: Partner) => void,
) => {
  const api = useUnauthenticatedApi();
  const fetchPartner = async () =>
    (await api.get<Partner>(`/partners/${partnerId}`)).data;
  return useQuery(['partner', partnerId], fetchPartner, {
    enabled: !!partnerId,
    onSuccess,
    onError: error => console.error(error),
  });
};
