import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/solid';
import { ChecklistItemStatus } from 'Util/constants';
import { classNames } from 'Util/css/css';
import { AgreementTypes } from 'Util/types';

export type ChecklistItemType = {
  id: number;
  icon: JSX.Element;
  title: string;
  description: string;
  status: ChecklistItemStatus;
};

export type AgreementChecklistItemType = {
  id: number;
  icon: JSX.Element;
  title: string;
  description: string;
  status: ChecklistItemStatus;
  agreementType: AgreementTypes;
};

export const ChecklistItem = ({ step }: { step: ChecklistItemType }) => {
  const baseIconClassName = 'h-5 w-5';
  const StatusIcon = {
    [ChecklistItemStatus.INCOMPLETE]: (
      <CheckCircleIcon
        className={classNames(baseIconClassName, 'text-status-incomplete-500')}
      />
    ),
    [ChecklistItemStatus.SUCCESS]: (
      <CheckCircleIcon
        className={classNames(baseIconClassName, 'text-status-success-500')}
      />
    ),
    [ChecklistItemStatus.FAILED]: (
      <XCircleIcon className={classNames(baseIconClassName, 'text-status-error-500')} />
    ),
  };
  return (
    <div className="flex items-center justify-between rounded-lg border border-primeft-300 py-4 px-3">
      <div className="flex flex-row items-center gap-3">
        {step.icon}
        <div className="flex flex-col">
          <div className="text-sm font-medium">{step.title}</div>
          <div className="text-xs text-primeft-500">{step.description}</div>
        </div>
      </div>
      <div>{StatusIcon[step.status]}</div>
    </div>
  );
};
