import { useAuth0 } from '@auth0/auth0-react';
import { useApi } from 'Context/ApiContext';
import { useBorrower } from 'Context/BorrowerContext';
import { useQuery } from 'react-query';

export type AccountInfoData = {
  accountInfo: AccountInfo | undefined;
  isFetching: boolean;
};

export type AccountInfo = {
  id: string;
  account_id: string;
  name: string;
  official_name: string;
  type: string;
  subtype: string;
  mask: string;
  plaid_account_id: string;
  money_api_bank_account_id: string;
  money_api_bank_account_rails: string;
  created_at: string;
  updated_at: string;
};

export const useGetBankAccount = (): AccountInfoData => {
  const api = useApi();
  const { isAuthenticated } = useAuth0();
  const { user } = useBorrower();
  const userId = user?.id || '';
  const fetchAccountInfo = async () =>
    (await api.get<AccountInfo[]>(`/bank_accounts/${userId}`)).data;
  const { data: accountInfo, isFetching } = useQuery(
    ['accountInfo', isAuthenticated, userId],
    fetchAccountInfo,
    {
      enabled: isAuthenticated && !!userId,
    },
  );

  return {
    isFetching,
    accountInfo: accountInfo?.[0],
  };
};
