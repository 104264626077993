import { useThemeContext } from 'Context/ThemeContext';
import { Theme } from 'Util/constants';
import { classNames } from 'Util/css/css';

export const InfoLineReview = ({
  title,
  value,
  subtitle,
}: {
  title: string;
  value: React.ReactNode;
  subtitle?: React.ReactNode;
}) => {
  const { theme } = useThemeContext();
  const valueColor = {
    [Theme.Odeko]: 'text-primeft-900',
    [Theme.PrimeFT]: 'text-primary-500',
  };
  return (
    <div className="flex-start flex flex-col justify-between">
      <p className="text-sm">{title}</p>
      <p className="text-xs text-primeft-500">{subtitle}</p>
      <p className={classNames('text-lg capitalize', valueColor[theme || Theme.PrimeFT])}>
        {value}
      </p>
    </div>
  );
};
