import { transparentBox } from 'Page/Application/Sidebar/constants';
import { toCurrency } from 'Util/currency/currency';
import { Equipment } from 'Util/types';

export const EquipmentSidebarPlanDetail = ({ equipment }: { equipment: Equipment }) => (
  <div className={transparentBox}>
    <div className="flex flex-row items-center justify-between">
      <div className="flex flex-col">
        <span className="text-xs font-light">Loan Amount</span>
      </div>
      <span className="text-xl">{toCurrency(equipment.price)}</span>
    </div>
  </div>
);
