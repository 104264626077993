const CTA = ({
  children,
  onClick,
}: {
  children: React.ReactNode;
  onClick: () => void;
}) => {
  return (
    <div className="mx-auto max-w-2xl cursor-pointer px-4 pb-2 text-center sm:px-6 lg:px-8">
      <span
        onClick={onClick}
        className="mt-8 inline-flex w-full min-w-[320px] items-center justify-center rounded-md border border-transparent bg-primary-500 px-5 py-3 text-base font-medium text-primeft-100 hover:bg-primary-300 sm:w-auto"
      >
        {children}
      </span>
    </div>
  );
};

export default CTA;
