const Welcome = ({
  businessName,
  userName,
}: {
  businessName: string;
  userName: string;
}) => {
  return (
    <div className="flex flex-col justify-between pb-4 md:flex-row md:items-center md:pb-0">
      <div className="py-6">
        <p className="text-sm text-primeft-500">Welcome, {userName}</p>
        <h1 className="text-3xl font-bold">{businessName}</h1>
      </div>
    </div>
  );
};

export default Welcome;
