import ChartColumn from './RepaymentChartColumn';

export const RepaymentChartLegend = ({ rate }: { rate: number }) => {
  const legendClasses =
    'flex flex-row flex-grow justify-center md:justify-start items-end md:text-left md:mx-auto max-w-lg pb-8';
  const legendKeyClasses = 'flex flex-row justify-between items-center h-6';
  const textClasses = 'mx-4 text-sm text-align-right text-complimentary-black';
  return (
    <div className={legendClasses}>
      <div className={legendKeyClasses}>
        <div className="h-3 w-3 rounded-full bg-primary-500" />
        <div className={textClasses}>Daily Credit Card Sales</div>
      </div>
      <div className={legendKeyClasses}>
        <div className="h-3 w-3 rounded-full bg-primary-700" />
        <div className={textClasses}>{`${(rate * 100).toFixed(0)}% Repayment`}</div>
      </div>
    </div>
  );
};

const RepaymentChart = ({ rate }: { rate: number }) => {
  const data = [
    { day: 'Monday', value: 941 },
    { day: 'Tuesday', value: 0 },
    { day: 'Wednesday', value: 766 },
    { day: 'Thursday', value: 1217 },
    { day: 'Friday', value: 1350 },
    { day: 'Saturday', value: 1483 },
    { day: 'Sunday', value: 408 },
  ];
  const maxValue = data.reduce((acc, curr) => {
    return Math.max(acc, curr.value);
  }, 0);

  return (
    <div className="mb-12">
      <div className="flex snap-x flex-row items-end justify-center gap-20 overflow-x-scroll md:overflow-x-auto">
        {data.map(({ day, value }, index) => (
          <ChartColumn
            maxValue={maxValue}
            value={value}
            rate={rate}
            label={day}
            key={index}
          />
        ))}
      </div>
    </div>
  );
};

export default RepaymentChart;
