import { AgreementProvider } from 'Context/AgreementContext';
import { FlowSequenceContextProvider } from 'Context/FlowSequenceContext';
import ApplicationRouting from 'Page/Application/ApplicationRouting';

const Apply = () => {
  return (
    <FlowSequenceContextProvider>
      <AgreementProvider>
        <ApplicationRouting />
      </AgreementProvider>
    </FlowSequenceContextProvider>
  );
};

export default Apply;
