import {
  ChartPieIcon,
  OfficeBuildingIcon,
  PaperAirplaneIcon,
} from '@heroicons/react/outline';
import { ChecklistItem, ChecklistItemType } from 'Component/ChecklistItem/ChecklistItem';
import { ChecklistItemStatus } from 'Util/constants';

const ConnectBankSteps = ({
  chooseBank,
  checkingAccount,
  achInfo,
}: {
  chooseBank: ChecklistItemStatus;
  checkingAccount: ChecklistItemStatus;
  achInfo: ChecklistItemStatus;
}) => {
  const steps: ChecklistItemType[] = [
    {
      id: 1,
      icon: <OfficeBuildingIcon className="h-4 w-4 text-primary-500" />,
      title: 'Setup an account for us to send funds.',
      description:
        'Select your bank and add the checking account or transactional account',
      status: chooseBank,
    },
    {
      id: 2,
      icon: <ChartPieIcon className="h-4 w-4 text-primary-500" />,
      title: 'Provide transaction data to verify your sales performance.',
      description:
        'Verify transactions of your sales, in order to gain insight into your business',
      status: checkingAccount,
    },
    {
      id: 3,
      icon: <PaperAirplaneIcon className="h-4 w-4 text-primary-500" />,
      title: 'Setup the account that will be used to automate repayment.',
      description:
        'Share account and routing numbers so we can send money back and forth',
      status: achInfo,
    },
  ];

  return (
    <div className="flex flex-col gap-3">
      {steps.map(step => (
        <ChecklistItem key={step.id} step={step} />
      ))}
    </div>
  );
};

export default ConnectBankSteps;
