import { useUnauthenticatedApi } from 'Context/ApiContext';
import { createContext, useContext, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { offerIdSet } from 'Store/OfferSlice';
import { Offer } from 'Util/types';
import { useThemeContext } from './ThemeContext';

type OfferContextValue = {
  offer: Offer | undefined;
  isLoading: boolean;
};

const OfferContext = createContext<OfferContextValue | null>(null);

export function OfferProvider(props: { children?: React.ReactNode }) {
  const api = useUnauthenticatedApi();
  const { onLoadedOffer } = useThemeContext();
  const { id: offerId } = useParams();
  const dispatch = useDispatch();

  const fetchOffer = async () => (await api.get<Offer>(`/offers/${offerId}`)).data;
  const { data: offer, isLoading } = useQuery(['offer', offerId], fetchOffer, {
    retry: false,
    onSuccess: offer => {
      dispatch(offerIdSet(offer.id));
      onLoadedOffer(offer);
    },
  });

  const contextValue: OfferContextValue = useMemo(
    () => ({ offer, isLoading }),
    [offer, isLoading],
  );

  return <OfferContext.Provider {...props} value={contextValue} />;
}

export function useOffer() {
  const context = useContext(OfferContext);

  if (!context) {
    throw new Error('Context not initialized');
  }
  return context;
}
