import { useApi } from 'Context/ApiContext';
import { useQuery } from 'react-query';
import { Business } from 'Util/types';

export const useGetBusinessByLoanId = (loanId?: string) => {
  const api = useApi();
  const fetchBusinessByLoanId = async () =>
    (await api.get<Business>(`/businesses/loan/${loanId}`)).data;
  const { data: business } = useQuery(['business', loanId], fetchBusinessByLoanId, {
    enabled: !!loanId,
    onError: error => console.error(error),
  });
  return { business };
};
