import { createSlice } from '@reduxjs/toolkit';

export interface AuthState {
  isAuthenticated: boolean;
  firstName: string;
  lastName: string;
  businessEmail: string;
  businessName: string;
}

const initialState: AuthState = {
  isAuthenticated: false,
  firstName: '',
  lastName: '',
  businessEmail: '',
  businessName: '',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsAuthenticated: (state, action: { payload: AuthState['isAuthenticated'] }) => {
      return { ...state, isAuthenticated: action.payload };
    },
    setFirstName: (state, action: { payload: AuthState['firstName'] }) => {
      return { ...state, firstName: action.payload };
    },
    setLastName: (state, action: { payload: AuthState['lastName'] }) => {
      return { ...state, lastName: action.payload };
    },
    setBusinessEmail: (state, action: { payload: AuthState['businessEmail'] }) => {
      return { ...state, businessEmail: action.payload };
    },
    setBusinessName: (state, action: { payload: AuthState['businessName'] }) => {
      return { ...state, businessName: action.payload };
    },
  },
});

export const {
  setIsAuthenticated,
  setFirstName,
  setLastName,
  setBusinessEmail,
  setBusinessName,
} = authSlice.actions;

export default authSlice.reducer;
