import { ShieldCheckIcon } from '@heroicons/react/outline';
import { useNavNotification } from 'Context/NavNotificationContext';
import { createContext, useCallback, useContext, useState } from 'react';
import { NotificationType } from 'Util/constants';

type PlaidContextValue = {
  plaidAccessError: boolean;
  handlePlaidAccessError: () => void;
  clearPlaidAccessError: () => void;
  shouldForceRefresh: boolean;
  setShouldForceRefresh: (value: boolean) => void;
  showPlaidDisconnectedModal: boolean;
  setShowPlaidDisconnectedModal: (value: boolean) => void;
};

const BankConnectionContext = createContext<PlaidContextValue | null>(null);

export function BankConnectionProvider(props: { children?: React.ReactNode }) {
  const [plaidAccessError, setPlaidAccessError] = useState<boolean>(false);
  const [shouldForceRefresh, setShouldForceRefresh] = useState<boolean>(false);
  const [showPlaidDisconnectedModal, setShowPlaidDisconnectedModal] =
    useState<boolean>(false);
  const { addNotification } = useNavNotification();
  const handlePlaidAccessError = useCallback(() => {
    setPlaidAccessError(true);
    setShowPlaidDisconnectedModal(true);
  }, []);
  const clearPlaidAccessError = useCallback(() => {
    setPlaidAccessError(false);
    setShouldForceRefresh(true);
    setShowPlaidDisconnectedModal(false);
    addNotification({
      type: NotificationType.Success,
      message: (
        <div className="flex flex-row">
          <ShieldCheckIcon className="mr-2 h-5 w-5 stroke-primeft-100" />
          <span className="text-primeft-100">Bank Successfully Connected</span>
        </div>
      ),
    });
  }, [addNotification]);

  return (
    <BankConnectionContext.Provider
      {...props}
      value={{
        plaidAccessError,
        handlePlaidAccessError,
        clearPlaidAccessError,
        shouldForceRefresh,
        setShouldForceRefresh,
        showPlaidDisconnectedModal,
        setShowPlaidDisconnectedModal,
      }}
    />
  );
}

export function useBankConnection() {
  const context = useContext(BankConnectionContext);

  if (!context) {
    throw new Error('Context not initialized');
  }
  return context;
}
