import { useAuth0 } from '@auth0/auth0-react';
import { ChevronDownIcon } from '@heroicons/react/outline';
import ActionMenu from 'Component/ActionMenu/ActionMenu';
import { ActionMenuItemProps } from 'Component/ActionMenu/ActionMenuItem';
import { ImageBuilder, ImageType } from 'Component/ImageBuilder/ImageBuilder';
import LinkComponent from 'Component/Link/Link';
import { NavNotificationAlert } from 'Component/NavBar/NavNotificationAlert';
import { useNavNotification, Notification } from 'Context/NavNotificationContext';
import { useThemeContext } from 'Context/ThemeContext';
import { useLogout } from 'Hook/useLogout';
import { Link, useNavigate } from 'react-router-dom';
import { NavRoute, Theme } from 'Util/constants';
import { classNames } from 'Util/css/css';

const NavBar = () => {
  const { isLoading, user, loginWithRedirect } = useAuth0();
  const { logout } = useLogout();
  const { theme } = useThemeContext();
  const navigate = useNavigate();
  const actionItemOptions: ActionMenuItemProps[] = [
    {
      label: 'My Account',
      onClick: () => {
        navigate(`/${NavRoute.Account}`);
      },
    },
    {
      label: 'Support',
      onClick: () => {
        navigate(`/${NavRoute.Support}`);
      },
    },
    {
      label: 'Logout',
      onClick: logout,
    },
  ];
  const { notifications, closeNotification } = useNavNotification();
  const backgroundColor = {
    [Theme.Odeko]: 'bg-primeft-300',
    [Theme.PrimeFT]: 'bg-primeft-100',
  };
  return (
    <div className="sticky top-0 z-10 flex flex-col items-center">
      <div
        className={classNames(
          'flex w-full max-w-screen-2xl flex-row items-center justify-between border-b border-primeft-300 px-4',
          backgroundColor[theme || Theme.PrimeFT],
        )}
      >
        <Link className="my-auto py-6" to="/dashboard">
          <ImageBuilder imageType={ImageType.DashboardLogo} />
        </Link>
        {user ? (
          <div className="flex">
            <Link
              to="/support"
              className="hidden h-full items-center border-x px-6 text-sm md:flex"
            >
              Support
            </Link>
            <ActionMenu actionItemOptions={actionItemOptions}>
              <div className="flex flex-row items-center truncate px-5 text-sm">
                {user.email}
                <ChevronDownIcon className="ml-2 h-4 w-4" />
              </div>
            </ActionMenu>
          </div>
        ) : isLoading ? (
          <div></div>
        ) : (
          <LinkComponent onClick={() => loginWithRedirect()}>
            <span>Log In</span>
          </LinkComponent>
        )}
      </div>
      {notifications.map((notification: Notification) => (
        <NavNotificationAlert
          key={notification.id}
          closeNotification={() => closeNotification(notification.id)}
          type={notification.type}
        >
          {notification.message}
        </NavNotificationAlert>
      ))}
    </div>
  );
};

export default NavBar;
