import ButtonLarge from 'Component/Button/ButtonLarge';
import { ChecklistItem } from 'Component/ChecklistItem/ChecklistItem';
import { ImageBuilder, ImageType } from 'Component/ImageBuilder/ImageBuilder';
import { useAgreementContext } from 'Context/AgreementContext';
import { useBorrower } from 'Context/BorrowerContext';
import { useToastNotification } from 'Context/ToastNotificationContext';
import { useConnectBusiness } from 'Hook/Businesses/useConnectBusiness';
import { useGetBusinessByLoanId } from 'Hook/Businesses/useGetBusinessByLoanId';
import { useExecuteLoan } from 'Hook/useExecuteLoan';
import ApplicationLayout from 'Page/Application/layouts/ApplicationLayout';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApplicationStepsCompleted, NotificationType } from 'Util/constants';
import SigningLoanDenied from './SigningLoanDenied';

export const ExecuteLoan = () => {
  const { agreements } = useAgreementContext();
  const navigate = useNavigate();
  const { addNotification } = useToastNotification();
  const [processing, setProcessing] = useState(false);
  const { user, loan } = useBorrower();
  const { business } = useGetBusinessByLoanId(loan?.id);

  const { mutateAsync: executeLoan } = useExecuteLoan();

  /*
   * The following mutation is called via the onClick handler
   * It contains multiple network reqests by doing the following:
   *
   * 1. Attempts to connect the business via the mutation below:
   *   a. [Happy path] If #1 is successful, we execute its onSuccess handler
   *      which contains the loan execution mutation.
   *   b. If unsuccessful, we handle the failure
   *
   * Note:
   * There is a bit of indirection with chaining multiple dependent mutations together.
   * It might be worthwhile to establish a pattern for these sorts of interactions
   * if they become more commonplace.
   */
  const { mutate: connectBusinessAndExecuteLoan, error } = useConnectBusiness({
    onSuccess: async () => {
      await executeLoan({
        user_id: user?.id ?? '',
        loan_id: loan?.id ?? '',
      });
      navigate('/processing');
    },
    onError: () => {
      addNotification({
        title: 'Error',
        message: 'There was an error executing your loan',
        type: NotificationType.Error,
      });
      setProcessing(false);
    },
  });

  return (
    <ApplicationLayout stepsCompleted={ApplicationStepsCompleted.SIGNING}>
      <>
        {error ? (
          <SigningLoanDenied />
        ) : (
          <div className="flex flex-col gap-4">
            <div>
              <ImageBuilder imageType={ImageType.SignGraphic} />
              <h1 className="text-2xl lg:text-3xl">Sign Agreements</h1>
              <p className="text-primeft-500">
                Before signing an agreement, read it over carefully to ensure you
                understand the terms of your financing. If you have any questions, you can
                reach out to our support team.
              </p>
            </div>
            {agreements.map(agreement => (
              <ChecklistItem key={agreement.id} step={agreement} />
            ))}
            <ButtonLarge
              arrowRight={true}
              onClick={() => {
                setProcessing(true);
                connectBusinessAndExecuteLoan({ businessId: business?.id ?? '' });
              }}
              disabled={processing}
            >
              Execute Loan
            </ButtonLarge>
          </div>
        )}
      </>
    </ApplicationLayout>
  );
};
