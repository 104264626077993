export const containerClassName =
  'flex flex-col min-h-screen items-center justify-between';
export const primeLogoClassName = 'fill-primary-500 h-8 w-24 mt-8';
export const pageDoesNotExistContainerClassName =
  'flex flex-col items-center sm:w-[460px] px-4';
export const notFoundIconClassName = 'fill-primary-500 h-36 w-48';
export const pageDoesNotExistTitleClassName =
  'mt-6 mb-2 text-3xl font-regular text-center';
export const pageDoesNotExistSubtitleClassName =
  'text-center text-primeft-500 text-base font-regular';
export const buttonClassName = 'mt-6';
export const buttonTextClassName = 'text-primary-500 text-sm font-semibold';

export const pageDoesNotExistTitle = 'Page does not exist';
export const pageDoesNotExistDescription =
  'The page you are looking for does not exist. Please check the URL and try again.';
export const buttonText = 'Return to home';
