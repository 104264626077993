import { useAuth0 } from '@auth0/auth0-react';
import { SidebarFooter } from 'Component/Footer/SidebarFooter';
import { ImageBuilder, ImageType } from 'Component/ImageBuilder/ImageBuilder';
import Link from 'Component/Link/Link';
import { useBorrower } from 'Context/BorrowerContext';
import { useGetOfferById } from 'Hook/Offers/useGetOfferById';
import { useOfferDetailsFromLocalStorage } from 'Hook/Offers/useOfferDetailsFromLocalStorage';
import { useGetEquipmentById } from 'Hook/useGetEquipmentById';
import { useLogout } from 'Hook/useLogout';
import { transparentBox } from 'Page/Application/Sidebar/constants';
import { SVGProps } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootState } from 'Store/store';
import { NavRoute } from 'Util/constants';
import { OfferProductType } from 'Util/constants';
import { classNames } from 'Util/css/css';
import { EquipmentSidebarPlanDetail } from './EquipmentSidebarPlanDetail';
import { MCASidebarPlanDetail } from './MCASidebarPlanDetail';

export const PlanDetail = ({
  Icon,
  description,
  value,
}: {
  Icon: (props: SVGProps<SVGSVGElement>) => JSX.Element;
  description: string;
  value: string;
}) => {
  return (
    <div className="flex flex-row justify-between">
      <div className="flex items-center gap-1">
        <Icon className="h-4 w-4 text-primeft-100" aria-hidden="true" />
        <span className="text-xs text-primeft-100">{description}</span>
      </div>
      <span className="text-xs text-primeft-100">{value}</span>
    </div>
  );
};

const Sidebar = ({
  children,
  width = 'fullWidth',
}: {
  children: JSX.Element;
  width?: 'fullWidth' | '90%';
}) => {
  const { pathname } = useLocation();
  const { offerId: offerIdFromGlobalStore } = useSelector(
    (state: RootState) => state.offer,
  );
  const { offerId: offerIdFromLocalStorage } = useOfferDetailsFromLocalStorage();
  const { user } = useAuth0();
  const { logout } = useLogout();
  const { business, loan } = useBorrower();

  /*
   * Warning: Loading offer details *hack*
   * This Sidebar component loads within the ApplicationLayout and assumes access to loan/offer data
   * However, we are not guaranteed to have that data within the context of the `/create-account` route
   * So, we load the offer data from either the redux store or localStorage if we are on this route,
   * Otherwise, we use the loan data we do have, to obtain the necessary information
   */
  const offerId =
    pathname === `/${NavRoute.CreateAccount}`
      ? offerIdFromGlobalStore || offerIdFromLocalStorage || ''
      : loan?.offer_id || '';

  const { offer } = useGetOfferById(offerId);
  const { data: equipment, isLoading: isLoadingEquipment } = useGetEquipmentById(
    offer?.equipment_id ?? '',
  );

  const baseClassNames =
    'flex h-full flex-col justify-around md:justify-between md:rounded-lg p-4 sm:p-6 bg-gradientDark text-white';

  const containerClasses = {
    fullWidth: classNames(baseClassNames, 'w-full'),
    '90%': classNames(baseClassNames, 'w-[calc(90%)]'),
  };

  return (
    <div className={containerClasses[width]}>
      <div className="flex flex-col gap-16">
        <div className="flex flex-col gap-2">
          <ImageBuilder imageType={ImageType.SidebarLogo} />
          <span className="mt-4 text-xs text-primeft-300">
            {offer?.product_type_id === OfferProductType.BNPL
              ? 'EQUIPMENT LOAN FOR'
              : 'MCA LOAN FOR'}
          </span>
          <span>{business?.name || offer?.business_name}</span>
        </div>
        {children}
      </div>
      <div className="flex flex-col gap-4">
        {offer?.product_type_id && equipment ? (
          <EquipmentSidebarPlanDetail equipment={equipment} />
        ) : (
          !isLoadingEquipment && <MCASidebarPlanDetail />
        )}
        <div className={transparentBox}>
          <div className="flex flex-row items-center justify-between">
            {user && (
              <div className="w-3/4 truncate text-sm" title={user.email}>
                {user.email}
              </div>
            )}
            <Link
              className="text-xs underline"
              colorClassName="text-primeft-100"
              onClick={logout}
            >
              Sign Out
            </Link>
          </div>
        </div>

        <SidebarFooter />
      </div>
    </div>
  );
};

export default Sidebar;
