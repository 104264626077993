import ButtonLarge from 'Component/Button/ButtonLarge';
import Modal from 'Component/Modal/Modal';
import { useLogout } from 'Hook/useLogout';
import { Dispatch, SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const OfferCreatedModal = ({
  offerId,
  isOpen,
  setIsOpen,
}: {
  offerId: string;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const navigate = useNavigate();
  const { logout } = useLogout();
  const [isCopied, setIsCopied] = useState(false);
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="flex flex-col gap-2">
        <h1 className="text-center text-2xl font-bold">Offer Created</h1>
        <ButtonLarge className="mx-auto" onClick={() => navigate(`/offer/${offerId}`)}>
          Go To Offer
        </ButtonLarge>
        <ButtonLarge
          className="mx-auto"
          onClick={() =>
            navigator.clipboard
              .writeText(`${window.location.origin}/offer/${offerId}`)
              .then(() => {
                setIsCopied(true);
                setTimeout(() => setIsCopied(false), 1000);
              })
          }
        >
          {isCopied ? 'Copied!' : 'Copy Link'}
        </ButtonLarge>
        <ButtonLarge onClick={logout} className="mx-auto">
          Logout
        </ButtonLarge>
      </div>
    </Modal>
  );
};
