import { useApi } from 'Context/ApiContext';
import { useQuery } from 'react-query';
import { Partner } from 'Util/types';

export const useGetPartners = () => {
  const api = useApi();
  const fetchPartner = async () => (await api.get<Partner[]>('/partners')).data;
  return useQuery(['partner'], fetchPartner, {
    onError: error => console.error(error),
  });
};
