import { AxiosError } from 'axios';
import { useApi } from 'Context/ApiContext';
import { useMutation, useQueryClient } from 'react-query';

export type VerifyUserParams = {
  loan_id: string;
  applicant_first_name: string;
  applicant_last_name: string;
  document_ssn: string;
  birth_date: string;
  phone_number: string;
  email_address: string;
  address_line_1: string;
  address_line_2?: string;
  address_city: string;
  address_state: string;
  address_postal_code: string;
  address_country_code: string;
};

export type VerifyUserResponse = {
  msg: string;
};

export const useVerifyUserInfo = ({
  onSuccess,
  onError,
}: {
  onSuccess: () => void;
  onError: () => void;
}) => {
  const api = useApi();
  const verifyUser = async (user: VerifyUserParams) =>
    (await api.post('/customer/verification', user)).data;
  const queryClient = useQueryClient();

  return useMutation<VerifyUserResponse, AxiosError, VerifyUserParams>(
    async user => verifyUser(user),
    {
      onSuccess: ({ msg }) => {
        console.log(msg);
        queryClient.invalidateQueries(['user', true]);
        onSuccess();
      },
      onError: error => {
        console.log('Error verifying the user: ', error);
        onError();
      },
    },
  );
};
