import { UnknownIssue } from 'Component/UnknownIssue/UnknownIssue';

const SigningLoanDenied = () => {
  const titleText = 'Cannot continue with the application';
  const subtitleText =
    'We are unable to move forward with the application for undisclosed reasons. We are sorry for the inconvenience.';

  return <UnknownIssue titleText={titleText} subtitleText={subtitleText} />;
};

export default SigningLoanDenied;
