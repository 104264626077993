import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline';
import { Label } from 'Component/ui/label';
import * as React from 'react';

import { cn } from 'Util/css/css';

type InputAttributes = React.InputHTMLAttributes<HTMLInputElement>;

export type InputProps = InputAttributes &
  Required<Pick<InputAttributes, 'placeholder'>> & {
    valueModifier?: (value: string) => string;
  };

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      type = 'text',
      onChange: reactHookFormOnChange,
      valueModifier,
      ...props
    },
    ref,
  ) => {
    const [hide, setHide] = React.useState(true);
    const inputType =
      type === 'password' && hide ? type : type !== 'password' ? type : 'text';

    // A valueModifier can be passed in to modify the event target's value in place
    // Here, we check to see if a valueModifier was passed in,
    // and if so, we modify the value before passing it to react hook form's onChange handler.
    const onChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
      if (valueModifier) {
        evt.target.value = valueModifier(evt.target.value);
        reactHookFormOnChange?.(evt);
      } else {
        reactHookFormOnChange?.(evt);
      }
    };

    const eyeIconClassName = 'cursor-pointer absolute top-[32%] right-[2%]';

    return (
      <div className="relative">
        <input
          type={inputType}
          className={cn(
            'border-b-1 peer h-[58px] w-full rounded-md border-primeft-300 pt-4 text-primeft-900 placeholder-transparent focus:pt-4 focus:outline-none focus:ring-primary-500 ',
            className,
          )}
          ref={ref}
          onChange={onChange}
          {...props}
        />
        {type === 'password' &&
          (hide ? (
            <EyeIcon
              width={25}
              className={eyeIconClassName}
              onClick={() => {
                setHide(false);
              }}
            />
          ) : (
            <EyeOffIcon
              width={25}
              className={eyeIconClassName}
              onClick={() => {
                setHide(true);
              }}
            />
          ))}

        <Label
          htmlFor={props.id}
          className="absolute left-3 top-2 cursor-text text-xs text-primeft-500 transition-all peer-placeholder-shown:top-4 peer-placeholder-shown:text-base peer-placeholder-shown:text-primeft-500 peer-focus:top-2 peer-focus:text-xs peer-focus:text-primeft-500"
        >
          {props.placeholder}
        </Label>
      </div>
    );
  },
);
Input.displayName = 'Input';

export { Input };
