import { useUnauthenticatedApi } from 'Context/ApiContext';
import { useThemeContext } from 'Context/ThemeContext';
import { useQuery } from 'react-query';
import { Offer } from 'Util/types';

export const useGetOfferById = (offerId: string) => {
  const api = useUnauthenticatedApi();
  const { onLoadedOffer } = useThemeContext();
  const fetchOffer = async () => (await api.get<Offer>(`/offers/${offerId}`)).data;
  const { data: offer, isLoading } = useQuery(['offer', offerId], fetchOffer, {
    enabled: !!offerId,
    onError: error => console.error(error),
    onSuccess: onLoadedOffer,
  });
  return { offer, isLoading };
};
