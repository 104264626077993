import { BankConnectionProvider } from 'Context/BankConnectionContext';
import { useBorrower } from 'Context/BorrowerContext';
import { useThemeContext } from 'Context/ThemeContext';
import { useGetLoanAppStatus } from 'Hook/Loans/useGetLoanAppStatus';
import { useGetOfferById } from 'Hook/Offers/useGetOfferById';
import { useGetEquipmentById } from 'Hook/useGetEquipmentById';
import { EquipmentPaymentPlan } from 'Page/Dashboard/components/EquipmentPaymentPlan';
import TermsSummary from 'Page/Dashboard/components/TermsSummary';
import TransactionHistory from 'Page/Dashboard/components/TransactionHistory/TransactionHistory';
import DashboardLayout from 'Page/Dashboard/DashboardLayout';
import Loading from 'Page/Loading/Loading';
import { useNavigate } from 'react-router-dom';
import { LoanApplicationStatuses, OfferProductType } from 'Util/constants';
import { Offer } from 'Util/types';
import Balance from './components/Balance';

const Dashboard = () => {
  const columnClassName = 'flex flex-col gap-6';
  const { theme } = useThemeContext();
  const { loan, loanDetails } = useBorrower();
  const navigate = useNavigate();
  const { data: loanAppStatus, isLoading: isLoadingLoanAppStatus } = useGetLoanAppStatus({
    loanId: loan?.id,
  });
  const { offer }: { offer: Offer | undefined } = useGetOfferById(loan?.offer_id ?? '');
  const { data: equipment } = useGetEquipmentById(offer?.equipment_id ?? '');
  const isEquipmentOffer = offer?.product_type_id === OfferProductType.BNPL && equipment;

  if (isLoadingLoanAppStatus) {
    return <Loading title="Checking for existing loan" />;
  }

  if (loanAppStatus?.app_status !== LoanApplicationStatuses.COMPLETE) {
    navigate('/application');
    return <Loading title="Redirecting to application" />;
  }

  return (
    <BankConnectionProvider>
      {theme ? (
        <DashboardLayout
          leftSide={
            <div className={columnClassName}>
              <Balance offer={offer} loan={loan} loanDetails={loanDetails} />
            </div>
          }
          rightSide={
            <div className={columnClassName}>
              {isEquipmentOffer ? (
                <EquipmentPaymentPlan loan={loan} />
              ) : (
                <TermsSummary offer={offer} loan={loan} loanDetails={loanDetails} />
              )}
            </div>
          }
          fullLayout={<TransactionHistory />}
        />
      ) : (
        <Loading title="Loading your dashboard" />
      )}
    </BankConnectionProvider>
  );
};

export default Dashboard;
