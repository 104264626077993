import { useAuth0 } from '@auth0/auth0-react';
import { AxiosError } from 'axios';
import { useApi } from 'Context/ApiContext';
import { useBorrower } from 'Context/BorrowerContext';
import { useQuery } from 'react-query';

export type LinkTokenResponse = {
  link_token: string;
  user_id: string;
};
export const useGetLinkToken = ({
  isOAuthRedirect,
  onError,
}: {
  isOAuthRedirect: boolean;
  onError: (error: string) => void;
}): { linkToken: string } => {
  const api = useApi();
  const { isAuthenticated } = useAuth0();
  const { user, loan } = useBorrower();
  const userId = user?.id || '';
  const loanId = loan?.id || '';
  const fetchToken = async () =>
    (await api.get<LinkTokenResponse>(`/link_token/${encodeURIComponent(userId)}`)).data;

  const { data: linkTokenResponse } = useQuery(
    ['linkToken', isAuthenticated, userId, loanId],
    fetchToken,
    {
      enabled: isAuthenticated && !!userId && !!loanId && !isOAuthRedirect,
      retry: 0,
      onSuccess: data => {
        if (data.link_token === '') {
          onError('Error connecting with banking platform, please try again');
        }
        localStorage.setItem('linkToken', linkToken);
      },
      onError: (error: AxiosError) => {
        onError(error.message);
      },
    },
  );

  let linkToken = linkTokenResponse ? linkTokenResponse.link_token : '';
  if (isOAuthRedirect) {
    linkToken = localStorage.getItem('linkToken') || '';
    if (linkToken === '') {
      onError('Authentication with 3rd Party OAuth Unsuccessful');
    }
  }

  return { linkToken };
};
