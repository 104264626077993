import { AxiosError } from 'axios';
import { useApi } from 'Context/ApiContext';
import { useMutation } from 'react-query';
import { Offer } from 'Util/types';

export type PostOfferPayload = Omit<Offer, 'id' | 'created_at' | 'updated_at' | 'status'>;

export const usePostOffer = () => {
  const api = useApi();

  const postOffer = async (payload: PostOfferPayload) =>
    (await api.post<Offer>('/offers', payload)).data;

  return useMutation<Offer, AxiosError, PostOfferPayload>(async payload =>
    postOffer(payload),
  );
};
