import { createContext, useContext, useState } from 'react';
import { NotificationType } from 'Util/constants';

export type ToastNotification = {
  id: string;
  type: NotificationType;
  title: string;
  message: string;
  autoClose?: boolean;
  showCloseIcon?: boolean;
  Icon?: React.FC<React.SVGProps<SVGSVGElement>>;
};
type ToastNotificationContextValue = {
  addNotification: (notification: Omit<ToastNotification, 'id'>) => void;
  closeNotification: (id: string) => void;
  notifications: ToastNotification[];
};

const ToastNotificationContext = createContext<ToastNotificationContextValue | null>(
  null,
);

export function ToastNotificationProvider(props: { children?: React.ReactNode }) {
  const [notifications, setNotifications] = useState<ToastNotification[]>([]);

  const addNotification = (notification: Omit<ToastNotification, 'id'>) => {
    setNotifications([
      ...notifications,
      {
        ...notification,
        id: Math.random().toString(36).substr(2, 9),
        autoClose: notification.autoClose ?? true,
      },
    ]);
  };

  const closeNotification = (id: string) => {
    setNotifications(prevState => prevState.filter(n => n.id !== id));
  };

  return (
    <ToastNotificationContext.Provider
      {...props}
      value={{ notifications, addNotification, closeNotification }}
    />
  );
}

export function useToastNotification() {
  const context = useContext(ToastNotificationContext);

  if (!context) {
    throw new Error('Context not initialized');
  }
  return context;
}
