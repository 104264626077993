import { ImageBuilder, ImageType } from 'Component/ImageBuilder/ImageBuilder';
import { useBorrower } from 'Context/BorrowerContext';
import { useGetAgreementsInfo } from 'Hook/useGetAgreementsInfo';
import BusinessInfoTable from 'Page/Application/Sign/components/BusinessInfoTable';
import React from 'react';
import PrimeSignatureInfoTable from './PrimeSignatureInfoTable';

type FinalSignatureProps = {
  signature: string;
  setSignature: (signature: string) => void;
  authorizedSigner: string;
  authorizedSignerTitle: string;
  primeAcceptanceDate: string;
  primeAuthorizedSigner: string;
  primeAuthorizedSignerTitle: string;
  primeSignature: string;
  validSignature: boolean;
};

const acknowledgeText =
  'BY SIGNING THIS AGREEMENT, YOU ACKNOWLEDGE THAT (1)YOU HAVE RECEIVED A COMPLETED COPY OF AND HAVE READ THIS AGREEMENT, INCLUDING THE PRICING DISCLOSURES, (2) YOU UNDERSTAND THIS AGREEMENT, (3) YOU AGREE TO THE TERMS OF THIS AGREEMENT, INCLUDING THE ARBITRATION CLAUSE, AND (4) YOU AGREE THAT YOU WILL KEEP A COPY OF THIS AGREEMENT FOR YOUR RECORDS.';

export const FinalSignature = ({
  signature,
  setSignature,
  authorizedSigner,
  authorizedSignerTitle,
  validSignature,
}: FinalSignatureProps) => {
  const contentBlockClassName =
    'shadow-lg w-full py-8 px-3 flex flex-col gap-5 justify-around items-center rounded-lg mt-6';
  const inputClassName =
    'placeholder:text-onWhite-light py-4 text-lg md:text-2xl placeholder:font-inter font-dancing text-center w-2/3 border-dotted border-b-2 border-primeft-300 text-onWhite-dark';
  const { loan, user } = useBorrower();
  const { data } = useGetAgreementsInfo(loan?.id);
  const { financingAgreement: financingAgreementInfo } = data ?? {};

  return (
    <div className="mb-5 flex flex-col items-center gap-8 rounded-md pb-5">
      <div className="text-center">
        <h4 className="text-xs text-onWhite-light">FINAL STEP</h4>
        <h2 className="text-2xl text-onWhite-dark">Sign & Execute</h2>
      </div>
      <p className="text-sm">{acknowledgeText}</p>
      <BusinessInfoTable
        authorizedSigner={authorizedSigner}
        authorizedSignerTitle={authorizedSignerTitle}
      />
      <PrimeSignatureInfoTable
        primeAcceptanceDate={financingAgreementInfo?.primeAcceptanceDate ?? ''}
        primeAuthorizedSigner={financingAgreementInfo?.primeAuthorizedSigner ?? ''}
        primeAuthorizedSignerTitle={
          financingAgreementInfo?.primeAuthorizedSignerTitle ?? ''
        }
        primeSignatureElement={
          <img
            className="h-16"
            src="https://primeft-dev-signed-agreements.s3.us-east-1.amazonaws.com/images/ck-sig.png"
            alt="prime signature"
          />
        }
      />
      <div className={contentBlockClassName}>
        <ImageBuilder imageType={ImageType.LeafGraphic} />

        <div className="flex flex-col items-center text-lg">
          <p className="text-center text-onWhite-dark">
            To agree & continue, please type in your signature
          </p>
        </div>
        <input
          className={inputClassName}
          placeholder="Enter Full Name"
          value={signature}
          onChange={e => {
            setSignature(e.target.value);
          }}
        />
        {signature && !validSignature ? (
          <p className="text-xs text-status-error-500">
            The name we have in our records did not match the name you entered.
          </p>
        ) : (
          <div className="text-xs text-onWhite-light">
            <span>
              Signature must match our recorded name: <b>{user?.fullName}</b>
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
