import { useAuth0 } from '@auth0/auth0-react';
import { ReactComponent as PrimeFTLogo } from 'Asset/PrimeFTLogo.svg';
import ButtonLarge from 'Component/Button/ButtonLarge';
import { Link } from 'react-router-dom';
import { NavRoute } from 'Util/constants';

const Login = () => {
  const { loginWithRedirect } = useAuth0();
  return (
    <div className="my-auto flex h-[295px] w-[366px] flex-col items-center justify-center gap-3 rounded-2xl bg-screen-primary md:drop-shadow-xl">
      <PrimeFTLogo className="mb-7" height="48" width="149" fill="#3364F1" />
      <ButtonLarge className="w-64 md:w-64" onClick={() => loginWithRedirect()}>
        Sign In
      </ButtonLarge>
      <Link className="text-sm text-button-complimentary" to={`/${NavRoute.Support}`}>
        Not a member?
      </Link>
    </div>
  );
};

export default Login;
