import { useGetPartnerById } from 'Hook/Partners/useGetPartnerById';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { Theme } from 'Util/constants';
import { Offer, Partner } from 'Util/types';

type ThemeContextValue = {
  partner?: Partner;
  theme?: Theme;
  toggleTheme: () => void;
  onLoadedOffer: (offer: Offer) => void;
};

export const ThemeContext = createContext<ThemeContextValue>({
  theme: Theme.PrimeFT,
  toggleTheme: () => void 0,
  onLoadedOffer: () => void 0,
});

export function ThemeProvider(props: { children?: React.ReactNode }) {
  const [theme, setTheme] = useState<ThemeContextValue['theme']>();
  const [offer, setOffer] = useState<Offer | undefined>();

  const { data: partner } = useGetPartnerById(offer?.partner_id || '', partner => {
    setTheme(theme || (partner.theme as Theme));
  });

  const onLoadedOffer = useCallback((offer: Offer) => {
    setOffer(offer);
    if (!offer.partner_id) {
      setTheme(Theme.PrimeFT);
    }
  }, []);

  const toggleTheme = useCallback(() => {
    setTheme(prevTheme => (prevTheme === Theme.Odeko ? Theme.PrimeFT : Theme.Odeko));
  }, []);

  const contextValue: ThemeContextValue = useMemo(
    () => ({ partner, theme, toggleTheme, onLoadedOffer }),
    [partner, theme, toggleTheme, onLoadedOffer],
  );

  return (
    <ThemeContext.Provider value={contextValue}>
      <div id="headlessui-portal-root" className={theme}>
        <div className="overflow-y-hidden bg-primeft-300">{props.children}</div>
      </div>
    </ThemeContext.Provider>
  );
}

export function useThemeContext() {
  const context = useContext(ThemeContext);

  if (!context) {
    throw new Error('Context not initialized');
  }
  return context;
}
