import { Menu } from '@headlessui/react';
import { classNames } from 'Util/css/css';

export type ActionMenuItemProps = {
  label: string;
  onClick: () => void;
};

const ActionMenuItem = ({ label, onClick }: ActionMenuItemProps) => {
  const baseClassName =
    'block px-4 py-2 text-sm text-primeft-100 w-full text-left cursor-pointer';
  const nonHoverClassName = 'bg-primeft-900';
  const hoverClassName = 'bg-primeft-700';

  return (
    <Menu.Item key={label}>
      {({ active }) => (
        <span
          onClick={onClick}
          className={classNames(
            baseClassName,
            active ? hoverClassName : nonHoverClassName,
          )}
        >
          {label}
        </span>
      )}
    </Menu.Item>
  );
};

export default ActionMenuItem;
