import Modal from 'Component/Modal/Modal';
import Table from 'Component/Table.tsx/Table';
import TBody from 'Component/TBody/TBody';
import TCell from 'Component/TCell.tsx/TCell';
import TRow from 'Component/TRow/TRow';
import { useGetEquipment } from 'Hook/useGetEquipment';
import { Dispatch, SetStateAction } from 'react';
import { ModalSize } from 'Util/constants';

export const EquipmentSelectionModal = ({
  isOpen,
  setIsOpen,
  setEquipmentId,
}: {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setEquipmentId: (id: string) => void;
}) => {
  const { data: equipment } = useGetEquipment();
  const TABLE_CONFIG = {
    columns: [
      {
        title: 'ID',
      },
      {
        title: 'Name',
      },
      {
        title: 'Price',
      },
      {
        title: 'Serial Number',
      },
      {
        title: 'Model',
      },
      {
        title: 'Created At',
      },
    ],
  };
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} size={ModalSize.Large}>
      <h1 className="text-center text-2xl font-bold">Select Equipment</h1>
      <h3 className="text-center text-lg text-onWhite-medium">
        Click a row to insert Equipment ID
      </h3>
      <Table tableConfig={TABLE_CONFIG}>
        <TBody>
          {equipment?.map(equipment => {
            return (
              <TRow
                key={equipment.id}
                onClick={() => {
                  setEquipmentId(equipment.id);
                  setIsOpen(() => false);
                }}
              >
                <TCell>{equipment.id}</TCell>
                <TCell>{equipment.manufacturer}</TCell>
                <TCell>{equipment.price}</TCell>
                {equipment.serial_number && <TCell>{equipment.serial_number}</TCell>}
                <TCell>{equipment.model}</TCell>
                <TCell>{equipment.created_at}</TCell>
              </TRow>
            );
          })}
        </TBody>
      </Table>
    </Modal>
  );
};
