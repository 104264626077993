import { useAuth0 } from '@auth0/auth0-react';

export const useLogout = (): {
  logout: () => void;
} => {
  const { logout: auth0Logout } = useAuth0();

  const logout = () => {
    localStorage.clear();
    auth0Logout({ returnTo: window.location.origin + '/' });
  };

  return { logout };
};
