import ButtonLarge from 'Component/Button/ButtonLarge';
import Modal from 'Component/Modal/Modal';
import { CustomOfferCalculator } from 'Page/Offer/Customize/Calculator';
import { Dispatch, SetStateAction } from 'react';
import { MCAOffer } from 'Util/types';

const ChangeModal = ({
  offer,
  isOpen,
  setIsOpen,
}: {
  offer: MCAOffer;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="flex flex-col">
        <h1 className="text-xl font-semibold">Adjust Your Plan</h1>
        <p className="text-primeft-500">Make any final adjustments to your plan.</p>
      </div>
      <div className="mb-12 mt-4 border-t-2 border-solid border-primeft-300" />
      <CustomOfferCalculator offer={offer} />
      <ButtonLarge
        size="sm"
        onClick={() => {
          setIsOpen(false);
        }}
        className="ml-auto"
      >
        Finish
      </ButtonLarge>
    </Modal>
  );
};

export default ChangeModal;
