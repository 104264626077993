import { AgreementChecklistItemType } from 'Component/ChecklistItem/ChecklistItem';
import { useAgreementContext } from 'Context/AgreementContext';
import { useFlowSequence } from 'Context/FlowSequenceContext';
import { useThemeContext } from 'Context/ThemeContext';
import Business from 'Page/Application/Business/Business';
import ConnectYourBank from 'Page/Application/ConnectYourBank/ConnectYourBank';
import { OnboardingDenied } from 'Page/Application/Onboarding/OnboardingDenied';
import { OnboardingReview } from 'Page/Application/Onboarding/OnboardingReview';
import Personal from 'Page/Application/Personal/Personal';
import Review from 'Page/Application/Review/Review';
import { ExecuteLoan } from 'Page/Application/Sign/ExecuteLoan';
import { SignView } from 'Page/Application/Sign/SignView';
import { SubmitVerification } from 'Page/Application/Verification/SubmitVerification';
import Loading from 'Page/Loading/Loading';
import { Underwriting } from 'Page/Underwriting/Underwriting';
import { UnderwritingDenied } from 'Page/Underwriting/UnderwritingDenied';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChecklistItemStatus, LoanApplicationStatuses, NavRoute } from 'Util/constants';
import { AgreementTypes } from 'Util/types';

const ApplicationRouting = () => {
  const { theme } = useThemeContext();
  const { currentStep, refetch } = useFlowSequence();
  const navigate = useNavigate();
  const { setAgreements } = useAgreementContext();
  useEffect(() => {
    // prettier-ignore
    const signedDocuments: AgreementTypes[] =
      currentStep === LoanApplicationStatuses.FINANCING_SIGNED
        ? ['offer_summary', 'itemization', 'unsecured_financing']
        : currentStep === LoanApplicationStatuses.ITEMIZATION_SIGNED
          ? ['offer_summary', 'itemization']
          : currentStep === LoanApplicationStatuses.OFFER_SUMMARY_SIGNED
            ? ['offer_summary']
            : [];

    setAgreements((agreements: AgreementChecklistItemType[]) => {
      return agreements.map((agreement: AgreementChecklistItemType) => ({
        ...agreement,
        status: signedDocuments.includes(agreement.agreementType)
          ? ChecklistItemStatus.SUCCESS
          : ChecklistItemStatus.INCOMPLETE,
      }));
    });
  }, [currentStep, setAgreements]);

  if (currentStep === LoanApplicationStatuses.NO_OFFER) {
    navigate(`/${NavRoute.NoOffer}`);
    return <Loading title="Checking for an existing offer" />;
  }

  if (!theme) {
    return <Loading title="Loading your application" />;
  }

  /* TODO
   * We can consolidate the identity verification flow by rendering the following:
   *
   * ```
   * import { IdentityContextProvider } from 'Context/identity';
   * import IdentityVerification from 'Page/Application/identity';
   * ...
   *   return (
   *     <IdentityContextProvider>
   *       <IdentityVerification />
   *     </IdentityContextProvider>
   *   );
   * ```
   * Within the following cases:
   *
   * - LoanApplicationStatuses.KYC:
   * - LoanApplicationStatuses.KYB:
   * - LoanApplicationStatuses.SUBMIT_ONBOARDING:
   */

  switch (currentStep) {
    case LoanApplicationStatuses.KYC:
      return <Personal onContinue={refetch} />;
    case LoanApplicationStatuses.KYB:
      return <Business onContinue={refetch} />;
    case LoanApplicationStatuses.SUBMIT_ONBOARDING:
      return <SubmitVerification onContinue={refetch} />;
    case LoanApplicationStatuses.ONBOARDING_DENIED:
      return <OnboardingDenied />;
    case LoanApplicationStatuses.ONBOARDING_REVIEW:
      return <OnboardingReview onContinue={refetch} />;
    case LoanApplicationStatuses.CONNECT_BANK:
      return <ConnectYourBank onContinue={refetch} />;
    case LoanApplicationStatuses.UNDERWRITING:
      return <Underwriting onContinue={refetch} status="UNDERWRITING" />;
    case LoanApplicationStatuses.UNDERWRITING_DENIED:
      return <UnderwritingDenied />;
    case LoanApplicationStatuses.UNDERWRITING_REVIEW:
      return <Underwriting onContinue={refetch} status="REVIEW" />;
    case LoanApplicationStatuses.REVIEW:
      return <Review />;
    case LoanApplicationStatuses.FINANCING_SIGNED:
      return <ExecuteLoan />;
    case LoanApplicationStatuses.OFFER_SUMMARY_SIGNED:
      return <SignView />;
    case LoanApplicationStatuses.ITEMIZATION_SIGNED:
      return <SignView />;
    case LoanApplicationStatuses.COMPLETE:
      navigate(`/${NavRoute.Dashboard}`);
      return <Loading title="Sending you to the dashboard" />;
  }
  return <Loading title="Loading your application" />;
};

export default ApplicationRouting;
