import { AxiosError } from 'axios';
import { useApi } from 'Context/ApiContext';
import { useMutation, useQueryClient } from 'react-query';

export type TransferParams = {
  user_id: string;
  loan_id: string;
};

export type TransferResponse = {
  transfer_id: string;
};

export const useExecuteLoan = () => {
  const api = useApi();
  const queryClient = useQueryClient();
  const initiateTransfer = async (transfer: TransferParams) =>
    (await api.post('/execute_loan', transfer)).data;

  return useMutation<TransferResponse, AxiosError, TransferParams>(
    async transfer => initiateTransfer(transfer),
    {
      onSuccess: ({ transfer_id: id }) => {
        queryClient.invalidateQueries({ queryKey: ['loans'] });
        console.log(`transfer ${id} initiated`);
      },
      onError: error => {
        console.log('Error initiating the transfer: ', error);
      },
    },
  );
};
