import { createContext, useContext, useState } from 'react';
import { NotificationType } from 'Util/constants';

export type Notification = {
  id: string;
  type: NotificationType;
  message: JSX.Element;
};
type NotificationContextValue = {
  addNotification: (notification: Omit<Notification, 'id'>) => void;
  closeNotification: (id: string) => void;
  notifications: Notification[];
};

const NavNotificationContext = createContext<NotificationContextValue | null>(null);

export function NavNotificationProvider(props: { children?: React.ReactNode }) {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const addNotification = (notification: Omit<Notification, 'id'>) => {
    setNotifications([
      ...notifications,
      { ...notification, id: Math.random().toString(36).substr(2, 9) },
    ]);
  };

  const closeNotification = (id: string) => {
    setNotifications(notifications.filter(notification => notification.id !== id));
  };

  return (
    <NavNotificationContext.Provider
      {...props}
      value={{ notifications, addNotification, closeNotification }}
    />
  );
}

export function useNavNotification() {
  const context = useContext(NavNotificationContext);

  if (!context) {
    throw new Error('Context not initialized');
  }
  return context;
}
