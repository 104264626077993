import { useGetLoanDetails } from 'Hook/Loans/useGetLoanDetails';
import { useGetUserLoan } from 'Hook/Loans/useGetUserLoan';
import { useGetOfferById } from 'Hook/Offers/useGetOfferById';
import { useGetUserBusiness } from 'Hook/useGetUserBusiness';
import useGetUser from 'Hook/Users/useGetUser';
import { createContext, useContext } from 'react';
import { Business, Loan, LoanDetails, Offer, User } from 'Util/types';

type BorrowerContextValue = {
  user: User | undefined;
  loan: Loan | undefined;
  isLoading: boolean;
  loanDetails: LoanDetails | undefined;
  business: Business | undefined;
  offer: Offer | undefined;
};

const BorrowerContext = createContext<BorrowerContextValue | null>(null);

export function BorrowerContextProvider(props: { children?: React.ReactNode }) {
  const { user, isLoading: isLoadingUser } = useGetUser();
  const { loan, isLoading: isLoadingLoan } = useGetUserLoan({ userId: user?.id });
  const { offer, isLoading: isLoadingOffer } = useGetOfferById(loan?.offer_id || '');
  const { loanDetails, isLoading: isLoadingLoanDetails } = useGetLoanDetails(loan?.id);
  const { business, isLoading: isLoadingBusiness } = useGetUserBusiness({
    userId: user?.id,
  });
  const isLoading =
    isLoadingUser ||
    isLoadingLoan ||
    isLoadingLoanDetails ||
    isLoadingBusiness ||
    isLoadingOffer;
  return (
    <BorrowerContext.Provider
      {...props}
      value={{ user, loan, isLoading, loanDetails, business, offer }}
    />
  );
}

export function useBorrower() {
  const context = useContext(BorrowerContext);

  if (!context) {
    throw new Error('Borrower Context is not provided');
  }
  return context;
}
