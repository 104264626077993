import React, { createContext, useContext, useState } from 'react';

const ScrollingContext = createContext<{
  scroll: number;
  setScroll: React.Dispatch<React.SetStateAction<number>>;
  isAtBottom: boolean;
  setIsAtBottom: React.Dispatch<React.SetStateAction<boolean>>;
} | null>(null);

export function ScrollingProvider({ children }: { children: React.ReactNode }) {
  const [scroll, setScroll] = useState(0);
  const [isAtBottom, setIsAtBottom] = useState(false);

  return (
    <ScrollingContext.Provider value={{ scroll, setScroll, isAtBottom, setIsAtBottom }}>
      {children}
    </ScrollingContext.Provider>
  );
}

export const useScroll = () => {
  const context = useContext(ScrollingContext);

  if (!context) {
    throw new Error('Context not initialized');
  }

  return context;
};
