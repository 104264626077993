import { MoneyAPIClearanceStatus } from 'Hook/Payments/useGetPaymentHistory';
import { classNames } from 'Util/css/css';

export const TransactionTypePill = ({
  type,
}: {
  type: MoneyAPIClearanceStatus | 'scheduled';
}) => {
  const baseClassName = 'px-2 py-1 rounded-full text-xs capitalize';
  const spanClassName = {
    scheduled: 'bg-primeft-500 border-[1px] border-primeft-500 text-black',
    [MoneyAPIClearanceStatus.PENDING]: 'bg-status-caution-500 text-white',
    [MoneyAPIClearanceStatus.PROCESSED]: '',
    [MoneyAPIClearanceStatus.CANCELLED]: 'bg-status-error-500 text-white',
    [MoneyAPIClearanceStatus.FAILED]: 'bg-status-error-500 text-white',
  };
  return (
    <span className={classNames(baseClassName, type ? spanClassName[type] : '')}>
      {type}
    </span>
  );
};
