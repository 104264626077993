import { useBankConnection } from 'Context/BankConnectionContext';
import { useGetBankInfo } from 'Hook/useGetBankInfo';
import { BankTile } from 'Page/Dashboard/components/ConnectedBanks/BankTile';
import { DisconnectedBankTile } from 'Page/Dashboard/components/ConnectedBanks/DisconnectedBankTile';

const ConnectedBanks = () => {
  const {
    institutionName,
    accountNumber,
    institutionLogo,
    isFetching: isFetchingBankInfo,
  } = useGetBankInfo();
  const { plaidAccessError } = useBankConnection();
  return (
    <div className="flex flex-col items-center gap-8">
      {plaidAccessError ? (
        <DisconnectedBankTile />
      ) : (
        <div className="flex w-full flex-row items-center justify-between">
          <div className="flex flex-col">
            <p className="text-sm font-medium">Connected Banks</p>
          </div>
          <div className="mx-4 flex flex-grow border-[0.5px] border-dashed" />
          <BankTile
            fetchingBankInfo={isFetchingBankInfo}
            institutionLogo={institutionLogo}
            institutionName={institutionName}
            accountNumber={accountNumber}
          />
        </div>
      )}
    </div>
  );
};

export default ConnectedBanks;
