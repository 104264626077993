import { useAuth0 } from '@auth0/auth0-react';
import { useApi } from 'Context/ApiContext';
import { useQuery } from 'react-query';
import { Business } from 'Util/types';

export const useGetUserBusiness = ({ userId }: { userId?: string }) => {
  const api = useApi();
  const { isAuthenticated } = useAuth0();
  const fetchBusinesses = async () => (await api.get<Business[]>('/businesses')).data;
  const { data: business, isLoading } = useQuery(['business', userId], fetchBusinesses, {
    enabled: isAuthenticated && !!userId,
    select: (data: Business[]): Business | undefined =>
      data.find(business => business.user_id === userId),
  });
  return { business, isLoading };
};
