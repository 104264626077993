import SlideOver from 'Component/SlideOver/SlideOver';
import { ToastContainer } from 'Component/Toast/ToastContainer';
import { ScrollingProvider, useScroll } from 'Context/ScrollingContext';
import ProgressSteps from 'Page/Application/ProgressSteps';
import Sidebar from 'Page/Application/Sidebar/Sidebar';
import React, { useEffect, useRef, useState } from 'react';
import { classNames } from 'Util/css/css';

type Size = 'normal' | 'large';

type ApplicationLayoutProps = {
  children: JSX.Element;
  stepsCompleted: number;
  size?: Size;
};

const ApplicationLayout = ({
  children,
  stepsCompleted,
  size = 'normal',
}: ApplicationLayoutProps) => {
  const { scroll, setScroll, setIsAtBottom } = useScroll();
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const pageContainerClassName = 'flex flex-col md:flex-row h-screen bg-primeft-300';
  const rightContainerClassName = 'py-16 flex flex-col justify-center w-full relative';
  const contentClassName = 'overflow-auto px-8';
  const contentSize: Record<Size, string> = {
    large: 'sm:mx-auto lg:w-5/6',
    normal: 'sm:mx-auto md:w-5/6 lg:w-2/3',
  };

  const getIsAtBottom = (height?: number) => {
    if (!contentRef.current) return false;

    const { current } = contentRef;

    return current.scrollTop + current.offsetHeight >= (height ?? current.scrollHeight);
  };

  const handleContentScroll = () => {
    const isAtBottom = getIsAtBottom();

    setIsAtBottom(isAtBottom);

    if (!isAtBottom) {
      setScroll(contentRef.current?.scrollTop ?? 0);
    }
  };

  useEffect(() => {
    const isAtBottom = getIsAtBottom(scroll);

    if (!isAtBottom) {
      contentRef.current?.scrollTo({ top: scroll });
    }
  }, [scroll]);

  return (
    <div className={pageContainerClassName}>
      <ToastContainer />
      <SlideOver open={sidebarOpen} setOpen={setSidebarOpen}>
        <Sidebar width="90%">
          <ProgressSteps stepsCompleted={stepsCompleted} />
        </Sidebar>
      </SlideOver>
      <div className="hidden p-6 md:flex md:min-w-[400px] md:max-w-sm">
        <Sidebar>
          <ProgressSteps stepsCompleted={stepsCompleted} />
        </Sidebar>
      </div>
      <div className={rightContainerClassName}>
        <div
          className={classNames(contentClassName, contentSize[size])}
          ref={contentRef}
          onScroll={handleContentScroll}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default function ApplicationLayoutWithScrolling({
  children,
  ...props
}: ApplicationLayoutProps) {
  return (
    <ScrollingProvider>
      <ApplicationLayout {...props}>{children}</ApplicationLayout>
    </ScrollingProvider>
  );
}
