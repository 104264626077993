import { useAuth0 } from '@auth0/auth0-react';
import { ClipboardCopyIcon } from '@heroicons/react/outline';
import { Toggle } from 'Component/Toggle/Toggle';
import { useBorrower } from 'Context/BorrowerContext';
import { ThemeContext } from 'Context/ThemeContext';
import { useGetOfferById } from 'Hook/Offers/useGetOfferById';
import { ReactNode, useContext } from 'react';
import { Theme } from 'Util/constants';
import { isAdmin } from 'Util/email/email';
import version from 'Util/version';

export const AdminBar = ({ children }: { children: ReactNode }) => {
  const { theme, toggleTheme } = useContext(ThemeContext) || {};
  const { user: auth0User } = useAuth0();
  const { user, loanDetails } = useBorrower();
  const { offer } = useGetOfferById(loanDetails?.offer_id ?? '');

  return (
    <>
      {isAdmin(auth0User?.email ?? '') && (
        <div className="flex flex-row justify-between bg-primeft-900 py-2 px-4">
          <div className="flex flex-row items-center gap-2">
            <span className="text-xs text-primeft-100">Prime</span>
            <Toggle
              enabled={theme === Theme.Odeko}
              setEnabled={() => {
                toggleTheme();
              }}
            />
            <span className="text-xs text-primeft-100">Odeko</span>
          </div>
          <div className="flex gap-2">
            {offer && (
              <div className="flex items-center">
                <span className="text-xs text-primeft-100">
                  Offer ID: (starts with {offer.id.substring(0, 4)})
                </span>
                <ClipboardCopyIcon
                  className="h-4 w-4 cursor-pointer text-primeft-100"
                  onClick={() => navigator.clipboard.writeText(offer.id || '')}
                />
              </div>
            )}
            {user && (
              <div className="flex items-center">
                <span className="text-xs text-primeft-100">
                  User ID: (starts with {user.id.substring(0, 4)})
                </span>
                <ClipboardCopyIcon
                  className="h-4 w-4 cursor-pointer text-primeft-100"
                  onClick={() => navigator.clipboard.writeText(user?.id || '')}
                />
              </div>
            )}
            {loanDetails && (
              <div className="flex items-center">
                <span className="text-xs text-primeft-100">
                  Loan ID: (starts with {loanDetails.id.substring(0, 4)})
                </span>
                <ClipboardCopyIcon
                  className="h-4 w-4 cursor-pointer text-primeft-100"
                  onClick={() => navigator.clipboard.writeText(loanDetails?.id || '')}
                />
              </div>
            )}
            <div className="flex items-center">
              <span className="pl-4 text-[.6rem] text-onComplimentary">
                Version {version}
              </span>
            </div>
          </div>
        </div>
      )}
      {children}
    </>
  );
};
