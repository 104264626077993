import { AxiosError } from 'axios';
import { useApi } from 'Context/ApiContext';
import { useMutation, useQueryClient } from 'react-query';

export type VerifyBusinessParams = {
  loan_id: string;
  name: string;
  federal_ein: string;
  phone_number?: string;
  owner_first_name: string;
  owner_last_name: string;
  address_line_1: string;
  address_line_2?: string;
  address_city: string;
  address_state: string;
  address_postal_code: string;
};

export type VerifyBusinessResponse = {
  msg: string;
};

export const useVerifyBusinessInfo = ({
  onSuccess,
  onError,
}: {
  onSuccess: () => void;
  onError: () => void;
}) => {
  const api = useApi();
  const queryClient = useQueryClient();
  const verifyBusiness = async (business: VerifyBusinessParams) =>
    (await api.post('/business/verification', business)).data;

  return useMutation<VerifyBusinessResponse, AxiosError, VerifyBusinessParams>(
    async business => verifyBusiness(business),
    {
      onSuccess: ({ msg }) => {
        console.log(msg);
        queryClient.invalidateQueries(['business']);
        onSuccess();
      },
      onError: error => {
        console.log('Error verifying the business: ', error);
        onError();
      },
    },
  );
};
