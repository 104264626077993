/* This example requires Tailwind CSS v2.0+ */
import { Dialog, Transition } from '@headlessui/react';
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from '@heroicons/react/solid';
import { Fragment } from 'react';

export default function SlideOver({
  children,
  open,
  setOpen,
}: {
  children: React.ReactNode;
  open: boolean;
  setOpen: (open: boolean) => void;
}) {
  const slideOverOpenerClassName =
    'bg-primary-500 rounded-r-lg px-2 py-4 md:hidden absolute left-0 top-4 z-10';
  const slideOverCloserClassName =
    'bg-primary-500 rounded-r-lg px-2 py-4 relative left-[calc(90%)] top-16 w-8';
  const doubleChevronClassName = 'h-4 w-4 text-white';
  const sidebarPositionClassName =
    'pointer-events-none fixed inset-y-0 -left-[calc(100%)] -top-12 flex max-w-[calc(100%)]';
  return (
    <>
      <div className={slideOverOpenerClassName} onClick={() => setOpen(true)}>
        <ChevronDoubleRightIcon className={doubleChevronClassName} />
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog className="relative z-10" onClose={setOpen}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className={sidebarPositionClassName}>
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-0"
                  enterTo="translate-x-full"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-full"
                  leaveTo="translate-x-0"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className={slideOverCloserClassName}>
                      <ChevronDoubleLeftIcon
                        className={doubleChevronClassName}
                        onClick={() => setOpen(false)}
                      />
                    </div>
                    {children}
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
