import { AxiosError } from 'axios';
import { useApi } from 'Context/ApiContext';
import { usePaymentTypeIdByName } from 'Hook/Payments/usePaymentTypeIdByName';
import { useMutation, useQueryClient } from 'react-query';
import { PaymentType } from 'Util/constants';
import { LoanPayment } from 'Util/types';

export const usePostLoanPayment = ({ loanId }: { loanId: string }) => {
  const api = useApi();
  const queryClient = useQueryClient();
  const manualPaymentTypeId = usePaymentTypeIdByName(PaymentType.MANUAL);

  const postLoanPayment = async ({ amount, date }: { amount: number; date: Date }) =>
    (
      await api.post('/payments', {
        payment_amount: amount,
        loan_id: loanId,
        payment_type_id: manualPaymentTypeId,
        scheduled_date: date.toISOString(),
      })
    ).data;

  return useMutation<LoanPayment, AxiosError, { amount: number; date: Date }>(
    async ({ amount, date }) => postLoanPayment({ amount, date }),
    {
      onSuccess: data => {
        console.log('loan payment', data);
        queryClient.invalidateQueries({ queryKey: ['loanPayments'] });
        queryClient.invalidateQueries({ queryKey: ['paymentHistory'] });
      },
      onError: error => {
        console.log('Error posting loan payment: ', error);
      },
    },
  );
};
