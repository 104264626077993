export const InfoLine = ({
  title,
  amount,
  children,
}: {
  title: string;
  amount: string;
  children?: React.ReactNode;
}) => {
  return (
    <div className="flex-start flex flex-row justify-between">
      <div>
        <span className="text-sm">{title}</span>
        <span className="ml-2">{children}</span>
      </div>
      <span className="text-sm font-medium">{amount}</span>
    </div>
  );
};
