import ButtonLarge from 'Component/Button/ButtonLarge';
import Footer from 'Component/Footer/Footer';
import { ImageBuilder, ImageType } from 'Component/ImageBuilder/ImageBuilder';
import { saveOfferDetails } from 'Page/Offer/utils';
import { useNavigate } from 'react-router-dom';
import { NavRoute, OfferStatus } from 'Util/constants';
import { toCurrency } from 'Util/currency/currency';
import { Equipment, BNPLOffer } from 'Util/types';

const EquipmentOfferCTA = ({
  offer,
  equipment,
}: {
  equipment: Equipment;
  offer: BNPLOffer;
}) => {
  const equipmentPrice = equipment?.price || 0;
  const navigate = useNavigate();
  return (
    <>
      <div className="max-w-4xl">
        <h1 className="max-w-md text-4xl leading-tight">
          Finance your purchase in weekly installments.
        </h1>
      </div>

      <div className="flex w-full flex-col items-start rounded-lg p-6 shadow-[0_20px_80px_0_rgba(0,0,0,0.15)] sm:w-[500px]">
        <p className="text-sm uppercase text-primeft-500/50">Installment Loan</p>
        <span className="text-2xl font-medium text-primeft-700">
          {offer.business_name}
        </span>
        <div className="my-6 flex w-full items-center justify-between rounded-md bg-black/10 bg-screen-secondary py-4 px-3.5 text-sm font-medium text-primeft-700">
          <p>Loan Amount</p>
          <p>{toCurrency(equipmentPrice, 0)}</p>
        </div>
        <ButtonLarge
          className="h-14 w-full text-base font-semibold md:w-full"
          onClick={() => {
            saveOfferDetails({
              amount: equipment.price,
              offerId: offer.id,
            });
            navigate(`/${NavRoute.CreateAccount}`);
          }}
          disabled={offer.status !== OfferStatus.Active}
        >
          Apply Now
        </ButtonLarge>
      </div>
    </>
  );
};
export const EquipmentOfferView = ({
  offer,
  equipment,
}: {
  offer: BNPLOffer;
  equipment: Equipment;
}) => {
  return (
    <div className="flex min-h-screen flex-col items-center justify-center bg-screen-primary text-center">
      <div className=" my-auto mt-20 mb-20 flex max-w-screen-2xl flex-col items-center justify-center">
        <div className="mb-6 flex justify-center">
          <ImageBuilder imageType={ImageType.EquipmentOfferLogo} />
        </div>
        <div className="flex flex-col items-center gap-12 px-6 text-onPrimary">
          <EquipmentOfferCTA equipment={equipment} offer={offer} />
        </div>
      </div>
      <div className="mt-auto">
        <Footer />
      </div>
    </div>
  );
};
