import { useAgreementContext } from 'Context/AgreementContext';
import { DocumentView } from 'Page/Application/Sign/DocumentView';
import { ExecuteLoan } from 'Page/Application/Sign/ExecuteLoan';
import { Intro } from 'Page/Application/Sign/Intro';
import { useState } from 'react';
import { ChecklistItemStatus } from 'Util/constants';

type Steps = 'Intro' | 'Sign';

export const SignView = () => {
  const [step, setStep] = useState<Steps>('Intro');

  const { agreements } = useAgreementContext();

  const agreementsToSign = agreements.filter(
    agreement => agreement.status !== ChecklistItemStatus.SUCCESS,
  );

  if (agreementsToSign.length === 0) {
    return <ExecuteLoan />;
  }

  switch (step) {
    case 'Intro':
      return <Intro onContinue={() => setStep('Sign')} />;
    case 'Sign':
      return <DocumentView agreementType={agreementsToSign[0].agreementType} />;
  }
};
