import Footer from 'Component/Footer/Footer';
import OfferFAQ from 'Page/Offer/Components/FAQ/OfferFAQ';
import OfferFeature from 'Page/Offer/Components/Feature/OfferFeature';
import OfferHeader from 'Page/Offer/Components/Header/OfferHeader';
import OfferCTA from 'Page/Offer/Components/OfferCTA/OfferCTA';
import OfferRepayment from 'Page/Offer/Components/Repayment/Repayment';
import { toCurrency } from 'Util/currency/currency';
import { MCAOffer } from 'Util/types';

export const MCAOfferView = (props: {
  firstSectionClassName: string;
  offer: MCAOffer;
  offerIsActive: boolean;
  offerBelongsToLoan: boolean;
}) => {
  return (
    <div>
      <div className={props.firstSectionClassName}>
        <OfferHeader
          name={props.offer.business_name}
          headline={`You're pre-qualified for up to ${toCurrency(
            props.offer.capital_amount_max,
            0,
          )} of growth capital to expand your business.`}
          expiresAt={props.offer.expires_at}
          offerStatus={props.offer.status}
        />
        <OfferFeature />
        <OfferCTA
          offerId={props.offer.id}
          offerIsActive={props.offerIsActive}
          offerBelongsToLoan={props.offerBelongsToLoan}
        />
      </div>
      <OfferRepayment offer={props.offer} />
      <OfferFAQ />
      <Footer />
    </div>
  );
};
