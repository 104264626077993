import { toCurrency } from 'Util/currency/currency';
import { Equipment } from 'Util/types';

export const EquipmentDetailSummary = ({ equipment }: { equipment?: Equipment }) => {
  const equipmentPrice = equipment?.price || 0;
  return (
    <div className="flex flex-row items-center justify-between rounded-md bg-screen-secondary p-4">
      <div className="flex flex-col text-left">
        {equipment ? (
          <>
            <span className="text-sm md:text-lg">Loan Amount</span>
          </>
        ) : (
          <span>Loading...</span>
        )}
      </div>
      <div className="flex flex-col gap-1 text-right">
        {equipment ? (
          <span className="text-3xl">{toCurrency(equipmentPrice, 0)}</span>
        ) : (
          <span>Loading...</span>
        )}
      </div>
    </div>
  );
};
