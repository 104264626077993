import { useApi } from 'Context/ApiContext';
import { useQuery } from 'react-query';

type APIResponse = {
  financing: {
    agreement_type: string;
    financing_html?: string;
    future_receipt_purchase_amount: number;
    future_receipt_daily_percentage: number;
    discount: number;
    purchase_price: number;
    disbursed_amount: number;
    max_remittance_amount: number;
    acceptance_date: string;
    authorized_signer: string;
    authorized_signer_title: string;
    prime_authorized_signer: string;
    prime_authorized_signer_title: string;
    prime_acceptance_date: string;
    prime_signature: string;
  };
  offer_summary: {
    payment_frequency_description: string;
    funding_amount_provided: number;
    effective_apr: number;
    finance_charge: number;
    total_payments_amount: number;
    avg_monthly_cost: number;
    payment: string;
    term: string;
    collateral_requirements: string;
  };
  itemization: {
    amount_given_to_business: number;
    amount_paid_supplier: number;
    amount_paid_customer: number;
    prepaid_finance_charges: number;
    amount_financed: number;
  };
};

export type FinancingAgreementInfo = {
  agreementHtml: string;
  futureReceiptPurchaseAmount: number;
  futureReceiptDailyPercentage: number;
  discount: number;
  purchasePrice: number;
  disbursedAmount: number;
  maxRemittanceAmount: number;
  acceptanceDate: string;
  authorizedSigner: string;
  authorizedSignerTitle: string;
  primeAuthorizedSigner: string;
  primeAuthorizedSignerTitle: string;
  primeAcceptanceDate: string;
  primeSignature: string;
};

export type OfferSummaryInfo = {
  paymentFrequencyDescription: string;
  loanAmount: number;
  effectiveAPR: number;
  financeCharge: number;
  totalPaymentsAmount: number;
  avgMonthlyCost: number;
  payment: string;
  term: string;
  collateralRequirements: string;
};

export type ItemizationInfo = {
  givenDirectly: number;
  paidBySupplier: number;
  prepaidFinanceCharges: number;
};

export type AgreementInfo = {
  financingAgreement: FinancingAgreementInfo;
  offerSummary: OfferSummaryInfo;
  itemization: ItemizationInfo;
};

export function useGetAgreementsInfo(loanId = '') {
  const api = useApi();
  const fetchAgreementContent = async () =>
    (await api.get<APIResponse>(`/agreements/loan/${loanId}/info?html=true`)).data;
  return useQuery(['agreement', loanId], fetchAgreementContent, {
    enabled: !!loanId,
    select: (data: APIResponse): AgreementInfo => ({
      financingAgreement: {
        agreementHtml: data.financing.financing_html ?? '',
        futureReceiptPurchaseAmount: data.financing.future_receipt_purchase_amount,
        futureReceiptDailyPercentage: data.financing.future_receipt_daily_percentage,
        discount: data.financing.discount,
        purchasePrice: data.financing.purchase_price,
        disbursedAmount: data.financing.disbursed_amount,
        maxRemittanceAmount: data.financing.max_remittance_amount,
        acceptanceDate: data.financing.acceptance_date,
        authorizedSigner: data.financing.authorized_signer,
        authorizedSignerTitle: data.financing.authorized_signer_title,
        primeAuthorizedSigner: data.financing.prime_authorized_signer,
        primeAuthorizedSignerTitle: data.financing.prime_authorized_signer_title,
        primeAcceptanceDate: data.financing.prime_acceptance_date,
        primeSignature: data.financing.prime_signature,
      },
      offerSummary: {
        paymentFrequencyDescription: data.offer_summary.payment_frequency_description,
        loanAmount: data.offer_summary.funding_amount_provided,
        effectiveAPR: data.offer_summary.effective_apr,
        financeCharge: data.offer_summary.finance_charge,
        totalPaymentsAmount: data.offer_summary.total_payments_amount,
        avgMonthlyCost: data.offer_summary.avg_monthly_cost,
        payment: data.offer_summary.payment,
        term: data.offer_summary.term,
        collateralRequirements: data.offer_summary.collateral_requirements,
      },
      itemization: {
        givenDirectly: data.itemization.amount_given_to_business,
        paidBySupplier: data.itemization.amount_paid_supplier,
        prepaidFinanceCharges: data.itemization.prepaid_finance_charges,
      },
    }),
  });
}
