import { BankConnectionProvider } from 'Context/BankConnectionContext';
import { SignView } from 'Page/Application/Sign/SignView';
import { useState } from 'react';
import ReviewPlanDetails from './ReviewPlanDetails';

const Review = () => {
  const [showSign, setShowSign] = useState<boolean>(false);
  if (showSign) {
    return <SignView />;
  }
  return (
    <BankConnectionProvider>
      <ReviewPlanDetails onContinueHandler={() => setShowSign(true)} />
    </BankConnectionProvider>
  );
};

export default Review;
