import { forwardRef } from 'react';
import type { TRowProps } from 'Util/types';

const THeadRow = forwardRef<HTMLTableRowElement, TRowProps>(
  ({ className, ...props }, ref) => (
    <tr className={`${className ?? ''}`} ref={ref} {...props} />
  ),
);

THeadRow.displayName = 'THeadRow';

export default THeadRow;
