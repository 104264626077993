import { useGetBankAccount, AccountInfoData } from './useGetBankAccount';

type EstimatedClearingData = {
  estimatedTime: string;
  isFetching: boolean;
};

const INSTANT_VALUE = 'realtime';

export const useGetLoanClearingTime = (): EstimatedClearingData => {
  const { accountInfo, isFetching }: AccountInfoData = useGetBankAccount();

  return {
    isFetching,
    estimatedTime:
      accountInfo?.money_api_bank_account_rails === INSTANT_VALUE
        ? 'instant'
        : '1 - 2 business days',
  };
};
