import { useAuth0 } from '@auth0/auth0-react';
import ButtonLarge from 'Component/Button/ButtonLarge';
import PlaidButton from 'Component/PlaidButton/PlaidButton';
import { useBorrower } from 'Context/BorrowerContext';
import { useToastNotification } from 'Context/ToastNotificationContext';
import { useGetBusinessByLoanId } from 'Hook/Businesses/useGetBusinessByLoanId';
import { usePostPublicToken } from 'Hook/usePostPublicToken';
import ConnectYourBankLayout from 'Page/Application/ConnectYourBank/layouts/ConnectYourBankLayout';
import ApplicationLayout from 'Page/Application/layouts/ApplicationLayout';
import { useEffect, useState } from 'react';
import {
  APIErrorCodes,
  ApplicationStepsCompleted,
  ChecklistItemStatus,
  NotificationType,
  PlaidVerificationMsg,
} from 'Util/constants';
import ConnectBankSteps from './ConnectBankSteps';
import Fail from './Fail';

export enum ConnectBankStatus {
  connect = 'Connect',
  success = 'Success',
  partial = 'Partial',
  fail = 'Fail',
}

export type ConnectYourBankError = {
  message: string;
  type: 'LinkToken' | 'Plaid';
};

const ConnectYourBank = ({ onContinue }: { onContinue: () => void }) => {
  const { addNotification } = useToastNotification();
  const { isAuthenticated } = useAuth0();
  const { user, loan } = useBorrower();
  const { business } = useGetBusinessByLoanId(loan?.id);

  const [publicToken, setPublicToken] = useState<string>('');
  const [plaidCompletion, setPlaidCompletion] = useState<ConnectBankStatus>(
    ConnectBankStatus.connect,
  );
  const [errorCodes, setErrorCodes] = useState<APIErrorCodes[]>([]);

  const { mutate: postPublicToken, isLoading: isLoadingExchangeToken } =
    usePostPublicToken({
      onSuccess: () => {
        if (!business?.id) {
          console.log('Business does not exist');
          return;
        }
      },
      onError: error => {
        console.log('Error exchanging public token: ', error);
        if (error?.response?.data?.msg === PlaidVerificationMsg.UNVERIFIED) {
          setPlaidCompletion(ConnectBankStatus.partial);
          setErrorCodes(error?.response?.data?.error_code);
          return;
        }
        addNotification({
          type: NotificationType.Error,
          title: 'Error exchanging public token',
          message: 'Please refresh the page and try again',
          autoClose: true,
        });
        setPlaidCompletion(ConnectBankStatus.fail);
      },
    });

  useEffect(() => {
    if (isAuthenticated && publicToken && user && business) {
      postPublicToken({ public_token: publicToken, user_id: user.id });
    }
  }, [isAuthenticated, publicToken, postPublicToken, user, business]);

  const handlePlaidSuccess = (publicToken: string) => {
    setPublicToken(publicToken);
    setPlaidCompletion(ConnectBankStatus.success);
  };

  const handlePlaidFailure = (error: ConnectYourBankError) => {
    if (error.type === 'LinkToken') {
      addNotification({
        title: 'Error fetching link token',
        message: error.message,
        type: NotificationType.Error,
      });
      return;
    }
    setPlaidCompletion(ConnectBankStatus.fail);
  };

  const [consentChecked, setConsentChecked] = useState(false);
  const MainScreen = {
    [ConnectBankStatus.connect]: (
      <ConnectYourBankLayout
        title="Connect Your Bank"
        subtitle="In order for us to send funds and validate certain information, you will need to connect your business’ primary bank account."
        button={
          <PlaidButton
            successHandler={handlePlaidSuccess}
            failHandler={handlePlaidFailure}
            showDisclaimer={false}
            disabled={!consentChecked}
          />
        }
      >
        <>
          <ConnectBankSteps
            chooseBank={ChecklistItemStatus.INCOMPLETE}
            checkingAccount={ChecklistItemStatus.INCOMPLETE}
            achInfo={ChecklistItemStatus.INCOMPLETE}
          />
          <div className="flex flex-row gap-2 text-xs">
            <input
              className="rounded-sm checked:bg-darkIcon checked:ring-0 checked:hover:bg-darkIcon focus:ring-0 checked:focus:bg-darkIcon"
              type="checkbox"
              checked={consentChecked}
              onChange={() => setConsentChecked(!consentChecked)}
              name="Consent Checkbox"
            />
            <label htmlFor="Consent Checkbox">
              By checking this box, you consent to the storage and processing of your
              banking information by Prime Financial Technologies.
            </label>
          </div>
        </>
      </ConnectYourBankLayout>
    ),
    [ConnectBankStatus.success]: (
      <ConnectYourBankLayout
        title="Connection Successful"
        subtitle="Excellent! We have successfully connected to your bank account."
        isProcessing={isLoadingExchangeToken}
        button={
          <ButtonLarge className="md:ml-auto" onClick={onContinue}>
            Finish Banking
          </ButtonLarge>
        }
      >
        <ConnectBankSteps
          chooseBank={ChecklistItemStatus.SUCCESS}
          checkingAccount={ChecklistItemStatus.SUCCESS}
          achInfo={ChecklistItemStatus.SUCCESS}
        />
      </ConnectYourBankLayout>
    ),
    [ConnectBankStatus.partial]: (
      <ConnectYourBankLayout
        title="We need a few more things from your bank connection."
        subtitle="Please make sure you have checked all the boxes necessary in order to move forward in the process."
        button={
          <ButtonLarge
            className="md:ml-auto"
            onClick={() => setPlaidCompletion(ConnectBankStatus.connect)}
          >
            Try Again
          </ButtonLarge>
        }
      >
        <ConnectBankSteps
          chooseBank={ChecklistItemStatus.SUCCESS}
          checkingAccount={
            errorCodes.includes(APIErrorCodes.MISSING_CHECKING_ACCOUNT)
              ? ChecklistItemStatus.FAILED
              : ChecklistItemStatus.SUCCESS
          }
          achInfo={
            errorCodes.includes(APIErrorCodes.MISSING_ACH_INFO)
              ? ChecklistItemStatus.FAILED
              : ChecklistItemStatus.SUCCESS
          }
        />
      </ConnectYourBankLayout>
    ),
    [ConnectBankStatus.fail]: (
      <ConnectYourBankLayout
        title="Failed to retrieve what we need"
        subtitle="Something went wrong when trying to connect your bank account to our system. Please either try again or contact our support team for further guidance."
        button={
          <ButtonLarge
            className="md:ml-auto"
            onClick={() => setPlaidCompletion(ConnectBankStatus.connect)}
          >
            Retry
          </ButtonLarge>
        }
      >
        <Fail />
      </ConnectYourBankLayout>
    ),
  };

  return (
    <ApplicationLayout stepsCompleted={ApplicationStepsCompleted.BANK_ACCOUNT_CONNECTION}>
      <>{MainScreen[plaidCompletion]}</>
    </ApplicationLayout>
  );
};

export default ConnectYourBank;
