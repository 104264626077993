import { Switch } from '@headlessui/react';
import { classNames } from 'Util/css/css';

export const Toggle = ({
  enabled,
  setEnabled,
  enabledClassName = 'bg-primary-500',
  disabledClassName = 'bg-primary-500',
}: {
  enabled: boolean;
  setEnabled: (enable: boolean) => void;
  enabledClassName?: string;
  disabledClassName?: string;
}) => {
  return (
    <Switch
      checked={enabled}
      onChange={setEnabled}
      className={classNames(
        enabled ? enabledClassName : disabledClassName,
        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none',
      )}
    >
      <span className="sr-only">Use setting</span>
      <span
        aria-hidden="true"
        className={classNames(
          enabled ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
        )}
      />
    </Switch>
  );
};
