import { Popover, Transition } from '@headlessui/react';
import {
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/outline';
import { Fragment } from 'react';
import Calendar from 'react-calendar';
import { classNames } from 'Util/css/css';

export const CalendarInput = ({
  date,
  setDate,
}: {
  date: Date;
  setDate: (date: Date) => void;
}) => {
  const isToday = date.toDateString() === new Date().toDateString();
  return (
    <Popover>
      <Popover.Button className="flex flex-row">
        <CalendarIcon className="mr-2 h-5 w-5 cursor-pointer" />
        <span className="text-primeft-900">
          {isToday ? 'Today' : date.toLocaleDateString('en-US', { dateStyle: 'short' })}
        </span>
      </Popover.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Popover.Panel>
          {({ close }) => (
            <Calendar
              value={date}
              calendarType="US"
              onChange={(date: Date) => {
                setDate(date);
                close();
              }}
              minDate={new Date()}
              className="absolute bottom-6 right-2 z-10 w-80 rounded-xl border bg-white p-4 shadow-lg"
              prevLabel={<ChevronLeftIcon className="h-4 w-4" />}
              prev2Label={null}
              nextLabel={<ChevronRightIcon className="h-4 w-4" />}
              next2Label={null}
              minDetail="month"
              maxDetail="month"
              formatShortWeekday={(locale, date) => {
                return date.toLocaleString(locale, { weekday: 'short' });
              }}
              navigationLabel={({ date }) => {
                const month = date.toLocaleString('en-US', { month: 'long' });
                const year = date.toLocaleString('en-US', { year: 'numeric' });
                return (
                  <span className="text-md px-8">
                    {month} {year}
                  </span>
                );
              }}
              tileClassName={({ date: tileDate }) => {
                const baseTileClassName = 'p-2 text-sm mb-[-1px] mr-[-1px] border';
                const today = new Date();
                const isToday = tileDate.toDateString() === today.toDateString();
                const isSelected = tileDate.toDateString() === date.toDateString();
                const isDisabled = tileDate < today;
                const hoverClassName = 'hover:bg-primary-500 hover:text-white';
                const selectedClassName = 'bg-primary-500 text-primeft-100 text-sm';
                const todayClassName = 'bg-primeft-500';
                return classNames(
                  baseTileClassName,
                  hoverClassName,
                  isSelected ? selectedClassName : isToday ? todayClassName : '',
                  isDisabled ? 'text-gray-300' : '',
                );
              }}
            />
          )}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};
