import { ArrowDownIcon } from '@heroicons/react/solid';
import ButtonLarge from 'Component/Button/ButtonLarge';
import { useBorrower } from 'Context/BorrowerContext';
import { useScroll } from 'Context/ScrollingContext';
import { useGetAgreementsInfo } from 'Hook/useGetAgreementsInfo';
import AgreementOverviewSection from 'Page/Application/Sign/components/AgreementOverviewSection';
import { FinalSignature } from 'Page/Application/Sign/components/FinalSignature';
import { useRef } from 'react';

export const FinancingAgreement = ({
  signature,
  setSignature,
  validSignature,
}: {
  signature: string;
  setSignature: (signature: string) => void;
  validSignature: boolean;
}) => {
  const { loan } = useBorrower();
  const { data } = useGetAgreementsInfo(loan?.id);
  const { scroll, setScroll, isAtBottom } = useScroll();
  const { financingAgreement: financingAgreementInfo } = data ?? {};
  const agreementRef = useRef<HTMLDivElement | null>(null);
  const arrowDownClassName =
    'group-hover:text-button-primary h-4 w-4 mr-2 text-button-primary';

  const handleClickSkipToSign = () => {
    setScroll(agreementRef.current?.clientHeight ?? 0);
  };

  const financingAgreementHtml = financingAgreementInfo?.agreementHtml ?? '';

  return (
    <div className="relative flex" ref={agreementRef}>
      <div className="rounded-lg border-primeft-300 p-8 md:border-[1px]">
        <div
          dangerouslySetInnerHTML={{
            __html: financingAgreementHtml,
          }}
        ></div>
        <AgreementOverviewSection />
        <FinalSignature
          authorizedSigner={financingAgreementInfo?.authorizedSigner ?? ''}
          authorizedSignerTitle={financingAgreementInfo?.authorizedSignerTitle ?? ''}
          signature={signature}
          setSignature={setSignature}
          primeAcceptanceDate={financingAgreementInfo?.primeAcceptanceDate ?? ''}
          primeAuthorizedSigner={financingAgreementInfo?.primeAuthorizedSigner ?? ''}
          primeAuthorizedSignerTitle={
            financingAgreementInfo?.primeAuthorizedSignerTitle ?? ''
          }
          primeSignature={financingAgreementInfo?.primeSignature ?? ''}
          validSignature={validSignature}
        />
      </div>
      {!isAtBottom && (
        <ButtonLarge
          className="absolute left-[38%]"
          style={{
            top: `calc(60vh + ${scroll}px)`,
          }}
          IconStart={<ArrowDownIcon className={arrowDownClassName} aria-hidden="true" />}
          onClick={handleClickSkipToSign}
        >
          Skip to Sign
        </ButtonLarge>
      )}
    </div>
  );
};
