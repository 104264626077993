import { MailIcon } from '@heroicons/react/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import auth0 from 'auth0-js';
import { ImageBuilder, ImageType } from 'Component/ImageBuilder/ImageBuilder';
import ApplicationLayout from 'Page/Application/layouts/ApplicationLayout';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { ApplicationStepsCompleted, NavRoute } from 'Util/constants';
import * as z from 'zod';
import { Button } from '@/components/button';
import { Checkbox } from '@/components/checkbox';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/form';
import { Input } from '@/components/input';

function SharedLayout({ children }: { children: any }) {
  return (
    <ApplicationLayout stepsCompleted={ApplicationStepsCompleted.CREATE_ACCOUNT}>
      {children}
    </ApplicationLayout>
  );
}

const formSchema = z
  .object({
    account_email: z.string().email({ message: 'Please enter a valid email address' }),
    agree_to_terms: z.boolean(),
  })
  .refine(data => data.agree_to_terms, {
    message: 'You must agree to the terms of service',
    path: ['agree_to_terms'],
  });

export default function CreateAccount() {
  const webAuth = new auth0.WebAuth({
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID ?? '',
    domain: process.env.REACT_APP_AUTH0_DOMAIN ?? '',

    redirectUri: `${window.location.origin}/${NavRoute.Application}`,
    responseType: 'token',
  });

  const [processing, setProcessing] = useState<boolean>(false);
  const [auth0WebAuth] = useState<auth0.WebAuth>(webAuth);
  const [emailSent, setEmailSent] = useState<string | null>(null);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      account_email: '',
      agree_to_terms: false,
    },
  });

  function onSubmit(values: z.infer<typeof formSchema>) {
    setProcessing(true);
    auth0WebAuth.passwordlessStart(
      {
        connection: 'email',
        send: 'link',
        email: values.account_email,
      },
      function (err: any) {
        if (!err) {
          setEmailSent(values.account_email);
          setProcessing(false);
        }
      },
    );
  }

  if (emailSent) {
    return (
      <SharedLayout>
        <CheckInbox email={emailSent} />
      </SharedLayout>
    );
  }

  const buttonDisabled = processing;

  return (
    <SharedLayout>
      <div className="flex flex-col gap-8">
        <div>
          <ImageBuilder imageType={ImageType.VerifyIdentityGraphic} />
          <h1 className="text-2xl lg:text-3xl">Let&rsquo;s Get Started</h1>
          <p className="text-primeft-500">
            Please enter the business email address we will use to secure your account and
            send communications.
          </p>
        </div>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
            <FormField
              control={form.control}
              name="account_email"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input type="email" placeholder="Business Email Address" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="agree_to_terms"
              render={({ field }) => (
                <FormItem className="mb-8 flex flex-col">
                  <div className="flex flex-row items-start space-x-3 space-y-0">
                    <FormControl>
                      <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                    </FormControl>
                    <FormLabel className="text-xs font-normal text-primeft-500">
                      By submitting your email, you agree to the{' '}
                      <Link className="underline" to={`/${NavRoute.TermsOfService}`}>
                        Terms of Service
                      </Link>
                      ,{' '}
                      <Link className="underline" to={`/${NavRoute.PrivacyPolicy}`}>
                        Privacy Policy
                      </Link>
                      , and{' '}
                      <Link className="underline" to={`/${NavRoute.ESign}`}>
                        E-Sign
                      </Link>
                    </FormLabel>
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button type="submit" disabled={buttonDisabled}>
              Send Email Verification
              <MailIcon className="ml-2 h-4 w-4 text-button-primary group-hover:text-button-primary" />
            </Button>
          </form>
        </Form>
      </div>
    </SharedLayout>
  );
}

function CheckInbox({ email }: { email: string }) {
  return (
    <div className="flex flex-col gap-1">
      <h2 className="text-3xl">Check inbox</h2>
      <p className="text-primeft-500">
        We&rsquo;ve sent a email to {email} containing a verification link. Please check
        your inbox for a message from noreply@primeft.com to finish creating your account.
      </p>
    </div>
  );
}
