import { ImageBuilder, ImageType } from 'Component/ImageBuilder/ImageBuilder';
import { useToastNotification } from 'Context/ToastNotificationContext';
import { useVerifyInformation } from 'Hook/useVerifyInformation';
import ApplicationLayout from 'Page/Application/layouts/ApplicationLayout';
import { OnboardingProcessing } from 'Page/Application/Onboarding/OnboardingProcessing';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  ApplicationStepsCompleted,
  NavRoute,
  NotificationType,
  OnboardingServiceErrorCode,
} from 'Util/constants';

export const OnboardingReview = ({ onContinue }: { onContinue: () => void }) => {
  const [checkVerify, setCheckVerify] = useState(true);
  const { addNotification } = useToastNotification();

  const { mutate: postVerify } = useVerifyInformation({
    onSuccess: onContinue,
    onError: err => {
      if (
        err?.response?.data?.error_code ===
        OnboardingServiceErrorCode.APPLICATION_UNDER_REVIEW
      ) {
        setCheckVerify(false);
      } else if (
        err?.response?.data?.error_code === OnboardingServiceErrorCode.APPLICATION_DENIED
      ) {
        onContinue();
      } else {
        setCheckVerify(false);
        addNotification({
          type: NotificationType.Error,
          title: 'Error verifying your information',
          message: 'Please wait a moment and try again',
        });
      }
    },
  });

  useEffect(() => {
    if (checkVerify && postVerify) {
      setTimeout(postVerify, 3000);
    }
    return;
  }, [checkVerify, postVerify]);
  const titleText = 'Reviewing your information';
  const subtitleText =
    'Thank you for submitting your information. We are currently reviewing it and will be in touch via email shortly. We appreciate your patience as we verify your application. If you have any questions, please reach out to us.';
  const buttonText = 'Questions? Contact Us';
  const waitingTimeText = 'approximate wait time:';
  const waitingTime = '~24hrs';

  if (checkVerify) {
    return <OnboardingProcessing />;
  }
  return (
    <ApplicationLayout stepsCompleted={ApplicationStepsCompleted.IDENTITY_VERIFICATION}>
      <div className="flex flex-col items-center gap-2">
        <ImageBuilder imageType={ImageType.ReviewGraphic} />
        <h1 className="mt-2 text-center text-3xl">{titleText}</h1>
        <span className="max-w-xl px-4 text-center text-onPrimary">{subtitleText}</span>
        <div className="flex flex-col gap-1">
          <span className="text-center text-sm font-semibold">{waitingTimeText}</span>
          <span className="text-xm text-center">{waitingTime}</span>
        </div>
        <Link
          className="pt-4 text-sm text-button-complimentary"
          to={`/${NavRoute.Support}`}
        >
          {buttonText}
        </Link>
      </div>
    </ApplicationLayout>
  );
};
