import { forwardRef } from 'react';
import type { TRowProps } from 'Util/types';

const TRow = forwardRef<HTMLTableRowElement, TRowProps>(
  ({ className, ...props }, ref) => (
    <tr className={`cursor-pointer ${className ?? ''}`} ref={ref} {...props} />
  ),
);

TRow.displayName = 'TRow';

export default TRow;
