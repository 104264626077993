import { Menu, Transition } from '@headlessui/react';
import ActionMenuItem, { ActionMenuItemProps } from 'Component/ActionMenu/ActionMenuItem';
import { Fragment } from 'react';

type ActionMenuProps = {
  children: React.ReactNode;
  actionItemOptions: ActionMenuItemProps[];
};

const ActionMenu = ({ children, actionItemOptions }: ActionMenuProps) => {
  const dropdownContainerClassName =
    'origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-primeft-900 ring-1 ring-black ring-opacity-5 focus:outline-none z-10';
  return (
    <Menu as="div" className="relative h-full">
      <Menu.Button className="h-full">
        <span className="sr-only">Open user menu</span>
        {children}
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className={dropdownContainerClassName}>
          {actionItemOptions.map(item => (
            <ActionMenuItem label={item.label} onClick={item.onClick} key={item.label} />
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default ActionMenu;
