import { useAuth0 } from '@auth0/auth0-react';
import ButtonLarge from 'Component/Button/ButtonLarge';
import Footer from 'Component/Footer/Footer';
import NavBar from 'Component/NavBar/NavBar';
import { ToastContainer } from 'Component/Toast/ToastContainer';
import { useToastNotification } from 'Context/ToastNotificationContext';
import { useLogout } from 'Hook/useLogout';
import { useState } from 'react';
import { NotificationType } from 'Util/constants';
import { isAdmin } from 'Util/email/email';

const LogoutButton = () => {
  const { logout } = useLogout();

  return (
    <ButtonLarge className="my-2" onClick={logout}>
      Log Out
    </ButtonLarge>
  );
};

const Profile = () => {
  const { user, error } = useAuth0();
  return (
    <div className="flex flex-row items-center gap-2">
      <img className="h-16 w-16 rounded-md" src={user?.picture} alt={user?.name} />
      <div>
        <p>{user?.email}</p>
        <p className="text-xs text-primeft-500">{user?.name}</p>
        {error && <div className="text-xs">{error.message}</div>}
      </div>
    </div>
  );
};

const GetAccessToken = () => {
  const getAccessOptions = {
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  };
  const { getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = useState('');
  const { addNotification } = useToastNotification();

  const getAccessToken = async () => {
    const token = await getAccessTokenSilently(getAccessOptions);
    setAccessToken(token);
  };

  return (
    <div className="w-full gap-4">
      <ButtonLarge onClick={getAccessToken}>Get Access Token</ButtonLarge>
      {accessToken && (
        <div>
          <p className="mt-2 truncate rounded-md bg-primeft-500 p-4 text-xs">
            {accessToken}
          </p>
          <ButtonLarge
            className="my-2"
            size="sm"
            onClick={() =>
              navigator.clipboard.writeText(accessToken).then(
                () =>
                  addNotification({
                    title: 'Success',
                    message: 'Copied to clipboard',
                    type: NotificationType.Success,
                  }),
                () =>
                  addNotification({
                    title: 'Error',
                    message: 'Failed to copy',
                    type: NotificationType.Error,
                  }),
              )
            }
          >
            Copy key to clipboard
          </ButtonLarge>
        </div>
      )}
    </div>
  );
};

const AdminActions = () => {
  const { addNotification } = useToastNotification();
  const { isLoading } = useAuth0();
  return (
    <div className="flex flex-col gap-4">
      <h2 className="text-xl">Admin Actions</h2>
      <GetAccessToken />
      <ButtonLarge
        onClick={() =>
          addNotification({
            message: 'Success!',
            title: 'Woo!',
            type: NotificationType.Success,
          })
        }
      >
        Success
      </ButtonLarge>
      <ButtonLarge
        onClick={() =>
          addNotification({
            message: 'Error!',
            title: 'Oh no!',
            type: NotificationType.Error,
          })
        }
      >
        Error
      </ButtonLarge>
      <ToastContainer />
      {isLoading ? (
        <div className="text-xs text-primeft-500">Loading ...</div>
      ) : (
        <div className="text-xs text-primeft-500">Done Loading Auth0</div>
      )}
    </div>
  );
};

function Account() {
  const containerClassName = 'bg-primeft-300 min-h-screen';
  const { user, isLoading } = useAuth0();
  return (
    <div className={containerClassName}>
      <NavBar />
      <div className="mx-auto my-8 flex max-w-3xl flex-col gap-8 rounded-md border-primeft-300 bg-primeft-100 p-8">
        <div>
          <h2 className="mb-4 text-xl">My Account</h2>
          {isLoading ? (
            <div className="text-xs text-primeft-500">Loading ...</div>
          ) : (
            <>
              <Profile />
              <LogoutButton />
            </>
          )}
        </div>
        {isAdmin(user?.email ?? '') && <AdminActions />}
      </div>
      <Footer />
    </div>
  );
}

export default Account;
