import { ExclamationIcon } from '@heroicons/react/outline';
import { useBorrower } from 'Context/BorrowerContext';
import { useOffer } from 'Context/OfferContext';
import { useThemeContext } from 'Context/ThemeContext';
import { useGetEquipmentById } from 'Hook/useGetEquipmentById';
import Loading from 'Page/Loading/Loading';
import { EquipmentOfferView } from 'Page/Offer/EquipmentOfferView';
import { MCAOfferView } from 'Page/Offer/MCAOfferView';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loanIdSet, userIdSet } from 'Store/OfferSlice';
import { OfferStatus, Theme } from 'Util/constants';
import { isBNPLOffer, isMCAOffer } from 'Util/typeHelpers/typeHelpers';

const OfferView = () => {
  const { offer, isLoading: isLoadingOffer } = useOffer();
  const { loan, isLoading: isLoadingBorrower } = useBorrower();
  const { data: equipment } = useGetEquipmentById(offer?.equipment_id || '');
  const { theme } = useThemeContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  dispatch(loanIdSet(''));
  dispatch(userIdSet(''));
  const containerClassName =
    'bg-primeft-100 mx-auto min-h-screen text-center overflow-hidden';
  if (!isLoadingOffer && !offer) {
    navigate('/unknown');
    return null;
  }

  const offerIsActive = offer?.status === OfferStatus.Active;
  const offerBelongsToLoan = offer?.id === loan?.offer_id;
  if (offer && !offerIsActive && loan && !offerBelongsToLoan) {
    navigate('/apply');
    return null;
  }

  const firstSectionClassName = {
    [Theme.PrimeFT]: 'bg-gradientDark pb-4 flex flex-col gap-4',
    [Theme.Odeko]: 'bg-primeft-300 pb-4 flex flex-col gap-4',
  };
  const notificationClassName =
    'bg-primeft-700 text-primeft-100 text-sm p-2 flex flex-row justify-center align-middle';

  return theme ? (
    <div className={containerClassName}>
      {offer && !isLoadingBorrower ? (
        <>
          {(offer.status == OfferStatus.Claimed ||
            offer.status == OfferStatus.Expired) && (
            <div className={notificationClassName}>
              <ExclamationIcon className="mr-2 h-10 w-10 md:h-5 md:w-5" />
              {offer.status == OfferStatus.Expired ? (
                <p>
                  This current offer has expired. You can request a renewal to apply by{' '}
                  <a href="/support" className="underline">
                    contacting us
                  </a>
                </p>
              ) : (
                <p>
                  This offer has already been claimed. You can request a new offer by{' '}
                  <a href="/support" className="underline">
                    contacting us
                  </a>
                </p>
              )}
            </div>
          )}
          {isBNPLOffer(offer) ? (
            equipment ? (
              <EquipmentOfferView offer={offer} equipment={equipment} />
            ) : (
              <Loading title="Loading your offer" />
            )
          ) : (
            isMCAOffer(offer) && (
              <MCAOfferView
                firstSectionClassName={firstSectionClassName[theme]}
                offer={offer}
                offerIsActive={offerIsActive}
                offerBelongsToLoan={offerBelongsToLoan}
              />
            )
          )}
        </>
      ) : (
        <Loading title="Loading your offer" />
      )}
    </div>
  ) : (
    <Loading title="Loading your offer" />
  );
};

export default OfferView;
