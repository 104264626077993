import Toast from 'Component/Toast/Toast';
import { NotificationType } from 'Util/constants';

const Fail = () => {
  return (
    <>
      <h3 className="text-sm font-semibold">What went wrong:</h3>
      <Toast
        title="Error XXXX"
        message="Due to workflow rules, document can not move to next queue."
        type={NotificationType.Error}
      />
    </>
  );
};

export default Fail;
