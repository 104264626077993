import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';

import { cn } from 'Util/css/css';

const buttonVariants = cva(
  'flex items-center justify-center rounded-lg font-medium transition-all md:w-fit',
  {
    variants: {
      variant: {
        fill: 'bg-button-primary text-button-primary hover:bg-button-hover disabled:cursor-default disabled:opacity-50',
        outline:
          'group border-2 border-button-primary text-button-complimentary hover:bg-button-primary hover:text-button-primary disabled:cursor-default disabled:opacity-50',
      },
      size: {
        lg: 'py-3 px-2 text-sm lg:px-10',
        sm: 'h-10 px-4 py-2 text-xs',
      },
    },
    defaultVariants: {
      variant: 'fill',
      size: 'lg',
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);
Button.displayName = 'Button';

export { Button, buttonVariants };
