import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum PaymentModalMode {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  SUCCESS = 'SUCCESS',
}

interface PaymentModalStateType {
  paymentId: string;
  amount: number;
  date: string;
  mode: PaymentModalMode;
}
const today = new Date();

const initialState: PaymentModalStateType = {
  paymentId: '',
  amount: 0,
  date: today.toISOString(),
  mode: PaymentModalMode.CREATE,
};

export const paymentModalSlice = createSlice({
  name: 'paymentModal',
  initialState,
  reducers: {
    closeModal: () => {
      return { ...initialState };
    },
    updateAmount: (
      state,
      action: PayloadAction<{ amount: PaymentModalStateType['amount'] }>,
    ) => {
      return { ...state, amount: action.payload.amount };
    },
    updateDate: (
      state,
      action: PayloadAction<{ date: PaymentModalStateType['date'] }>,
    ) => {
      return { ...state, date: action.payload.date };
    },
    updateMode: (
      state,
      action: PayloadAction<{ mode: PaymentModalStateType['mode'] }>,
    ) => {
      return { ...state, mode: action.payload.mode };
    },
    showCreateModal: state => {
      return { ...state, isOpen: true, mode: PaymentModalMode.CREATE };
    },
    showEditModal: state => {
      return { ...state, isOpen: true, mode: PaymentModalMode.EDIT };
    },
    setEditModalData: (
      state,
      action: PayloadAction<{
        amount: PaymentModalStateType['amount'];
        date: PaymentModalStateType['date'];
        paymentId: PaymentModalStateType['paymentId'];
      }>,
    ) => {
      return {
        ...state,
        isOpen: true,
        mode: PaymentModalMode.EDIT,
        amount: action.payload.amount,
        date: action.payload.date,
        paymentId: action.payload.paymentId,
      };
    },
    showDeleteModal: state => {
      return { ...state, isOpen: true, mode: PaymentModalMode.DELETE };
    },
    showSuccessModal: state => {
      return { ...state, isOpen: true, mode: PaymentModalMode.SUCCESS };
    },
  },
});

export const {
  closeModal,
  updateDate,
  updateAmount,
  showCreateModal,
  showEditModal,
  setEditModalData,
  showSuccessModal,
  showDeleteModal,
} = paymentModalSlice.actions;

export default paymentModalSlice.reducer;
