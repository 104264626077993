import { ArrowRightIcon } from '@heroicons/react/solid';
import ButtonLarge from 'Component/Button/ButtonLarge';
import AddressInput, { AddressParams } from 'Component/Input/AddressInput';
import Input from 'Component/Input/Input';
import { maskEIN, maskPhone } from 'Component/Input/utils/masks';
import Loader from 'Component/Loader/Loader';
import { useBorrower } from 'Context/BorrowerContext';
import { useToastNotification } from 'Context/ToastNotificationContext';
import { useVerifyBusinessInfo } from 'Hook/useVerifyBusinessInfo';
import ApplicationLayout from 'Page/Application/layouts/ApplicationLayout';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { setBusinessName } from 'Store/AuthSlice';
import { ApplicationStepsCompleted, NotificationType } from 'Util/constants';

type BusinessFormInput = {
  first_name: string;
  last_name: string;
  business_name: string;
  address: string;
  ein: string;
  phone?: string;
};

const Business = ({ onContinue }: { onContinue: () => void }) => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isDirty },
  } = useForm<BusinessFormInput>();
  const dispatch = useDispatch();
  const { addNotification } = useToastNotification();
  const [processing, setProcessing] = useState<boolean>(false);
  const [addressComponents, setAddressComponents] = useState<AddressParams | null>(null);
  const { loan } = useBorrower();
  const verifyBusiness = useVerifyBusinessInfo({
    onSuccess: onContinue,
    onError: () => {
      addNotification({
        type: NotificationType.Error,
        title: 'Error verifying your information',
        message: 'Please wait a moment and try again',
      });
      setProcessing(false);
    },
  });
  const onSubmit: SubmitHandler<BusinessFormInput> = data => {
    if (addressComponents === null) {
      addNotification({
        type: NotificationType.Error,
        title: 'Address is required',
        message: 'Please select an address using the dropdown',
      });
      return;
    }
    verifyBusiness.mutate({
      loan_id: loan?.id || '',
      name: data.business_name,
      federal_ein: data.ein,
      phone_number: data.phone,
      owner_first_name: data.first_name,
      owner_last_name: data.last_name,
      address_line_1: addressComponents.address_line_1,
      address_line_2: addressComponents.address_line_2,
      address_city: addressComponents.address_city,
      address_state: addressComponents.address_state,
      address_postal_code: addressComponents.address_postal_code,
    });
    setProcessing(true);
    dispatch(setBusinessName(data.business_name));
  };

  return (
    <ApplicationLayout stepsCompleted={ApplicationStepsCompleted.IDENTITY_VERIFICATION}>
      <>
        {processing && <Loader />}
        <div className="relative flex flex-col gap-8">
          <div>
            <h1 className="text-2xl lg:text-3xl">Confirm Your Business Information</h1>
            <p className="text-primeft-500">
              When verifying your business information, be sure to provide the correct
              information related to your business.
            </p>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className="mt-4 flex flex-col gap-4">
            <div className="grid grid-cols-6 gap-4">
              <Input
                name="first_name"
                label="Owner First Name"
                divClassName="col-span-3"
                control={control}
                rules={{ required: true }}
                error={errors.first_name}
              />
              <Input
                name="last_name"
                label="Owner Last Name"
                divClassName="col-span-3"
                control={control}
                rules={{ required: true }}
                error={errors.last_name}
              />
            </div>
            <Input
              name="business_name"
              label="Legal Business Name"
              control={control}
              rules={{ required: true }}
              error={errors.business_name}
            />
            <AddressInput
              name="address"
              label="Business Address"
              control={control}
              setAddress={newValue => setValue('address', newValue.description)}
              setAddressComponents={setAddressComponents}
              error={errors.address}
              rules={{ required: true, validate: () => addressComponents !== null }}
            />
            <Input
              name="phone"
              label="Business Phone Number (optional)"
              control={control}
              rules={{
                validate: (value: string) => {
                  if (!value) return true;
                  else return value.replace(/\D/g, '').length === 10;
                },
              }}
              error={errors.phone}
              modifier={maskPhone}
            />
            <Input
              name="ein"
              label="Business EIN"
              control={control}
              rules={{
                required: true,
                validate: (value: string) => value.replace(/\D/g, '').length === 9,
              }}
              error={errors.ein}
              modifier={maskEIN}
            />
            <ButtonLarge
              type="submit"
              className="md:ml-auto"
              disabled={!isDirty || processing}
            >
              Finish Verification <ArrowRightIcon className="ml-2 h-4 w-4" />
            </ButtonLarge>
          </form>
        </div>
      </>
    </ApplicationLayout>
  );
};

export default Business;
