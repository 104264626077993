import { ImageBuilder, ImageType } from 'Component/ImageBuilder/ImageBuilder';
import Loader from 'Component/Loader/Loader';
import { ReactNode } from 'react';

const ConnectYourBankLayout = ({
  children,
  title,
  subtitle,
  isProcessing,
  button,
}: {
  children: ReactNode;
  title: string;
  subtitle: string;
  isProcessing?: boolean;
  button: ReactNode;
}) => {
  return (
    <div className="relative">
      {isProcessing && <Loader />}
      <div className="text-align-left flex flex-col justify-start gap-4">
        <ImageBuilder imageType={ImageType.ConnectBankGraphic} />
        <div>
          <h2 className="text-2xl lg:text-3xl">{title}</h2>
          <p className="text-primeft-500">{subtitle}</p>
        </div>
        {children}
        {button}
      </div>
    </div>
  );
};

export default ConnectYourBankLayout;
