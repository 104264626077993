import {
  DocumentReportIcon,
  DocumentSearchIcon,
  DocumentTextIcon,
} from '@heroicons/react/outline';
import { AgreementChecklistItemType } from 'Component/ChecklistItem/ChecklistItem';
import { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';
import { ChecklistItemStatus } from 'Util/constants';

type AgreementContextType = {
  agreements: AgreementChecklistItemType[];
  setAgreements: Dispatch<SetStateAction<AgreementChecklistItemType[]>>;
};

const equipmentChecklistItems: AgreementChecklistItemType[] = [
  {
    id: 1,
    icon: <DocumentTextIcon className="h-6 w-6 text-primeft-500" />,
    title: 'Offer Summary',
    description: 'Offer summary for the closed-end commercial loan',
    status: ChecklistItemStatus.INCOMPLETE,
    agreementType: 'offer_summary',
  },
  {
    id: 2,
    icon: <DocumentReportIcon className="h-6 w-6 text-primeft-500" />,
    title: 'Itemization of Amount Financed',
    description: 'Confirmation of all the itemizations',
    status: ChecklistItemStatus.INCOMPLETE,
    agreementType: 'itemization',
  },
  {
    id: 3,
    icon: <DocumentSearchIcon className="h-6 w-6 text-primeft-500" />,
    title: 'Financing Agreement',
    description: 'Financing Agreement',
    status: ChecklistItemStatus.INCOMPLETE,
    agreementType: 'unsecured_financing',
  },
];

const AgreementContext = createContext<AgreementContextType>({
  agreements: equipmentChecklistItems,
  setAgreements: () => void 0,
});

export function AgreementProvider(props: { children?: React.ReactNode }) {
  const [agreements, setAgreements] = useState<AgreementChecklistItemType[]>(
    equipmentChecklistItems,
  );

  return (
    <AgreementContext.Provider
      {...props}
      value={{
        agreements,
        setAgreements,
      }}
    />
  );
}

export function useAgreementContext() {
  const context = useContext(AgreementContext);

  if (!context) {
    throw new Error('Context not initialized');
  }
  return context;
}
