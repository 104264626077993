import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline';
import { useThemeContext } from 'Context/ThemeContext';
import { useEffect, useRef, useState } from 'react';
import { FieldError, Controller, RegisterOptions } from 'react-hook-form';
import { Theme } from 'Util/constants';
import { baseLabelClassName, inputClassName } from './input-constants';

const Input = ({
  name,
  label,
  control,
  rules,
  divClassName,
  error,
  type = 'text',
  modifier,
  sideEffect,
}: {
  name: string;
  label: string;
  control: any;
  rules?: Pick<RegisterOptions, 'required' | 'pattern' | 'validate'>;
  divClassName?: string;
  error?: FieldError | undefined;
  type?: 'text' | 'password';
  modifier?: (value: string) => string;
  sideEffect?: (value: string) => void;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [cursor, setCursor] = useState<number | null>(null);
  const [hide, setHide] = useState(true);
  const inputType =
    type === 'password' && hide ? type : type !== 'password' ? type : 'text';

  const iconClassName = 'cursor-pointer absolute top-[32%] right-[2%]';

  useEffect(() => {
    const input = inputRef.current;
    if (input) input.setSelectionRange(cursor, cursor);
  }, [inputRef, cursor]);

  const { theme } = useThemeContext();
  const errorMessage = (error: FieldError) => {
    if (error?.message) return error.message;
    switch (error?.type) {
      case 'required':
        return 'This field is required';
      case 'pattern':
        return 'Invalid input';
      default:
        return 'Invalid input';
    }
  };

  return (
    <div className={divClassName}>
      <div className="relative">
        <Controller
          name={name}
          control={control}
          rules={rules}
          defaultValue={''}
          render={({ field }) => (
            <input
              type={inputType}
              id={name}
              className={inputClassName[theme || Theme.PrimeFT]}
              placeholder={label}
              {...field}
              ref={inputRef}
              onChange={e => {
                if (sideEffect) {
                  sideEffect(e.target.value);
                }
                if (modifier) {
                  field.onChange(modifier(e.target.value));
                  if (
                    e.target.selectionStart &&
                    e.target.selectionStart < field.value.length
                  ) {
                    setCursor(e.target.selectionStart);
                  }
                } else {
                  field.onChange(e.target.value);
                }
              }}
            />
          )}
        />
        {type === 'password' &&
          (hide ? (
            <EyeIcon
              width={25}
              className={iconClassName}
              onClick={() => {
                setHide(false);
              }}
            />
          ) : (
            <EyeOffIcon
              width={25}
              className={iconClassName}
              onClick={() => {
                setHide(true);
              }}
            />
          ))}
        <label htmlFor={name} className={baseLabelClassName}>
          {label}
        </label>
      </div>
      {error && <div className="text-sm text-red-500">{errorMessage(error)}</div>}
    </div>
  );
};
export default Input;
