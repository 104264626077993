import { AxiosError } from 'axios';
import { useApi } from 'Context/ApiContext';
import { useMutation } from 'react-query';
import { Business } from 'Util/types';

type ConnectBusinessProps = {
  businessId: string;
};

export const useConnectBusiness = ({
  onSuccess,
  onError,
}: {
  onSuccess: () => void;
  onError: (error: AxiosError) => void;
}) => {
  const api = useApi();

  const connectBusiness = async ({ businessId }: ConnectBusinessProps) =>
    (await api.post(`/businesses/${businessId}/connect`)).data;

  return useMutation<Business, AxiosError, ConnectBusinessProps>(
    businessId => connectBusiness(businessId),
    {
      onSuccess,
      onError,
    },
  );
};
