import { OfferProductType } from 'Util/constants';
import { BNPLOffer, MCAOffer, Offer } from 'Util/types';

export function isBNPLOffer(offer: Offer | BNPLOffer | MCAOffer): offer is BNPLOffer {
  return offer.product_type_id === OfferProductType.BNPL;
}

export function isMCAOffer(offer: Offer | BNPLOffer | MCAOffer): offer is MCAOffer {
  return offer.product_type_id === OfferProductType.MCA;
}
