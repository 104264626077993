import { AxiosError } from 'axios';
import { useApi } from 'Context/ApiContext';
import { useBorrower } from 'Context/BorrowerContext';
import { useMutation } from 'react-query';
import { OnboardingServiceErrorCode } from 'Util/constants';

export type SubmitVerificationErrorResponse = {
  msg: string;
  error_code?: OnboardingServiceErrorCode;
};

export const useVerifyInformation = ({
  onSuccess,
  onError,
}: {
  onSuccess: () => void;
  onError: (err: AxiosError<SubmitVerificationErrorResponse>) => void;
}) => {
  const api = useApi();
  const { loan } = useBorrower();
  const verifyInformation = async () =>
    (
      await api.post('/verification', {
        loan_id: loan?.id,
      })
    ).data;

  return useMutation(async () => verifyInformation(), {
    onSuccess: ({ msg }) => {
      console.log(msg);
      onSuccess();
    },
    onError: (error: AxiosError<SubmitVerificationErrorResponse>) => {
      console.log('Error verifying the information: ', error);
      onError(error);
    },
  });
};
