import { useApi } from 'Context/ApiContext';
import { useMutation, useQueryClient } from 'react-query';
import { LoanPayment } from 'Util/types';

type RemovePaymentParamsType = {
  payment_status_id: number;
};

type UpdatePaymentParamsType = {
  payment_amount: number;
  scheduled_date: string;
};

export const useUpdateLoanPayment = () => {
  const api = useApi();
  const queryClient = useQueryClient();

  const updatePayment = async (
    id: string,
    params: UpdatePaymentParamsType | RemovePaymentParamsType,
  ) => {
    const { data: payment } = await api.patch<LoanPayment>(`/payments/${id}`, params);
    return payment;
  };

  return useMutation(
    async ({
      paymentId,
      loanPaymentParams,
    }: {
      paymentId: string;
      loanPaymentParams: RemovePaymentParamsType | UpdatePaymentParamsType;
    }) => updatePayment(paymentId, loanPaymentParams),
    {
      onSuccess: data => {
        console.log('loan payment', data);
        queryClient.invalidateQueries({ queryKey: ['loanPayments'] });
        queryClient.invalidateQueries({ queryKey: ['paymentHistory'] });
      },
      onError: error => {
        console.log('Error updating loan payment: ', error);
      },
    },
  );
};
