import { CalendarInput } from 'Component/CalendarInput/CalendarInput';
import { MiniInput } from 'Component/Input/MiniInput';
import { maskCurrency } from 'Component/Input/utils/masks';
import Toast from 'Component/Toast/Toast';
import { useGetBankInfo } from 'Hook/useGetBankInfo';
import { BankTile } from 'Page/Dashboard/components/ConnectedBanks/BankTile';
import { useDispatch, useSelector } from 'react-redux';
import { updateAmount, updateDate } from 'Store/PaymentModalSlice';
import { RootState } from 'Store/store';
import { NotificationType } from 'Util/constants';
import { fromCurrency, toCurrency } from 'Util/currency/currency';

export const PaymentModalLineItems = ({
  balance,
  showError,
  setShowError,
}: {
  balance: number;
  showError: boolean;
  setShowError: (v: boolean) => void;
}) => {
  const {
    institutionName,
    accountNumber,
    institutionLogo,
    isFetching: isFetchingBankInfo,
  } = useGetBankInfo();
  const { amount, date: dateStr } = useSelector((state: RootState) => state.paymentModal);
  const date = new Date(dateStr);
  const dispatch = useDispatch();
  const lineClassName = 'flex flex-row justify-between items-center border-b-[1px] h-20';
  return (
    <div>
      {showError && (
        <Toast
          title="Payment Failed"
          message="We could not send the payment. Please try again."
          type={NotificationType.Error}
          autoClose={false}
          showCloseIcon={true}
          onClose={() => setShowError(false)}
        />
      )}
      <div className={lineClassName}>
        <span className="text-xs font-semibold">Pay From:</span>
        <BankTile
          fetchingBankInfo={isFetchingBankInfo}
          institutionLogo={institutionLogo}
          institutionName={institutionName}
          accountNumber={accountNumber}
        />
      </div>
      <div className={lineClassName}>
        <span className="text-xs font-semibold">Amount:</span>
        <div className="flex flex-col">
          <MiniInput
            mask={maskCurrency}
            value={amount === 0 ? '' : toCurrency(amount)}
            setValue={(v: string) => {
              dispatch(updateAmount({ amount: fromCurrency(v) }));
            }}
            placeholder="Enter Amount"
            className="ml-auto rounded-lg bg-primeft-500"
          />
          {amount > balance && (
            <span className="text-xs text-status-error-500">
              The amount cannot exceed the pending or balance amount
            </span>
          )}
        </div>
      </div>
      <div className={lineClassName}>
        <span className="text-xs font-semibold">Date:</span>
        <CalendarInput
          date={date}
          setDate={(d: Date) => {
            d.setHours(23, 59, 0, 0);
            dispatch(updateDate({ date: d.toISOString() }));
          }}
        />
      </div>
    </div>
  );
};
