import ButtonLarge from 'Component/Button/ButtonLarge';
import Toast from 'Component/Toast/Toast';
import { PaymentStatusNames } from 'Hook/Payments/useGetPaymentStatuses';
import { usePaymentStatusIdByName } from 'Hook/Payments/usePaymentStatusIdByName';
import { useUpdateLoanPayment } from 'Hook/Payments/useUpdateLoanPayment';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { showEditModal } from 'Store/PaymentModalSlice';
import { ButtonSubset, NotificationType } from 'Util/constants';

export const DeletePaymentModal = ({
  paymentId,
  handleClose,
}: {
  paymentId: string;
  handleClose: () => void;
}) => {
  const cancelledStatusId = usePaymentStatusIdByName(PaymentStatusNames.CANCELLED);
  const { mutate: removeLoanPayment } = useUpdateLoanPayment();
  const [showError, setShowError] = useState<boolean>(false);
  const dispatch = useDispatch();
  return (
    <div className="flex flex-col justify-center gap-4">
      <div className="text-center">
        <h3 className="text-2xl">Are you sure?</h3>
        <span className="text-lg text-primeft-500">
          This action will permanently remove the payment
        </span>
      </div>
      {showError && (
        <Toast
          title="Payment Failed"
          message="We could not send the payment. Please try again."
          type={NotificationType.Error}
          autoClose={false}
          showCloseIcon={true}
          onClose={() => setShowError(false)}
        />
      )}
      <div className="ml-auto flex flex-row justify-center gap-4">
        <ButtonLarge
          onClick={() => dispatch(showEditModal())}
          subset={ButtonSubset.Clear}
        >
          Nevermind
        </ButtonLarge>
        <ButtonLarge
          subset={ButtonSubset.Destructive}
          onClick={() => {
            if (cancelledStatusId) {
              removeLoanPayment(
                {
                  paymentId,
                  loanPaymentParams: { payment_status_id: cancelledStatusId },
                },
                {
                  onSuccess: () => {
                    handleClose();
                  },
                  onError: () => setShowError(true),
                },
              );
            } else {
              console.log('Error: No cancelled status id');
            }
          }}
        >
          Cancel Payment
        </ButtonLarge>
      </div>
    </div>
  );
};
