import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/solid';
import { useThemeContext } from 'Context/ThemeContext';
import { Theme } from 'Util/constants';
import { classNames } from 'Util/css/css';

type TablePageControlProps = {
  direction: 'next' | 'previous';
};

const TablePageControl = ({ direction }: TablePageControlProps) => {
  const { theme } = useThemeContext();
  const chevronIconStyle = 'h-5 w-5';
  const baseAnchorClassName =
    'relative inline-flex items-center px-2 py-2 text-bodySmall font-regular hover:bg-gray-50';
  const anchorStyle = {
    [Theme.PrimeFT]: 'text-primeft-300',
    [Theme.Odeko]: 'text-primeft-500',
  };

  return (
    <div className={classNames(baseAnchorClassName, anchorStyle[theme || Theme.PrimeFT])}>
      {direction === 'previous' ? (
        <>
          <span className="sr-only">Previous</span>
          <ArrowLeftIcon
            data-testid="chevron-left-icon"
            className={chevronIconStyle}
            aria-hidden="true"
          />
        </>
      ) : (
        <>
          <span className="sr-only">Next</span>
          <ArrowRightIcon
            data-testid="chevron-right-icon"
            className={chevronIconStyle}
            aria-hidden="true"
          />
        </>
      )}
    </div>
  );
};

export default TablePageControl;
