import { useBorrower } from 'Context/BorrowerContext';
import { useGetAgreementsInfo } from 'Hook/useGetAgreementsInfo';
import { classNames } from 'Util/css/css';
import { toCurrency } from 'Util/currency/currency';
import { toPercentage } from 'Util/values/percentage';

export const OfferSummary = () => {
  const { loan } = useBorrower();
  const { data } = useGetAgreementsInfo(loan?.id);
  if (!data) return <div>Loading...</div>;
  const {
    offerSummary: {
      paymentFrequencyDescription,
      loanAmount,
      effectiveAPR,
      financeCharge,
      totalPaymentsAmount,
      avgMonthlyCost,
      payment,
      term,
    },
  } = data;
  const borderClassName = '-mt-[1px] -ml-[1px] border border-black p-2';
  const detailsClassName = 'text-xs';
  const titleClassName = 'text-sm';
  return (
    <div className="grid grid-cols-4">
      <span className={classNames(borderClassName, titleClassName)}>
        Funding Provided
      </span>
      <span className={borderClassName}>{toCurrency(loanAmount)}</span>
      <span className={classNames(borderClassName, detailsClassName, 'col-span-2')}>
        This is how much funding Prime Financial Technologies, Inc. will provide. Due to
        deductions or payments to others, the total funds that will be provided to you
        directly is $0.00. For more information on the amounts that will be deducted,
        please review the attached document ‘Itemization of Amount Financed.’
      </span>
      <span className={classNames(borderClassName, titleClassName)}>
        Annual Percentage Rate (APR)
      </span>
      <span className={borderClassName}>{toPercentage(effectiveAPR)}</span>
      <span className={classNames(borderClassName, detailsClassName, 'col-span-2')}>
        APR is the cost of your financing expressed as a yearly rate. APR includes the
        amount and timing of the funding you receive, interest and other finance charges
        you pay and the payments you make.
        <br />
        <br />
        Your APR is not an interest rate. The cost of this financing is based upon fees
        charged rather than interest that accrues over time.
      </span>
      <span className={classNames(borderClassName, titleClassName)}>Finance Charge</span>
      <span className={borderClassName}>{toCurrency(financeCharge)}</span>
      <span className={classNames(borderClassName, detailsClassName, 'col-span-2')}>
        This is the dollar cost of your financing.
      </span>
      <span className={classNames(borderClassName, titleClassName)}>
        Total Payments Amount
      </span>
      <span className={borderClassName}>{toCurrency(totalPaymentsAmount)}</span>
      <span className={classNames(borderClassName, detailsClassName, 'col-span-2')}>
        This is the total dollar amount of payments you will make during the term of the
        contract.
      </span>
      <span className={classNames(borderClassName, titleClassName)}>
        Average Monthly Cost
      </span>
      <span className={borderClassName}>{toCurrency(avgMonthlyCost)}</span>
      <span className={classNames(borderClassName, detailsClassName, 'col-span-2')}>
        Although this financing does not have monthly payments, this is our calculation of
        your average monthly cost for comparison purposes.
      </span>
      <span className={classNames(borderClassName, titleClassName)}>Payment</span>
      <span className={borderClassName}>{payment}</span>
      <span className={classNames(borderClassName, detailsClassName, 'col-span-2')}>
        {paymentFrequencyDescription}
      </span>
      <span className={classNames(borderClassName, titleClassName)}>Term</span>
      <span className={classNames(borderClassName, 'col-span-3')}>{term}</span>
      <span className={classNames(borderClassName, titleClassName)}>Prepayment</span>
      <div className={classNames(borderClassName, detailsClassName, 'col-span-3')}>
        <span className={classNames(detailsClassName, 'col-span-3')}>
          If you pay off the financing early, you will still need to pay all or portion of
          the finance charge, up to ${financeCharge}.
        </span>
        <div className="my-2 h-[1px] w-full bg-black" />
        <span className={classNames(detailsClassName, 'col-span-3')}>
          If you pay off the financing early you must also pay the following additional
          fees: any accrued late payment fees of $50.00 each.
        </span>
      </div>
      <span className={classNames(borderClassName, titleClassName)}>Avoidable Costs</span>
      <span className={classNames(borderClassName, detailsClassName, 'col-span-3')}>
        We charge a late payment fee of $50.00 for each payment not received within 15
        days after its due date.
      </span>
    </div>
  );
};
