import { CalendarIcon, CurrencyDollarIcon, ScaleIcon } from '@heroicons/react/outline';
import { InfoLine } from 'Component/InfoLine/InfoLine';

import { useThemeContext } from 'Context/ThemeContext';
import CostGadget from 'Page/Offer/Components/Repayment/CostGadget/CostGadget';
import Incrementor from 'Page/Offer/Components/Repayment/CostGadget/Incrementor/Incrementor';
import RepaymentChart, {
  RepaymentChartLegend,
} from 'Page/Offer/Components/Repayment/RepaymentChart/RepaymentChart';
import { useDispatch, useSelector } from 'react-redux';
import {
  decrementAmount,
  decrementRate,
  incrementAmount,
  incrementRate,
  rateSet,
  amountSet,
} from 'Store/OfferSlice';
import { RootState } from 'Store/store';
import { Theme } from 'Util/constants';
import { toCurrency } from 'Util/currency/currency';
import { addDays, dateToLocalDateString } from 'Util/date/date';
import { MCAOffer } from 'Util/types';
import { clamp } from 'Util/values/clamp';

const Description = ({
  icon,
  title,
  description,
}: {
  icon: JSX.Element;
  title: string;
  description: string;
}) => {
  const containerClassName =
    'text-center md:text-left mx-4 py-8 md:mx-auto max-w-md flex flex-col justify-center';
  const iconBackgroundClassName =
    'mx-auto md:mx-0 rounded-lg bg-primary-500 flex justify-center items-center h-12 w-12 p-3';
  return (
    <div className={containerClassName}>
      <div className={iconBackgroundClassName}>{icon}</div>
      <h1 className="py-4 text-2xl font-bold md:text-4xl">{title}</h1>
      <h2 className="text-md md:text-xl">{description}</h2>
    </div>
  );
};

const OfferRepayment = ({
  offer: {
    factor_rate: factorRate,
    fee,
    capital_amount_min: amountMin,
    capital_amount_max: amountMax,
    remittance_rate_min: rateMin,
    remittance_rate_max: rateMax,
    estimated_daily_revenue: dailyRevenue,
  },
}: {
  offer: MCAOffer;
}) => {
  const rateFromStore = useSelector((state: RootState) => state.offer.rate);
  const amountFromStore = useSelector((state: RootState) => state.offer.amount);
  const dispatch = useDispatch();
  const { theme } = useThemeContext();
  const getProperlyRangedValuesFromStore = (): { rate: number; amount: number } => {
    const rate = clamp(rateFromStore, rateMin, rateMax);
    const amount = clamp(amountFromStore, amountMin, amountMax);
    if (rateFromStore !== rate) {
      dispatch(rateSet({ rate }));
    }
    if (amountFromStore !== amount) {
      dispatch(amountSet({ amount }));
    }
    return { rate, amount };
  };
  const layoutClassName = 'bg-primeft-300';
  const containerClassName = 'flex flex-col pb-8 max-w-screen-2xl m-auto';
  const rowClassName = 'flex flex-col md:flex-row mt-32 justify-center';
  const iconClassName = {
    [Theme.PrimeFT]: 'stroke-primeft-100',
    [Theme.Odeko]: 'strike-primeft-700',
  };
  const { rate, amount } = getProperlyRangedValuesFromStore();
  const dailyPayment = rate * dailyRevenue;
  const financingCost = factorRate * amount + fee;
  const totalOwed = amount + financingCost;
  const daysToPayoff = Math.ceil(totalOwed / dailyPayment);
  return (
    <div className={layoutClassName}>
      <div className={containerClassName}>
        <div className={rowClassName}>
          <Description
            icon={
              <CurrencyDollarIcon className={iconClassName[theme || Theme.PrimeFT]} />
            }
            title="How much does it cost?"
            description="The cost of your loan is determined by the amount you want to borrow. It’s a fixed fee. There’s no up-front payment, and the final amount you pay will never change."
          />
          <CostGadget>
            <>
              <Incrementor
                title="Amount to Borrow"
                currentValue={toCurrency(amount)}
                incrementFunction={() => dispatch(incrementAmount())}
                decrementFunction={() => dispatch(decrementAmount())}
                disableIncrement={amount >= amountMax}
                disableDecrement={amount <= amountMin}
              />
              <InfoLine title="Financing Cost" amount={toCurrency(financingCost)} />
              <InfoLine
                title="Total Amount Owed"
                amount={toCurrency(financingCost + amount)}
              />

              <InfoLine title="Interest" amount="None" />
              <InfoLine title="Additional Fees" amount="None" />
            </>
          </CostGadget>
        </div>

        <div className={rowClassName}>
          <Description
            icon={<CalendarIcon className={iconClassName[theme || Theme.PrimeFT]} />}
            title="How long do I have to repay?"
            description="There’s no due date. Your loan is repaid from a percentage of your daily credit card sales.You pay more when your sales are strong, and less if sales slow down."
          />
          <CostGadget>
            <>
              <InfoLine
                title="Total Amount Owed"
                amount={toCurrency(financingCost + amount)}
              />
              <InfoLine title="Average Daily Sales" amount={toCurrency(dailyRevenue)} />
              <Incrementor
                title="Repayment Rate"
                currentValue={(rate * 100).toFixed(0) + '%'}
                incrementFunction={() => dispatch(incrementRate())}
                decrementFunction={() => dispatch(decrementRate())}
                disableIncrement={rate >= rateMax}
                disableDecrement={rate <= rateMin}
              />
              <InfoLine title="Average Daily Payment" amount={toCurrency(dailyPayment)} />
              <InfoLine title="Total Payments" amount={`${daysToPayoff} days`} />
              <InfoLine
                title="Projected Payoff Date"
                amount={dateToLocalDateString(
                  addDays(new Date(), daysToPayoff),
                  'medium',
                )}
              />
            </>
          </CostGadget>
        </div>
        <div className={rowClassName}>
          <Description
            icon={<ScaleIcon className={iconClassName[theme || Theme.Odeko]} />}
            title="How are payments made?"
            description="Once a day, a percentage of your latest credit card sales is automatically withdrawn from your bank account. You pay more when your sales are strong, and less if sales slow down. "
          />
          <RepaymentChartLegend rate={rate} />
        </div>
        <RepaymentChart rate={rate} />
      </div>
    </div>
  );
};

export default OfferRepayment;
