import { AxiosError } from 'axios';
import { useApi } from 'Context/ApiContext';
import { useQuery } from 'react-query';

type APILoanTermsResponse = {
  loan_id: string;
  fee_percentage: number;
  loan_term_days: number;
  effective_apr: number;
  fee_amount: number;
  total_loan_amount: number;
  remittance_frequency: number;
  remittance_payment: number;
  id: string;
  created_at: string;
  updated_at: string;
};

export type LoanTerms = {
  loanId: string;
  feePercentage: number;
  loanTermDays: number;
  effectiveApr: number;
  feeAmount: number;
  /**
   * Full amount of the loan
   */
  totalLoanAmount: number;
  /**
   * Payment Frequency, days (How often a payment has to be made)
   */
  remittanceFrequency: number;
  /**
   * Payment Amount (currency)
   */
  remittancePayment: number;
  id: string;
};

export const useGetLoanTermsBNPLByLoanId = ({ loanId }: { loanId: string }) => {
  const api = useApi();
  const fetchLoanTerms = async () =>
    (await api.get(`/loan_terms_bnpl/loan/${loanId}`)).data;
  return useQuery<APILoanTermsResponse, AxiosError, LoanTerms>(
    ['loan_terms', loanId],
    fetchLoanTerms,
    {
      enabled: !!loanId,
      select: data => ({
        loanId: data.loan_id,
        feePercentage: data.fee_percentage,
        loanTermDays: data.loan_term_days,
        effectiveApr: data.effective_apr,
        feeAmount: data.fee_amount,
        totalLoanAmount: data.total_loan_amount,
        remittanceFrequency: data.remittance_frequency,
        remittancePayment: data.remittance_payment,
        id: data.id,
      }),
    },
  );
};
