import { User, UserAPIPayload } from 'Util/types';

export const mapUserResponseToUser = (userResponse: UserAPIPayload): User => ({
  id: userResponse.id,
  email: userResponse.email,
  auth0Id: userResponse.auth0_id,
  address: userResponse.address,
  firstName: userResponse.first_name,
  lastName: userResponse.last_name,
  phone: userResponse.phone,
  createdAt: userResponse.created_at,
  updatedAt: userResponse.updated_at,
  fullName: `${userResponse.first_name} ${userResponse.last_name}`,
});
