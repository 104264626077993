import { AxiosError } from 'axios';
import { useApi } from 'Context/ApiContext';
import { LinkTokenResponse } from 'Hook/useGetLinkToken';
import { useMutation, useQueryClient } from 'react-query';
import { APIErrorCodes } from 'Util/constants';
import { Loan, UserAPIPayload } from 'Util/types';

export type SetupAccountParams = {
  auth0_id: string;
  email: string;
  remittance_rate: number;
  capital_amount: number;
  offer_id: string;
};

type SetupAccountSuccessResponse = {
  user: UserAPIPayload;
  loan: Loan;
  link_token: LinkTokenResponse;
};

type SetupAccountErrorResponse = {
  msg: string;
  error_code?: string;
};

export function useSetupAccount() {
  const api = useApi();
  const queryClient = useQueryClient();
  const setupAccount = async ({
    auth0_id,
    email,
    remittance_rate,
    capital_amount,
    offer_id,
  }: SetupAccountParams) =>
    (
      await api.post('/account_setup', {
        auth0_id,
        email,
        remittance_rate,
        capital_amount,
        offer_id,
      })
    ).data;
  return useMutation<
    SetupAccountSuccessResponse,
    AxiosError<SetupAccountErrorResponse>,
    SetupAccountParams
  >(async (params: SetupAccountParams) => setupAccount(params), {
    onSuccess: (data: SetupAccountSuccessResponse) => {
      console.log('Account setup successfully', data);
      queryClient.setQueryData(['user', true], [data.user]);
      queryClient.setQueryData(['loan'], [data.loan]);
      queryClient.setQueryData(['linkToken', true, data.user.id, data.loan.id], {
        link_token: data.link_token,
        user_id: data.user.id,
      });
    },
    onError: (error: AxiosError<SetupAccountErrorResponse>) => {
      console.log('Error setting up the account: ', error);
      const errorCode = error?.response?.data?.error_code;
      if (errorCode == APIErrorCodes.LINK_TOKEN_FAILED) {
        queryClient.invalidateQueries(['user', true]);
        queryClient.invalidateQueries(['loan']);
        queryClient.invalidateQueries(['linkToken', true]);
      }
    },
    retryDelay: 1000,
    retry: 3,
  });
}
