import { useAuth0 } from '@auth0/auth0-react';
import { AxiosError } from 'axios';
import { useApi } from 'Context/ApiContext';
import { useBankConnection } from 'Context/BankConnectionContext';
import { useBorrower } from 'Context/BorrowerContext';
import { useQuery } from 'react-query';
import { APIErrorCodes } from 'Util/constants';

export type AccountInfo = {
  account_id: string;
  available_balance: number;
  current_balance: number;
  iso_currency_code: string;
  limit: number;
  mask: string;
  name: string;
  official_name: string;
  subtype: string;
  type: string;
};

export type AccountNumbers = {
  account: string;
  account_id: string;
  routing: string;
  wire_routing: string;
};

export type BankInfo = {
  accounts: AccountInfo[];
  numbers: {
    ach: AccountNumbers[];
  };
  institution: {
    institution_id: string;
    logo: string;
    name: string;
    url: string;
  };
};

type BankInfoError = {
  msg: string;
  error_code?: string;
};

export type BankInfoFetchResults = {
  isFetching: boolean;
  achAccountNumber: string | undefined;
  achRoutingNumber: string | undefined;
  institutionName: string;
  accountType: string;
  accountNumber: string;
  institutionLogo: string;
  bankAccountId: string;
};

export const useGetBankInfo = (): BankInfoFetchResults => {
  const api = useApi();
  const { isAuthenticated } = useAuth0();
  const { user } = useBorrower();
  const userId = user?.id || '';
  const {
    plaidAccessError: accessError,
    handlePlaidAccessError,
    shouldForceRefresh,
    setShouldForceRefresh,
  } = useBankConnection();
  const fetchBankInfo = async () =>
    (
      await api.get<BankInfo[]>(`/plaid_bank_accounts/${userId}`, {
        params: { force_refetch: shouldForceRefresh },
      })
    ).data;
  const { data: bankInfo, isFetching } = useQuery<BankInfo[], AxiosError<BankInfoError>>(
    ['bankInfo', isAuthenticated, userId],
    fetchBankInfo,
    {
      enabled: !accessError && !!userId,
      onSuccess: () => setShouldForceRefresh(false),
      onError: (err: AxiosError<BankInfoError>) => {
        const errorCode = err.response?.data.error_code;
        if (errorCode === APIErrorCodes.PLAID_ITEM_LOGIN_REQUIRED) {
          handlePlaidAccessError();
        }
      },
    },
  );

  if (bankInfo === undefined) return { isFetching } as BankInfoFetchResults;

  const chosenBank = bankInfo[bankInfo.length - 1];
  const accountId = chosenBank.accounts[0].account_id;
  const achData = chosenBank.numbers.ach.find(el => el.account_id === accountId);

  return {
    isFetching,
    achAccountNumber: achData?.account,
    achRoutingNumber: achData?.routing,
    accountType: chosenBank.accounts[0].subtype,
    institutionName: chosenBank.institution.name,
    accountNumber: chosenBank.accounts[0].mask,
    institutionLogo: chosenBank.institution.logo
      ? 'data:image/png;base64,' + chosenBank.institution.logo
      : '',
    bankAccountId: chosenBank.accounts[0].account_id,
  };
};
