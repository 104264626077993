import { AxiosError } from 'axios';
import { useApi } from 'Context/ApiContext';
import { useQuery } from 'react-query';
import { PaymentType } from 'Util/constants';

export type PaymentTypeType = {
  id: number;
  name: PaymentType;
  created_at: string;
  updated_at: string;
};

export const useGetPaymentTypes = () => {
  const api = useApi();

  const getPaymentTypes = async () => (await api.get('/payments/types')).data;

  return useQuery<PaymentTypeType[], AxiosError, PaymentTypeType[]>(
    ['payment_types'],
    async () => getPaymentTypes(),
  );
};
