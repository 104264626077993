import { useAuth0 } from '@auth0/auth0-react';
import { useApi } from 'Context/ApiContext';
import { useQuery } from 'react-query';
import { Loan } from 'Util/types';

export const useGetUserLoan = ({ userId }: { userId?: string }) => {
  const api = useApi();
  const { isAuthenticated } = useAuth0();
  const fetchLoans = async () => (await api.get<Loan[]>('/loans')).data;
  const {
    data: loan,
    isFetched,
    isLoading,
  } = useQuery(['loan'], fetchLoans, {
    enabled: isAuthenticated && !!userId,
    select: (data: Loan[]): Loan | undefined =>
      data.find(loan => loan.user_id === userId),
  });
  return { loan, isFetched, isLoading };
};
