import { Theme } from 'Util/constants';
import { classNames } from 'Util/css/css';

export const containerClassName = 'flex items-center justify-center';
export const pageBaseClasses =
  'relative inline-flex items-center h-7 min-w-[28px] px-2 py-2 text-bodySmall font-regular rounded-md justify-center';
export const pageClassName = {
  [Theme.PrimeFT]: classNames(pageBaseClasses, 'text-primeft-500'),
  [Theme.Odeko]: classNames(pageBaseClasses, 'text-primeft-700'),
};
export const pageActiveClassName = {
  [Theme.PrimeFT]: classNames(pageBaseClasses, 'z-10 bg-primary-500 !text-primeft-100'),
  [Theme.Odeko]: classNames(pageBaseClasses, 'z-10 bg-primeft-700 !text-primeft-100'),
};
export const breakClassName = {
  [Theme.PrimeFT]: 'text-primeft-500 mx-1',
  [Theme.Odeko]: 'text-primeft-700 mx-1',
};
export const disabledClassName = 'hidden';
