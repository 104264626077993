import background from 'Asset/dashboard_bg.png';
import NavBar from 'Component/NavBar/NavBar';
import { useBorrower } from 'Context/BorrowerContext';
import { useThemeContext } from 'Context/ThemeContext';
import Welcome from 'Page/Dashboard/components/Welcome';
import { ReactNode } from 'react';
import { Theme } from 'Util/constants';
import { classNames } from 'Util/css/css';

const DashboardLayout = ({
  leftSide,
  rightSide,
  fullLayout,
}: {
  leftSide: ReactNode;
  rightSide: ReactNode;
  fullLayout: ReactNode;
}) => {
  const { theme } = useThemeContext();
  const screenColor = {
    [Theme.Odeko]: 'bg-primeft-300',
    [Theme.PrimeFT]: 'bg-primeft-100',
  };
  const screenClassName = classNames('min-h-screen', screenColor[theme || Theme.PrimeFT]);
  const sectionClassName = 'max-w-screen-2xl mx-auto px-4';
  const hasBackground = [Theme.PrimeFT].includes(theme || Theme.PrimeFT);
  const topContainerThemedClasses = {
    [Theme.Odeko]: 'bg-primeft-500',
    [Theme.PrimeFT]: 'bg-primeft-300',
  };
  const backgroundClasses = 'bg-bottom bg-contain bg-no-repeat';
  const topContainerClassname = classNames(
    'pb-8 w-auto',
    topContainerThemedClasses[theme || Theme.PrimeFT],
    hasBackground ? backgroundClasses : '',
  );
  const widgetContainerClassName = 'flex flex-col md:flex-row gap-6';
  const leftSideClassName = 'w-full md:w-1/2';
  const rightSideClassName = 'w-full md:w-1/2';
  const { business, user } = useBorrower();
  const userName = user?.firstName ?? '';
  const businessName = business?.name || '';

  return (
    <div className={screenClassName}>
      <NavBar />
      <div
        style={{ backgroundImage: hasBackground ? `url(${background})` : '' }}
        className={topContainerClassname}
      >
        <div className={sectionClassName}>
          <Welcome businessName={businessName} userName={userName} />
          <div className={widgetContainerClassName}>
            <div className={leftSideClassName}>{leftSide}</div>
            <div className={rightSideClassName}>{rightSide}</div>
          </div>
        </div>
      </div>
      <div className={sectionClassName}>
        <div>{fullLayout}</div>
      </div>
    </div>
  );
};

export default DashboardLayout;
