import ReviewAnimation from 'Component/ReviewAnimation/ReviewAnimation';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { NavRoute } from 'Util/constants';

const Processing = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  setTimeout(() => {
    queryClient.invalidateQueries({ queryKey: ['loanDetails'] });
    queryClient.invalidateQueries({ queryKey: ['loan'] });
    queryClient.invalidateQueries({ queryKey: ['loanAppStatus'] });
    navigate(`/${NavRoute.Dashboard}`);
  }, 13000);

  return <ReviewAnimation pageTitle="Processing your Plan..." />;
};

export default Processing;
