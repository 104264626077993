import { getButtonLargeClassNames } from 'Component/Button/button-utils';
import { ImageBuilder, ImageType } from 'Component/ImageBuilder/ImageBuilder';
import { Link } from 'react-router-dom';
import { ButtonSubset, NavRoute } from 'Util/constants';
import { classNames } from 'Util/css/css';

export const UnknownIssue = ({
  titleText = 'Cannot continue with the application!',
  subtitleText = "I'm sorry, but your loan application has been paused due to the information you entered. Please contact us to get to the bottom of this.",
  buttonText = 'Have Questions? Contact Us',
  icon = <ImageBuilder imageType={ImageType.RoadblockGraphic} />,
}: {
  titleText?: string;
  subtitleText?: string;
  buttonText?: string;
  icon?: React.ReactNode;
}) => {
  const { baseClassName, buttonClassNames, textClassNames, sizesClassName } =
    getButtonLargeClassNames({ subset: ButtonSubset.Fill, size: 'lg' });

  return (
    <div className="flex flex-col items-center gap-2 text-center">
      {icon}
      <h1 className="font-regular mt-2 text-center text-3xl">{titleText}</h1>
      <span className="max-w-xl px-4 text-onPrimary">{subtitleText}</span>
      <Link
        className={classNames(
          baseClassName,
          sizesClassName,
          buttonClassNames,
          textClassNames,
        )}
        to={`/${NavRoute.Support}`}
      >
        {buttonText}
      </Link>
    </div>
  );
};
