import { ClockIcon, MailIcon, QuestionMarkCircleIcon } from '@heroicons/react/outline';
import { ReactNode } from 'react';
import { classNames } from 'Util/css/css';

export const WhatsNext = () => {
  const baseIconClassName = 'h-8 w-8';
  return (
    <div className="my-4 flex flex-col gap-8 md:mb-10">
      <div className="text-sm font-bold">What&apos;s next</div>
      <div className="flex flex-col gap-4 md:flex-row md:gap-8">
        <WhatsNextItem
          title="Automatic repayments"
          description="Repayments will appear in this table over time, be on the lookout"
          icon={
            <ClockIcon className={classNames(baseIconClassName, 'stroke-teal-500')} />
          }
          bgColor="bg-teal-100"
        />
        <WhatsNextItem
          title="Check Emails"
          description="Check your emails for important periodical information about your loan"
          icon={<MailIcon className={classNames(baseIconClassName, 'stroke-sky-500')} />}
          bgColor="bg-sky-100"
        />
        <WhatsNextItem
          title="Read our FAQs"
          description="Head to our support page to read more of our FAQs"
          icon={
            <QuestionMarkCircleIcon
              className={classNames(baseIconClassName, 'stroke-fuchsia-700')}
            />
          }
          bgColor="bg-fuchsia-100"
        />
      </div>
    </div>
  );
};

const WhatsNextItem = ({
  title,
  description,
  icon,
  bgColor,
}: {
  title: string;
  description: string;
  icon: ReactNode;
  bgColor: string;
}) => {
  return (
    <div
      className={classNames('flex flex-1 flex-col gap-4 rounded-lg p-4 pb-10', bgColor)}
    >
      {icon}
      <div className="flex flex-col gap-2">
        <div className="text-sm font-semibold">{title}</div>
        <div className="text-xs text-primeft-500">{description}</div>
      </div>
    </div>
  );
};
